import React from "react";
import {Route} from "react-router-dom";


const module = (shopApp) => {
	const CheckoutType = shopApp.dynamic(() => import("./CheckoutType"));

	const url = "/checkout_type";

	let config = {
		name: "CheckoutType",
		routes: [
			<Route key={url} exact path={url} component={CheckoutType}/>
		]
	};

	shopApp.registerPage(config);

};

export default module;
