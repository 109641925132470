export const trmSearch = {
	pathesToCheck: ["search"],
	tr: {
		search: {
			Page: {
				Headline: {
					DE: "Suchergebnisse für \"{searchString}\"",
					EN: "Search results for \"{searchString}\"",
					FR: "Résultats de recherche pour \"{searchString}\"",
				}
			},
			AutoComplete: {
				Headline: {
					DE: "Produkte",
					EN: "Products",
					FR: "Produits",
				},
				MoreResultsLink: {
					DE: "{AMOUNT} weitere Treffer in Produkten anzeigen",
					EN: "{AMOUNT} more results in products",
					FR: "Afficher {AMOUNT} plus de résultats dans les produits"
				}
			},
			EmptyResult: {
				DE: "Leider liegen zu dem Suchbegriff \"{searchString}\" keine Ergebnisse vor.",
				EN: "Sorry, there are no results for your search term \"{searchString}\".",
				FR: "Désolé, il n'y a pas de résultats pour votre terme de recherche \"{searchString}\"."
			},
		}
	}
};
