import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { Tr } from '@esnpm/translate';
import { compose } from 'redux';

class UserResetPasswordSuccessLabel extends React.Component {
	static propTypes = {
		app: PropTypes.any,
	};

	render() {
		if (
			this.props.app.registration.reset_pw_request.result.data != null ||
			this.props.app.registration.reset_pw_request.result.error != null
		) {
			return (
				<div className="alert alert-success text-center">
					<Tr path={'loginDialog.ResetPasswordSuccess'} />
				</div>
			);
			// } else if (this.props.app.registration.reset_pw_request.result.error != null) {
			// 	return (
			// 		<Alert bsStyle={"danger"}>
			// 			<Tr path={"notifications.Types.Error"}/>
			// 			&nbsp;
			// 			<Tr path={"notifications.Email.Error.NotRegistered"}/>
			// 		</Alert>
			// 	);
		} else {
			return null;
		}
	}
}

export default compose(
	connect((state) => ({
		app: {
			registration: state.app.registration,
		},
	}))
)(UserResetPasswordSuccessLabel);
