import axios from "axios/index";
import {getAPIUrl} from "@esnpm/node-toolbox";
import Promise from "bluebird";
export const API_SESSION_SET_ACTIVE_LANGUAGE_POST_REQUEST = "API_SESSION_SET_ACTIVE_LANGUAGE_POST_REQUEST";
export const API_SESSION_SET_ACTIVE_LANGUAGE_POST_SUCCESS = "API_SESSION_SET_ACTIVE_LANGUAGE_POST_SUCCESS";
export const API_SESSION_SET_ACTIVE_LANGUAGE_POST_FAILURE = "API_SESSION_SET_ACTIVE_LANGUAGE_POST_FAILURE";

export const api_session_set_active_language_post_request = (options) => {
	return {
		type: API_SESSION_SET_ACTIVE_LANGUAGE_POST_REQUEST,
		options: options,
		payload: {
			loading: true
		}
	};
};

export const api_session_set_active_language_post_success = (data) => {
	return {
		type: API_SESSION_SET_ACTIVE_LANGUAGE_POST_SUCCESS,
		payload: {
			loading: false,
			data: data,
			error: null
		}
	};
};

export const api_session_set_active_language_post_failure = (error) => {
	return {
		type: API_SESSION_SET_ACTIVE_LANGUAGE_POST_FAILURE,
		payload: {
			loading: false,
			error: error
		}
	};
};

/*
	Session.apiSessionSetActiveLanguage_post
	Sets active language in current session
	
			:param LANGUAGE_CODE:
			:return: {"activeLanguage": LANGUAGE_CODE}
*/
export const apiSessionSetActiveLanguage_post = (options) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(api_session_set_active_language_post_request(options));
			const url=getAPIUrl([
				{"url": "/api/session/set_active_language", "parameters": []},
			], options);
			axios({
				method: "post",
				url: url,
				data: {
					LANGUAGE_CODE: options.LANGUAGE_CODE,
				},
			}).then(
				(response) => {
					if (response.data.SUCCESSFUL) {
						dispatch(api_session_set_active_language_post_success(response.data.DATA));
						resolve();
					} else {
						dispatch(api_session_set_active_language_post_failure(response.data));
						resolve();
					}
				},
				(err) => {
					// eslint-disable-next-line no-console
					console.log(err);
					reject();
				}
			);
		}
	);
