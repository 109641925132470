import PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";
import {Tr} from "@esnpm/translate";
import {AppShowLanguageModal, AppShowSettingsModal} from "../../../../../esshop2_frontend/src/js/actions/App/AppCommonDialog";
import PackageVariantClosedIcon from "mdi-react/PackageVariantClosedIcon";
import EmailIcon from "mdi-react/EmailIcon";
import TranslateIcon from "mdi-react/TranslateIcon";
import SettingsIcon from "mdi-react/SettingsIcon";
import AccountIcon from "mdi-react/AccountIcon";
import SettingsDialog from "../../../../../esshop2_frontend/src/js/components/SettingsDialog";
import TranslationSelector from "../../../../../esshop2_frontend/src/js/components/TranslationSelector";
import {LoggedIn, NotLoggedIn} from "@esnpm/eswebapp";
import TopNavBarItem from "./TopNavBarItem";
import {InlineIcon, ResponsiveRender} from "@esnpm/react-toolbox";
import Navbar from "react-bootstrap/es/Navbar";
import CartPreview from "../../../../../esshop2_frontend/src/js/components/CartPreview";
import Grid from "react-bootstrap/es/Grid";
import Nav from "react-bootstrap/es/Nav";
import {compose} from "redux";
import {withShopAppContext} from "../../../../../esshop2_frontend/src/js/components/ShopAppContext";
import CurrentBreakpoint from "../../../../../esshop2_frontend/src/js/components/CurrentBreakpoint";


class TopNavBar extends React.Component {
	static propTypes = {
		currentSession: PropTypes.any,
		shopApp: PropTypes.any
	};

	render() {
		return (
			<Grid className={"TopNavBar"}>
				<CurrentBreakpoint disabled/>
				<Navbar>
					<Nav>
						<TopNavBarItem to={"/shipping_costs"} className={"hidden-xs"}>
							<InlineIcon icon={PackageVariantClosedIcon} marginRight/>
							<Tr path={"links.ShippingCosts"}/>
						</TopNavBarItem>
						<TopNavBarItem to={"/contact"} className={"hidden-xs"}>
							<InlineIcon icon={EmailIcon} marginRight/>
							<Tr path={"links.Contact"}/>
						</TopNavBarItem>
					</Nav>
					<Nav pullRight>
						{this.renderTranslation()}
						{this.renderSettings()}
						{this.renderLoginProfile()}
					</Nav>
					<ResponsiveRender xs>
						<CartPreview/>
					</ResponsiveRender>
				</Navbar>
				<SettingsDialog/>
				<TranslationSelector/>
			</Grid>
		);
	}

	renderTranslation() {
		return (
			<TopNavBarItem
				noLink
				onClick={() => this.props.dispatch(AppShowLanguageModal(true))}
				to={"#"}>

				<InlineIcon icon={TranslateIcon} className={"hidden-xs"} marginRight/>
				<span className={"hidden-xs"}><Tr path={"language.Button"}/></span>
			</TopNavBarItem>
		);
	}

	renderSettings() {
		if (this.props.currentSession.data == null) return null;

		return (
			<React.Fragment>
				<NotLoggedIn>
					<TopNavBarItem
						noLink
						onClick={() => this.props.dispatch(AppShowSettingsModal(true))}
					>
						<InlineIcon icon={SettingsIcon} marginRight/>
						<span className={"hidden-xs"}>
                        <Tr path={"links.Settings"}/>
                    </span>
					</TopNavBarItem>
				</NotLoggedIn>
			</React.Fragment>
		);
	}

	renderLoginProfile() {
		return (
			<React.Fragment>
				<NotLoggedIn>
					<TopNavBarItem
						to={"#"}
						onClick={() => this.props.shopApp.setShowLoginComponent(true)}
					>

						<InlineIcon icon={AccountIcon} marginRight/>

						<span className={"hidden-xs"}>
                            <Tr path={"links.Login"}/>
                        </span>
					</TopNavBarItem>
				</NotLoggedIn>
				<LoggedIn>
					<TopNavBarItem
						to={"/account"}

						className={"pull-right"}>
						<InlineIcon icon={AccountIcon} marginRight/>

						<span className={"hidden-xs"}>
                            <Tr path={"links.Profile"}/>
                        </span>
					</TopNavBarItem>
				</LoggedIn>
			</React.Fragment>
		);
	}
}


export default compose(
	connect(
		(state) => (
			{
				currentSession: state.currentSession
			}
		)
	),
	withShopAppContext
)(TopNavBar);
