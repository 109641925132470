import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {AppShowLanguageModal} from "../../../../../esshop2_frontend/src/js/actions/App/AppCommonDialog";
import React from "react";
import {LoggedIn} from "@esnpm/eswebapp";
import {Tr} from "@esnpm/translate";
import {compose} from "redux";

/**
 * Widget Customer Navigation
 * Navigation mit Links zum Account, rechtlichen Kram */
class WidgetCustomerNav extends React.Component {
	render() {
		return (
			<React.Fragment>
				<h3><Tr path={"footer.Widgets.CustomerCenter.Headline"}/></h3>
				<nav>
					<ul>
						<LoggedIn>
							<li>
								<Link to={"/account"}> <Tr path={"links.Profile"}/> </Link>
							</li>
						</LoggedIn>
						<li onClick={() => this.props.dispatch(AppShowLanguageModal(true))}>
							<a><Tr path={"language.Language"}/>: <Tr
								path={"language.Button"}/></a>
						</li>
						<li role={"separator"} className={"divider"}>&nbsp;</li>
						<li>
							<Link to={"/le_support"}>LEGO® Education Support</Link>
						</li>
						<li>
							<Link to={"/faq"}><Tr path={"links.FAQ"}/></Link>
						</li>
						<li>
							<Link to={"/shipping_costs"}><Tr path={"links.ShippingCosts"}/></Link>
						</li>
						<li>
							<Link to={"/revocation"}><Tr path={"links.Revocation"}/></Link>
						</li>
						<li role={"separator"} className={"divider"}>&nbsp;</li>
						<li>
							<Link to={"/gtc"}><Tr path={"links.GTC.Short"}/></Link>
						</li>
						<li>
							<Link to={"/privacy"}><Tr path={"links.PrivacyPolicy"}/></Link>
						</li>
						<li>
							<Link to={"/legal"}><Tr path={"links.LegalNotice"}/></Link>
						</li>
						<li>
							<Link to={"/cookies"}><Tr path={"links.CookiePolicy"}/></Link>
						</li>
					</ul>
				</nav>
			</React.Fragment>
		);
	}
}

export default compose(
	connect(
		(state) => (
			{
				currentSession: state.currentSession
			}
		)
	)
)(WidgetCustomerNav);
