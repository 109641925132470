import React, {Component} from "react";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import queryString from "query-string";
import {apiSessionSetActiveLanguage_post} from "../actions/esshop2/apiSessionSetActiveLanguage_post";
import {apiSessionSetCustomerData_post} from "../actions/esshop2/apiSessionSetCustomerData_post";
import {translation_change_language} from "@esnpm/translate";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {withAuthCurrentSession} from "../factories/withAuthCurrentSession";
import {withAuthCurrentUser} from "../factories/withAuthCurrentUser";


class AuthActiveLanguageContainer extends Component {
	static propTypes = {
		availableLanguages: PropTypes.array.isRequired,
		children: PropTypes.any,
		currentSession: PropTypes.object,
		location: PropTypes.any
	};

	componentDidMount() {
		this.languageCheck();
	}

	languageCheck() {
// Lang ermitteln / setzen
		const {ACTIVE_LANGUAGE} = this.props.currentSession.data;
		const LC = this.getUsedLanguage();

		if (ACTIVE_LANGUAGE == null) {
			// Keine Active Lang bedeutet neue session

			this.props.dispatch(
				apiSessionSetActiveLanguage_post({
					LANGUAGE_CODE: LC,
				}),
			).then(
				() => this.props.dispatch(apiSessionSetCustomerData_post({
					CUSTOMER_TYPE: "PRIVATE",
					COUNTRY: "LU",
				})).then(
					() => this.setState({init: true}),
				),
			);
		} else {
			this.props.dispatch(
				translation_change_language(LC),
			).then(
				() => this.setState({init: true}),
			);

		}

	}

	getUsedLanguage() {
		let ACTIVE_LANGUAGE = null;
		if (this.props.currentSession && this.props.currentSession.data) {
			ACTIVE_LANGUAGE = this.props.currentSession.data.ACTIVE_LANGUAGE;
		}

		const queryValues = queryString.parse(this.props.location.search);
		const browserLanguage = navigator.language.toUpperCase().substring(0, 2);
		const availableLanguages = this.props.availableLanguages;

		let LC = null;

		if (LC == null) {
			// lang gesetzt ? wenn ja diese verwebden
			if (queryValues.lang != null) {
				LC = queryValues.lang.toString().toUpperCase();
			}
		}

		if (LC == null) {
			// lang in der session
			if (ACTIVE_LANGUAGE != null) {
				LC = ACTIVE_LANGUAGE;
			}
		}

		if (LC == null) {
			// lang vom browser
			LC = browserLanguage;
		}

		if (availableLanguages.indexOf(LC) === -1) {
			// lang nicht unterstützt, en verwenden
			LC = "EN";
		}

		return LC;
	}

	render() {
		const LC = this.getUsedLanguage();
		return (
			React.cloneElement(this.props.children, {activeLanguage: LC})
		);
	}
}

export default compose(
	withRouter,
	withAuthCurrentUser,
	withAuthCurrentSession,
	connect(),
)(AuthActiveLanguageContainer);
