export const trmTransaction = {
	pathesToCheck: ['transaction'],
	tr: {
		transaction: {
			Addresses: {
				Headline: {
					DE: 'Adressen',
					EN: 'Addresses',
					FR: 'Adresses',
				},
				DeliveryInvoiceAddress: {
					DE: 'Liefer- und Rechnungsadresse',
					EN: 'Shipping and billing address',
					FR: 'Adresse de livraison et de facturation',
				},
				DeliveryAddress: {
					DE: 'Lieferadresse',
					EN: 'Shipping address',
					FR: 'Adresse de livraison',
				},
				InvoiceAddress: {
					DE: 'Rechnungsadresse',
					EN: 'Billing address',
					FR: 'Adresse de facturation',
				},
				PickupAddress: {
					DE: 'Abholadresse',
					EN: 'Pickup address',
					FR: 'Adresse de collecte',
				},
				NewAddress: {
					DE: 'Neue Adresse hinzufügen',
					EN: 'Add new address',
					FR: 'Ajouter une nouvelle adresse',
				},
			},
			OrderNumber: {
				DE: 'Auftragsnummer',
				EN: 'Order number',
				FR: 'N° de commande',
			},
			OrderDate: {
				DE: 'Auftragsdatum',
				EN: 'Date of order',
				FR: 'Date de commande',
			},
			OrderSummary: {
				DE: 'Bestellübersicht',
				EN: 'Order Summary',
				FR: 'Récapitulatif de commande',
			},
			ShippingMethod: {
				DE: 'Versandmethode',
				EN: 'Shipping Method',
				FR: 'Mode de livraison',
			},
			PaymentMethod: {
				DE: 'Zahlungsmethode',
				EN: 'Payment method',
				FR: 'Mode de paiement',
			},
			PaymentShippingMethod: {
				DE: 'Versand- und Zahlmethode',
				EN: 'Shipping and payment method',
				FR: "Méthode de paiement et d'envoi",
			},
			AdditionalOptions: {
				DE: 'Weitere Optionen',
				EN: 'Additional Options',
				FR: 'Autres options',
			},
			AdditionalInformation: {
				DE: 'Weitere Angaben',
				EN: 'Additional Information',
				FR: 'Informations additionnelles',
			},
			YourComment: {
				DE: 'Ihr Kommentar',
				EN: 'Your comment',
				FR: 'Votre commentaire',
			},
			YourReferenceNumber: {
				DE: 'Ihre persönliche Referenznummer',
				EN: 'Your custom order number',
				FR: 'Votre commentaire',
			},
			ShippingMethods: {
				NonListed: {
					Headline: {
						DE: 'Versand in nicht gelistete Länder',
						EN: 'Shipping to non-listed countries',
						FR: 'Expédition vers les pays non répertoriés',
					},
					Content: {
						DE: "Sie würden gerne bei uns bestellen, aber das von Ihnen gewünschte Land ist im Adressformular nicht gelistet? Zögern Sie nicht <a href='/contact'>Kontakt</a> mit uns aufzunehmen. Wir erstellen Ihnen gerne ein individuelles Angebot.",
						EN: "You would like to place an order, but your country is not listed in the address form? Please do not hesitate to <a href='/contact'>contact us</a>. We will be happy to provide you with an individual offer.",
						FR: "Vous souhaitez passer une commande, mais votre pays ne figure pas dans le formulaire d'adresse ? N'hésitez pas à nous contacter. Nous nous ferons un plaisir de vous soumettre une offre individuelle.",
					},
				},
				Untraceable: {
					DE: 'Es konnte keine Versandmethode ermittelt werden.',
					FR: "La méthode d'expédition n'a pu être déterminée.",
					EN: "Shipping method couldn't be determined.",
				},
				UPS_SAVER: {
					Headline: {
						ALL: 'UPS',
					},
					Tooltip: {
						DE: 'Die geschätzte Laufzeit für Sendungen innerhalb der EU liegt bei 1-3 Werktage. Bei internationalen Lieferungen liegt die geschätzte Werktage bei 3-6 Tagen.',
						EN: 'Estimated delivery time for shipments within in the EU is 1-3 business days. For international shipments the estimated delivery time is 3-6 business days.',
						FR: "La durée estimée pour les envois au sein de l'Union Européenne sont de 1 à 3 jours ouvrés. Pour les livraisons internationales cette durée est de 3 à 6 jours ouvrés.",
					},
				},
				DHL_EXPRESS: {
					Headline: {
						ALL: 'DHL Express',
					},
					Tooltip: {
						DE: 'Die geschätzte Laufzeit für Sendungen innerhalb der EU liegt bei 1-3 Werktage. Bei internationalen Lieferungen liegt die geschätzte Werktage bei 3-6 Tagen.',
						EN: 'Estimated delivery time for shipments within in the EU is 1-3 business days. For international shipments the estimated delivery time is 3-6 business days.',
						FR: "La durée estimée pour les envois au sein de l'Union Européenne sont de 1 à 3 jours ouvrés. Pour les livraisons internationales cette durée est de 3 à 6 jours ouvrés.",
					},
				},
				TNT: {
					Headline: {
						ALL: 'TNT',
					},
					Tooltip: {
						DE: 'Die geschätzte Laufzeit für Sendungen innerhalb der EU liegt bei 2-4 Werktage. Bei internationalen Lieferungen liegt die geschätzte Laufzeit bei 7-11 Werktage.',
						EN: 'Estimated delivery time for shipments within in the EU is 2-4 days. For international shipments the estimated delivery time is 7-11 days.',
						FR: "La durée estimée pour les envois au sein de l'Union Européenne sont de 2 à 4 jours ouvrables. Pour les livraisons internationales cette durée est de 7 à 11 jours ouvrables.",
					},
				},
				ECONOMY: {
					Headline: {
						DE: 'Economy-Versand',
						EN: 'Economy shipping',
						FR: 'Envoi économique',
					},
					Tooltip: {
						DE: 'Geschätzte Laufzeit 3-21 Werktage. Bitte beachten Sie, dass es bei dieser Versandmethode keine Sendungsverfolgung möglich ist.',
						EN: 'Estimated delivery time 3-21 business days. Please note that shipment tracking is not available.',
						FR: "Durée estimée : de 3 à 21 jours ouvrés. Veuillez noter qu'aucun suivi n'est possible avec ce moyen de livraison.",
					},
				},
				FREE_SHIPPING: {
					Headline: {
						DE: 'Kostenloser Versand',
						EN: 'Free shipping',
						FR: 'Livraison gratuite',
					},
					Tooltip: {
						DE: '<p>Ihre Bestellung ist für den &quot;Kostenlosen Versand&quot; qualifiziert.</p><p>Wenn Sie diese Lieferoption wählen, wird der Versanddienstleister abhängig von Ihrer Adresse und den bestellten Waren von uns festgelegt. Es ist möglich, dass Ihre Ware ohne Sendungsverfolgung gesendet wird. Alternativ können Sie natürlich auch aus einer der kostenpflichtigen Versandmethoden wählen.</p><p>Zu Laufzeiten und weiteren Informationen besuchen Sie bitte {LINK_FREE_SHIPPING}.</p>',
						EN: '<p>Your order is qualified for our &quot;Free Shipping&quot; option</p><p>If you choose this shipping option, we will select a logistic provider, depending on the ordered goods and the shipping destination. It is possible, that your goods are sent without tracking. Alternatively, you could choose from one of the non-free shipping methods.</p><p>For delivery time and other information, please visit {LINK_FREE_SHIPPING}.</p>',
						FR: '<p>Votre commande remplit les conditions pour la livraison gratuite.</p><p>Si vous choisissez cette option, le préstataire de service sera choisi par nos soins en fonction de votre adresse et de la marchandise commandée. Il se peut que votre commande soit envoyé sans numéro de colis. Vous pouvez également opter pour une option payante de votre choix.</p><p> Pour les délais de livraison et information complèmentaire veuillez regarder ici {LINK_FREE_SHIPPING}.</p>',
					},
					Link: {
						DE: 'Kostenloser Versand - Informationen und Bedingungen',
						EN: 'Free Shipping Information and Conditions',
						FR: 'Livraison gratuite et conditions',
					},
				},
				PICKUP: {
					Headline: {
						DE: 'Abholung in unserem Lager',
						EN: 'Pick up from our warehouse',
						FR: 'Enlèvement dans notre entrepôt',
					},
					Tooltip: {
						DE: 'Kein Versand. Abholung erfolgt in unserem Lager in Niederanven.',
						EN: 'No delivery, you can pick-up your goods at our warehouse in Niederanven.',
						FR: "Aucune expédition. La récupération s'effectue dans notre entrepôt à Niederanven.",
					},
				},
				EXTERN_LOGISTIC_PICKUP: {
					Headline: {
						DE: 'Externe Abholung',
						EN: 'External pickup',
						FR: 'Ramassage externe',
					},
					Tooltip: {
						DE: 'Abholung durch ein Transportunternehmen Ihrer Wahl',
						EN: 'Pickup by an external logistic service provider',
						FR: 'Ramassage par une entreprise de transport de votre choix',
					},
				},
				DPD_CLASSIC: {
					Headline: {
						ALL: 'DPD Classic',
					},
					Tooltip: {
						DE: 'Die geschätzte Laufzeit für Sendungen innerhalb der EU liegt bei 2-4 Werktage. Bei internationalen Lieferungen liegt die geschätzte Laufzeit bei 7-11 Werktage.',
						EN: 'Estimated delivery time for shipments within in the EU is 2-4 days. For international shipments the estimated delivery time is 7-11 days.',
						FR: "La durée estimée pour les envois au sein de l'Union Européenne sont de 2 à 4 jours ouvrables. Pour les livraisons internationales cette durée est de 7 à 11 jours ouvrables.",
					},
				},
			},
			PaymentMethods: {
				Untraceable: {
					DE: 'Es konnte keine Zahlungsmethode ermittelt werden.',
					FR: "Le mode de paiement n'a pu être déterminé.",
					EN: "Payment method couldn't be determined.",
				},
				PAYPAL: {
					Headline: {
						ALL: 'PayPal',
					},
					Tooltip: {
						DE: 'Bezahlen Sie einfach und sicher mit PayPal und profitieren Sie vom PayPal Käuferschutz.',
						EN: "The simple and safe way to pay. You'll benefit from the PayPal Buyer Protection.",
						FR: "Payez facilement et en toute sécurité avec PayPal et bénéficiez de la protection de l'acheteur de PayPal.",
					},
				},
				SAFERPAY: {
					Headline: {
						DE: 'Kreditkartenzahlung via Six Payment Service',
						EN: 'Payment by credit card',
						FR: 'Paiement par carte de crédit',
					},
					Tooltip: {
						DE: 'Bezahlen Sie mit Ihrer Kreditkarte von VISA- oder MasterCard.',
						EN: 'Pay with your VISA or MasterCard.',
						FR: 'Payez avec votre carte de crédit VISA ou MasterCard.',
					},
				},
				INVOICE: {
					Headline: {
						DE: 'Rechnung',
						EN: 'Invoice',
						FR: 'Facture',
					},
					Tooltip: {
						DE: 'Sie erhalten Ihre Rechnung mit der Lieferung der Ware.',
						EN: 'You will receive your invoice with the delivery of the goods.',
						FR: 'Vous recevrez votre facture avec la livraison de la marchandise.',
					},
				},
				SOFORT: {
					Headline: {
						ALL: 'SOFORT by Klarna',
					},
					Tooltip: {
						DE: 'Bezahlen Sie sicher und schnell mit der TÜV geprüften Zahlungsmethode von SOFORT. Sie benötigen nur Ihren vertrauten Online-Banking Daten.',
						EN: 'Pay with your own online banking details - fast and direct. SOFORT is TÜV certified.',
						FR: 'Payez en toute sécurité avec le mode de paiement certifié de SOFORT. Tout ce que vous avez besoin sont vos coordonnées bancaires.',
					},
				},
				WIRE_TRANSFER: {
					Headline: {
						DE: 'Vorkasse',
						EN: 'Prepayment (wire transfer)',
						FR: 'Paiement en avance (virement bancaire)',
					},
					Tooltip: {
						DE: 'Die Bezahlung erfolgt per Überweisung. Wir versenden Ihre Ware nach dem Erhalt Ihrer Zahlung, dies kann 2-4 Werktage in Anspruch nehmen.',
						EN: 'Pay by bank transfer. We will ship your goods after we received your payment, this kan take 2-4 business days.',
						FR: 'Le paiement est effectué par virement bancaire. Nous expédions votre commande après réception de votre paiement, cela peut prendre 2-4 jours ouvrables.',
					},
				},
			},
		},
	},
};
