import _ from 'lodash';

export const getTranslation = (tr, locale, locales) => {
	const activeLanguage = _.isNil(locale) ? 'EN' : locale.toUpperCase();

	if (!_.isNil(_.get(tr, activeLanguage, null))) return tr[activeLanguage];
	if (!_.isNil(_.get(tr, 'ALL', null))) return tr['ALL'];
	if (!_.isNil(_.get(tr, 'EN', null))) return tr['EN'];
	if (!_.isNil(locales)) {
		for (let lc of locales) {
			if (!_.isNil(_.get(tr, lc, null))) return tr[lc];
		}
	}

	if (Object.keys(tr).length !== 0) {
		return Object.values(tr)[0];
	}
	return null;
};
