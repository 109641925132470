export const MenuLegoEducationSeries = {
	title: { ALL: 'LEGO® Education' },
	description: {
		headline: {
			DE: 'Spielerisch lernen',
			EN: 'Playful learning',
			FR: 'Apprendre par le jeu',
		},
		text: {
			DE: 'Erleben Sie spannende MINT-Lernkonzepte für Klassenräume und für zuhause.',
			EN: 'Experience exciting STEM learning concepts in classrooms and at home.',
			// prettier-ignore
			FR:
				'Faites l\'expérience de concepts d\'apprentissage STEM passionnants dans les salles de classe et à la maison.',
		},
	},
	grouptags: ['SERIES/LEGO_EDUCATION'],
	items: [
		{
			title: { ALL: 'LEGO® Education Early Learning' },
			grouptags: ['SERIES/LEGO_EDUCATION/EARLY_LEARNING'],
		},
		{
			title: { ALL: 'BricQ Motion' },
			grouptags: ['SERIES/LEGO_EDUCATION/BRICQ'],
		},
		{
			title: { ALL: 'SPIKE™ Essential' },
			grouptags: ['SERIES/LEGO_EDUCATION/SPIKE_ESSENTIAL'],
		},
		{
			title: { ALL: 'SPIKE™ Prime' },
			grouptags: ['SERIES/LEGO_EDUCATION/SPIKE_PRIME'],
		},
		{
			title: {
				DE: 'Aufbewahrungsboxen',
				EN: 'Storage boxes',
				FR: 'Boîtes de stockage',
			},
			grouptags: ['SERIES/LEGO_EDUCATION/STORAGE'],
		},
		{
			title: {
				ALL: 'LEGO® Education WeDo 2.0',
			},
			grouptags: ['SERIES/LEGO_EDUCATION/WE_DO_2_0'],
		},
		{
			title: { ALL: 'MINDSTORMS® EV3' },
			grouptags: ['SERIES/LEGO_EDUCATION/MINDSTORMS_EV3'],
		},
	],
};

export const MenuComponents = {
	title: { DE: 'Bauelemente', EN: 'Components' },
	grouptags: ['COMPONENTS'],
	items: [
		{
			title: { DE: 'Aktive Bauelemente', EN: 'Active Components' },
			grouptags: ['COMPONENTS', 'ACTIVE_COMPONENTS'],
		},
		{
			title: { DE: 'Bauelemente-Zubehör', EN: 'Component Accessories' },
			grouptags: ['COMPONENTS', 'COMPONENT_ACCESSORIES'],
		},
		{
			title: { ALL: 'Displays' },
			grouptags: ['COMPONENTS', 'DISPLAYS'],
		},
		{
			title: { DE: 'Elektromechanik', EN: 'Electromechanics' },
			grouptags: ['COMPONENTS', 'ELECTROMECHANICS'],
		},
		{
			title: { DE: 'Gehäuse', EN: 'Enclosures' },
			grouptags: ['COMPONENTS', 'ENCLOSURES'],
		},
		{
			title: { DE: 'Kabel', EN: 'Cable' },
			grouptags: ['COMPONENTS', 'CABLE'],
		},
		{
			title: { DE: 'Kameras', EN: 'Cameras' },
			grouptags: ['COMPONENTS', 'CAMERAS'],
		},
		{
			title: { DE: 'Mechanische Bauelemente', EN: 'Mechanical Components' },
			grouptags: ['COMPONENTS', 'MECHANICAL_COMPONENTS'],
		},
		{
			title: { DE: 'Optoelektronik', EN: 'Optoelectronic Components' },
			grouptags: ['COMPONENTS', 'OPTOELECTRONIC_COMPONENTS'],
		},
		{
			title: { DE: 'Organisation', EN: 'Organization' },
			grouptags: ['COMPONENTS', 'ORGANIZATION'],
		},
		{
			title: { DE: 'Passive Bauelemente', EN: 'Passive Components' },
			grouptags: ['COMPONENTS', 'PASSIVE_COMPONENTS'],
		},
		{
			title: { DE: 'Schalter und Taster', EN: 'Switches and Buttons' },
			grouptags: ['COMPONENTS', 'SWITCHES_AND_BUTTONS'],
		},
		{
			title: { EN: 'Sensors', DE: 'Sensoren' },
			grouptags: ['COMPONENTS', 'SENSORS'],
		},
		{
			title: { EN: 'Connectors', DE: 'Buchsen' },
			grouptags: ['COMPONENTS', 'CONNECTORS'],
		},
	],
};

export const MenuComputerAndNetwork = {
	title: { EN: 'Computer & Network', DE: 'Computer & Netzwerk' },
	grouptags: ['COMPUTER+NETWORK'],
	items: [
		{
			title: { EN: 'Adaptors', DE: 'Adapter' },
			grouptags: ['COMPUTER+NETWORK', 'ADAPTORS'],
		},
		{
			title: {
				EN: 'Switches and Splitters for Computer Peripherals',
				DE: 'Computer-Peripherie Schalter und Verteiler',
			},
			grouptags: [
				'COMPUTER+NETWORK',
				'SWITCHES_AND_SPLITTERS_FOR_COMPUTER_PERIPHERALS',
			],
		},
		{
			title: { EN: 'Printer', DE: 'Drucker' },
			grouptags: ['COMPUTER+NETWORK', 'PRINTER'],
		},
		{
			title: { EN: 'Cable', DE: 'Kabel' },
			grouptags: ['COMPUTER+NETWORK', 'CABLE'],
		},
		{
			title: { EN: 'KVM / Extender / Digital Signage' },
			grouptags: ['COMPUTER+NETWORK', 'KVM_EXTENDER_DIGITAL_SIGNAGE'],
		},
		{
			title: { EN: 'Network', DE: 'Netzwerk' },
			grouptags: ['COMPUTER+NETWORK', 'NETWORK'],
		},
		{
			title: { EN: 'Storage Media', DE: 'Speichermedien' },
			grouptags: ['COMPUTER+NETWORK', 'STORAGE_MEDIA'],
		},
		{
			title: { EN: 'USB Components', DE: 'USB Komponenten' },
			grouptags: ['COMPUTER+NETWORK', 'USB_COMPONENTS'],
		},
	],
};

export const MenuEnergy = {
	title: { EN: 'Energy', DE: 'Stromversorgung' },
	grouptags: ['ENERGY'],
	items: [
		{
			title: { EN: 'Batteries', DE: 'Batterien' },
			grouptags: ['ENERGY', 'BATTERIES'],
		},
		{
			title: {
				EN: 'Chargers and Power Supplies',
				DE: 'Ladegeräte',
			},
			grouptags: ['ENERGY', 'CHARGERS_AND_POWER_SUPPLIES'],
		},
		{
			title: { EN: 'Power supplies', DE: 'Stromversorgung' },
			grouptags: ['ENERGY', 'POWER_SUPPLIES'],
		},
		{
			title: { EN: 'Voltage Converter', DE: 'Spannungswandler' },
			grouptags: ['ENERGY', 'VOLTAGE_CONVERTER'],
		},
		{
			title: { EN: 'Socket Strips', DE: 'Steckdosenleisten' },
			grouptags: ['ENERGY', 'SOCKET_STRIPS'],
		},
		{
			title: { EN: 'Power lines and cables', DE: 'Stromleitungen und -Kabel' },
			grouptags: ['ENERGY', 'POWER_LINES_AND_CABLES'],
		},
	],
};

export const MenuLabAndWorkshop = {
	title: { EN: 'Lab & Workshop', DE: 'Labor & Werkstatt' },
	grouptags: ['LAB+WORKSHOP'],
	items: [
		{
			title: {
				EN: 'Electronic Sprays & Cleanser',
				DE: 'Elektronik-Sprays und Reiniger',
			},
			grouptags: ['LAB+WORKSHOP', 'ELECTRONIC_SPRAYS+CLEANSER'],
		},
		{
			title: {
				EN: 'Soldering',
				DE: 'Löttechnik',
			},
			grouptags: ['LAB+WORKSHOP', 'SOLDERING'],
		},
		{
			title: {
				EN: 'Measurement and Test Equipment',
				DE: 'Mess- und Prüfgeräte',
			},
			grouptags: ['LAB+WORKSHOP', 'MEASUREMENT_AND_TEST_EQUIPMENT'],
		},
		{
			title: { EN: 'Microscopes', DE: 'Mikroskope' },
			grouptags: ['LAB+WORKSHOP', 'MICROSCOPES'],
		},
		{
			title: { EN: 'Project Mat', DE: 'Projektmatten' },
			grouptags: ['LAB+WORKSHOP', 'PROJECT_MAT'],
		},
		{
			title: { EN: 'Vices and Mounts', DE: 'Schraubstöcke und Halterungen' },
			grouptags: ['LAB+WORKSHOP', 'VICES_AND_MOUNTS'],
		},
		{
			title: { EN: 'Spill Control Products', DE: 'Sorptionsmittel' },
			grouptags: ['LAB+WORKSHOP', 'SPILL_CONTROL_PRODUCTS'],
		},
		{
			title: { EN: 'Flashlight', DE: 'Taschenlampen' },
			grouptags: ['LAB+WORKSHOP', 'FLASHLIGHT'],
		},
		{
			title: { EN: 'Tools', DE: 'WERKZEUG' },
			grouptags: ['LAB+WORKSHOP', 'WERKZEUG'],
		},
	],
};

export const MenuEmbedded = {
	title: { EN: 'Embedded' },
	grouptags: ['EMBEDDED'],
	items: [
		{
			title: { EN: 'Accessories', DE: 'Zubehör' },
			grouptags: ['EMBEDDED', 'ACCESSORIES'],
		},
		{
			title: { EN: 'Books', DE: 'Bücher' },
			grouptags: ['EMBEDDED', 'BOOKS'],
		},
		{
			title: { EN: 'Breakout Boards', DE: 'Breakout-Boards' },
			grouptags: ['EMBEDDED', 'BREAKOUT_BOARDS'],
		},
		{
			title: { EN: 'Geek Fun Stuff' },
			grouptags: ['EMBEDDED', 'GEEK_FUN_STUFF'],
		},
		{
			title: { EN: 'Programmers' },
			grouptags: ['EMBEDDED', 'PROGRAMMERS'],
		},
		{
			title: { EN: 'Prototyping' },
			grouptags: ['EMBEDDED', 'PROTOTYPING'],
		},
		{
			title: { EN: 'School and Laboratory Sets', DE: 'Elektronik-Sets' },
			grouptags: ['EMBEDDED', 'SCHOOL_AND_LABORATORY_SETS'],
		},
		{
			title: { EN: 'Systems & Boards', DE: 'Systeme & Boards' },
			grouptags: ['EMBEDDED', 'SYSTEMS+BOARDS'],
		},
		{
			title: { EN: 'Wearable Computing' },
			grouptags: ['EMBEDDED', 'WEARABLE_COMPUTING'],
		},
		{
			title: { EN: 'Widgets' },
			grouptags: ['EMBEDDED', 'WIDGETS'],
		},
		{
			title: { EN: 'Young Engineers', DE: 'Junge Techniker und Ingenieure' },
			grouptags: ['EMBEDDED', 'YOUNG_ENGINEERS'],
		},
	],
};

export const MenuRobotics = {
	title: { EN: 'Robotics', DE: 'Robotik' },
	grouptags: ['ROBOTICS'],
	items: [
		{
			title: { EN: 'Components & Parts', DE: 'Bauteile' },
			grouptags: ['ROBOTICS', 'COMPONENTS+PARTS'],
		},
		{
			title: { EN: 'Drivers', DE: 'Treiber' },
			grouptags: ['ROBOTICS', 'DRIVERS'],
		},
		{
			title: { EN: 'Robot Kits', DE: 'Roboter' },
			grouptags: ['ROBOTICS', 'ROBOT_KITS'],
		},
	],
};

export const MenuLegoSeries = {
	title: {
		ALL: 'LEGO®',
	},
	grouptags: ['SERIES/LEGO'],
	items: [
		{
			title: { ALL: 'Architecture' },
			grouptags: ['SERIES/LEGO/ARCHITECTURE'],
		},
		{
			title: { ALL: 'Art' },
			grouptags: ['SERIES/LEGO/ART'],
		},
		{
			title: { ALL: 'Avatar' },
			grouptags: ['SERIES/LEGO/AVATAR'],
		},
		{
			title: { ALL: 'BrickHeadz' },
			grouptags: ['SERIES/LEGO/BRICKHEADZ'],
		},
		{
			title: { ALL: 'City' },
			grouptags: ['SERIES/LEGO/CITY'],
		},
		{
			title: { ALL: 'Classic' },
			grouptags: ['SERIES/LEGO/CLASSIC'],
		},
		{
			title: { ALL: 'Creator 3-in-1' },
			grouptags: ['SERIES/LEGO/CREATOR-3-IN-1'],
		},
		{
			title: { ALL: 'DC Comics Super Heroes' },
			grouptags: ['SERIES/LEGO/DC_COMICS_SUPER_HEROES'],
		},
		{
			title: { ALL: 'Disney™' },
			grouptags: ['SERIES/LEGO/DISNEY'],
		},
		{
			title: { ALL: 'DOTS' },
			grouptags: ['SERIES/LEGO/DOTS'],
		},
		{
			title: { ALL: 'DUPLO®' },
			grouptags: ['SERIES/LEGO/DUPLO'],
		},
		{
			title: { ALL: 'Friends' },
			grouptags: ['SERIES/LEGO/FRIENDS'],
		},

		{
			title: { ALL: 'Harry Potter™' },
			grouptags: ['SERIES/LEGO/HARRY_POTTER'],
		},
		{
			title: { ALL: 'Icons (Creator Expert)' },
			grouptags: ['SERIES/LEGO/CREATOR_EXPERT'],
		},
		{
			title: { ALL: 'Ideas' },
			grouptags: ['SERIES/LEGO/IDEAS'],
		},
		{
			title: { ALL: 'Jurassic World™' },
			grouptags: ['SERIES/LEGO/JURASSIC_WORLD'],
		},
		{
			title: { ALL: 'Marvel Super Heroes' },
			grouptags: ['SERIES/LEGO/MARVEL_SUPER_HEROES'],
		},
		{
			title: { ALL: 'Minecraft™' },
			grouptags: ['SERIES/LEGO/MINECRAFT'],
		},
		{
			title: { ALL: 'Minifigures' },
			grouptags: ['SERIES/LEGO/MINIFIGURES'],
		},
		{
			title: { ALL: 'Minions' },
			grouptags: ['SERIES/LEGO/MINIONS'],
		},
		{
			title: { ALL: 'Monkey Kid™' },
			grouptags: ['SERIES/LEGO/MONKEY_KID'],
		},
		{
			title: { ALL: 'NINJAGO®' },
			grouptags: ['SERIES/LEGO/NINJAGO'],
		},

		{
			title: { ALL: 'Speed Champions' },
			grouptags: ['SERIES/LEGO/SPEED_CHAMPIONS'],
		},
		{
			title: { ALL: 'Star Wars™' },
			grouptags: ['SERIES/LEGO/STAR_WARS'],
		},
		{
			title: { ALL: 'Super Mario™' },
			grouptags: ['SERIES/LEGO/SUPER_MARIO'],
		},
		{
			title: { ALL: 'Technic' },
			grouptags: ['SERIES/LEGO/TECHNIC'],
		},
		{
			title: { ALL: 'Ghostbusters' },
			grouptags: ['SERIES/LEGO/GHOSTBUSTERS'],
		},
		{
			title: { DE: 'Alte Sets', EN: 'Hard To Find', FR: 'Anciens sets' },
			grouptags: ['SERIES/LEGO/HARD_TO_FIND'],
		},
		{
			title: { ALL: 'Polybag' },
			grouptags: ['SERIES/LEGO/POLYBAG'],
		},
		{
			title: { ALL: 'Power Functions' },
			grouptags: ['SERIES/LEGO/POWER_FUNCTIONS'],
		},
		{
			title: { ALL: 'Serious Play' },
			grouptags: ['SERIES/LEGO/SERIOUS_PLAY'],
		},
		{
			title: { ALL: 'Xtra' },
			grouptags: ['SERIES/LEGO/XTRA'],
		},
	],
};

export const MenuLighting = {
	title: { DE: 'Beleuchtung', EN: 'Lighting', FR: 'Éclairage' },
	description: {
		// prettier-ignore
		headline: { DE: 'Erleuchtend!', EN: 'It\' s enlighting!', FR: 'Illuminer' },
		text: {
			DE: 'Mit diesen Beleuchtungen hauchen Sie Ihren LEGO-Modellen Leben ein.',
			EN: 'With these lights you can add a touch of sparkle to your LEGO models.',
			FR: 'Donnez vie à vos modèles LEGO avec ces illuminateurs.',
		},
	},
	src: '/images/tags/TAG-LIGHTING.png',
	items: [
		{
			title: { DE: 'Für LEGO Sets', EN: 'For Lego sets' },
			grouptags: ['LIGHTING/LEGO'],
		},
		{
			title: { DE: 'DIY-Beleuchtung', EN: 'DIY Lighting' },
			grouptags: ['LIGHTING/DIY'],
		},
	],
};

export const MenuBooks = {
	title: { DE: 'Bücher', EN: 'Books', FR: 'Livres' },
	items: [{ title: { ALL: 'LEGO®' }, grouptags: ['BOOKS/LEGO'] }],
};
