import React from "react";
import Col from "react-bootstrap/es/Col";
import Row from "react-bootstrap/es/Row";
import WidgetContact from "./WidgetContact";
import WidgetCustomerNav from "./WidgetCustomerNav";
import WidgetNewsletter from "./WidgetNewsletter";
import WidgetPayment from "./WidgetPayment";
import {compose} from "redux";

/**
 * Footer Info
 * Contains Customer Center Nav, Infos about Shipping, Newsletter registration and  Info about Payment methods */
class FooterInfo extends React.Component {
	render() {
		return (
			<Row className={"FooterInfo"}>
				<Col xs={12}>
					<Row>
						<Col xs={12} sm={3} smPush={9} md={3} lg={2} lgPush={0} className={"col-footer"}>
							<WidgetCustomerNav/>
						</Col>
						<Col xs={12} smHidden mdHidden lgHidden>
							<hr/>
						</Col>
						<Col xs={12} sm={8} smPull={3} md={9} lg={3} lgPull={0} className={"col-footer"}>
							<WidgetNewsletter/>
						</Col>
						<Col xs={12} sm={8} smPull={3} md={9} lg={3} lgPull={0} className={"col-footer"}>
							<WidgetContact/>
						</Col>
						<Col xs={12} lgHidden>
							<hr/>
						</Col>
						<Col xs={12} sm={6} md={6} lg={4} className={"col-footer"}>
							<WidgetPayment/>
						</Col>

					</Row>
				</Col>
			</Row>
		);
	}
}

export default compose(
)(FooterInfo);
