import {API_CONTACT_CHANGE_PASSWORD_WITH_HASH_POST_FAILURE, API_CONTACT_CHANGE_PASSWORD_WITH_HASH_POST_REQUEST, API_CONTACT_CHANGE_PASSWORD_WITH_HASH_POST_SUCCESS} from "../../actions/esshop2/apiContactChangePasswordWithHash_post";
import {APP_RESET_MYACCOUNT_RESULTS} from "../../actions/App/AppAccount";
import {API_CONTACT_CHANGE_PASSWORD_POST_FAILURE, API_CONTACT_CHANGE_PASSWORD_POST_REQUEST, API_CONTACT_CHANGE_PASSWORD_POST_SUCCESS} from "../../actions/esshop2/apiContactChangePassword_post";
import {API_CONTACT_CHANGE_POST_FAILURE, API_CONTACT_CHANGE_POST_REQUEST, API_CONTACT_CHANGE_POST_SUCCESS} from "../../actions/esshop2/apiContactChange_post";
import {API_CONTACT_EMAIL_TO_DEPARTMENT_POST_FAILURE, API_CONTACT_EMAIL_TO_DEPARTMENT_POST_REQUEST, API_CONTACT_EMAIL_TO_DEPARTMENT_POST_SUCCESS} from "../../actions/esshop2/apiContactEmailToDepartment_post";
import {API_CONTACT_ACTIVATE_POST_FAILURE, API_CONTACT_ACTIVATE_POST_REQUEST, API_CONTACT_ACTIVATE_POST_SUCCESS} from "../../actions/esshop2/apiContactActivate_post";
import {APP_CONTACT_RESULT_ENTRY_SUCCESS} from "../../actions/App/AppContact";
import update from "immutability-helper";

const initialState = {
	contact_activate: {
		result: {
			loading: false,
			data: null,
			error: null,
		},
	},
	contact_change_pw: {
		result: {
			loading: false,
			data: null,
			error: null,
		},
	},
	contact_change: {
		result: {
			loading: false,
			data: null,
			error: null,
		},
	},
	contact_email_to_department: {
		result: {
			loading: false,
			data: null,
			error: null,
		},
	},
	reset_pw: {
		result: {
			loading: false,
			data: null,
			error: null,
		},
	},
};

export const AppContactReducer = (state = initialState, action) => {
	let dummy_data = null;

	switch (action.type) {

		case API_CONTACT_ACTIVATE_POST_SUCCESS:
		case API_CONTACT_ACTIVATE_POST_REQUEST:
		case API_CONTACT_ACTIVATE_POST_FAILURE:
			dummy_data = ((action.payload.data !== undefined) ? "data" : null);
			return update(state, {
				contact_activate: {
					result: {
						$set: Object.assign({}, state.contact_activate.result,
							{
								loading: action.payload.loading,
								data: dummy_data,
								error: action.payload.error,
							}),
					},
				},
			});

		case API_CONTACT_EMAIL_TO_DEPARTMENT_POST_SUCCESS:
		case API_CONTACT_EMAIL_TO_DEPARTMENT_POST_REQUEST:
		case API_CONTACT_EMAIL_TO_DEPARTMENT_POST_FAILURE:
			return update(state, {
				contact_email_to_department: {
					result: {
						$set: Object.assign({}, state.contact_email_to_department.result, action.payload),
					},
				},
			});

		case API_CONTACT_CHANGE_PASSWORD_WITH_HASH_POST_FAILURE:
		case API_CONTACT_CHANGE_PASSWORD_WITH_HASH_POST_REQUEST:
		case API_CONTACT_CHANGE_PASSWORD_WITH_HASH_POST_SUCCESS:
			return update(state, {
				reset_pw: {
					result: {
						$set: Object.assign({}, state.reset_pw.result, action.payload),
					},
				},
			});


		case API_CONTACT_CHANGE_PASSWORD_POST_FAILURE:
		case API_CONTACT_CHANGE_PASSWORD_POST_SUCCESS:
		case API_CONTACT_CHANGE_PASSWORD_POST_REQUEST:
			return update(state, {
				contact_change_pw: {
					result: {
						$set: Object.assign({}, state.contact_change_pw.result, action.payload),
					},
				},
			});

		case API_CONTACT_CHANGE_POST_FAILURE:
		case API_CONTACT_CHANGE_POST_REQUEST:
		case API_CONTACT_CHANGE_POST_SUCCESS:
			return update(state, {
				contact_change: {
					result: {
						$set: Object.assign({}, state.reset_pw.result, action.payload),
					},
				},
			});


		case APP_RESET_MYACCOUNT_RESULTS:
			return update(state, {
				contact_change: {
					result: {
						$set: {
							loading: false,
							data: null,
							error: null,
						},
					},
				},
				contact_change_pw: {
					result: {
						$set: {
							loading: false,
							data: null,
							error: null,
						},
					},
				},
			});

		case APP_CONTACT_RESULT_ENTRY_SUCCESS:
			return update(state, {
				[action.payload.field]: {
					result: {
						$set: {
							loading: false,
							data: null,
							error: null,
						},
					},
				},
			});

		default:
			return state;
	}
};
