import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// noinspection ES6CheckImport
import { Tr } from '@esnpm/translate';
import { compose } from 'redux';
import React from 'react';
import AutoCompleteProduct from './ShopProduct/SingleViews/AutoCompleteProduct';
import ShopProduct from './ShopProduct/ShopProduct';
import _ from 'lodash';

class AutoComplete extends React.Component {
	static propTypes = {
		autocomplete: PropTypes.any,
		history: PropTypes.any,
		searchBar: PropTypes.any,
		searchString: PropTypes.any,
		selected: PropTypes.any,
	};

	constructor(props) {
		super(props);
	}

	handleMouseDown() {
		this.props.history.push('/search?q=' + this.props.searchString);
		this.props.searchBar.resetSearch();
	}

	render() {
		const { autocomplete } = this.props;
		const data = autocomplete.data;
		if (data == null || data.PRODUCTS == null) return null;

		return (
			<div className={'AutoComplete'}>
				<div className={'AutoCompleteHeadline'}>
					<Tr path={'search.AutoComplete.Headline'} />
				</div>
				{this.renderProducts()}
				{this.renderMore(data)}
			</div>
		);
	}

	renderMore(data) {
		const max_hit = _.get(data, 'MAX_HIT', 0);
		const product_length = _.get(data, 'PRODUCTS', []).length;
		const amount = max_hit - product_length;
		return (
			<div
				className={'AutoCompleteMoreLink'}
				onMouseDown={this.handleMouseDown.bind(this)}
			>
				<Tr
					path={'search.AutoComplete.MoreResultsLink'}
					format={{
						AMOUNT: amount,
					}}
				/>
			</div>
		);
	}

	renderProducts() {
		const { autocomplete } = this.props;
		const data = autocomplete.data;
		return data.PRODUCTS.map((sku) => {
			return (
				<ShopProduct
					SKU={sku}
					filterClass={'LIST'}
					key={sku}
					gtmList={'autocomplete'}
					gtmImpression
				>
					<AutoCompleteProduct
						selected={this.props.selected === sku}
						searchBar={this.props.searchBar}
					/>
				</ShopProduct>
			);
		});
	}
}

export default compose(
	connect((state) => ({
		autocomplete: state.autocomplete,
	})),
	withRouter
)(AutoComplete);
