import {APP_RIGHT_OF_REVOCATION_SET_ACCEPTED_SUCCESS} from "../../actions/App/AppCheckout";
import update from "immutability-helper";

const initialState = {
	accepted: null,
};

export const AppRightOfRevocationReducer = (state = initialState, action) => {
	switch (action.type) {
		case APP_RIGHT_OF_REVOCATION_SET_ACCEPTED_SUCCESS:
			return update(state, {
				$set: action.payload,
			});
		default:
			return state;
	}
};
