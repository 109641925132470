import {
	API_ELASTICSEARCH_SEARCH_POST_REQUEST,
	API_ELASTICSEARCH_SEARCH_POST_SUCCESS,
} from '@esnpm/esdb';

const initialState = {
	loading: false,
	data: null,
	error: null,
};

export const AutoCompleteReducer = (state = initialState, action) => {
	let newState = null;

	switch (action.type) {
		case API_ELASTICSEARCH_SEARCH_POST_REQUEST:
			if (action.options.VIEW !== 'autocomplete') return state;

			newState = Object.assign({}, state, action.payload);
			newState['data'] = { SEARCH_STRING: action.options.q };
			return newState;

		case API_ELASTICSEARCH_SEARCH_POST_SUCCESS:
			if (action.payload.data.VIEW !== 'autocomplete') return state;
			if (state.data.SEARCH_STRING !== action.payload.data.SEARCH_STRING)
				return state;
			newState = Object.assign({}, state, action.payload);
			newState.data.PRODUCTS = action.payload.data.PRODUCTS.map((p) => p.SKU);
			return newState;

		default:
			return state;
	}
};
