export const trmFooter = {
	pathesToCheck: ["footer"],
	tr: {
		footer: {
			Widgets: {
				CustomerCenter: {
					Headline: {
						DE: "Kundencenter",
						EN: "Customer Center",
						FR: "Centre clientèle"
					}
				},
				Newsletter: {
					Headline: {
						ALL: "Newsletter",
					},
					Content: {
						DE: "Hier können Sie sich für unseren Newsletter anmelden. Bitte geben Sie hierfür Ihre E-Mail-Adresse an.",
						EN: "Here you can sign up for our newsletter. For this, please provide your e-mail address.",
						FR: "Vous pouvez ici vous abonner à notre newsletter. Veuillez pour cela indiquer votre adresse e-mail."
					}
				},
				Contact: {
					Headline: {
						DE: "Kontakt aufnehmen",
						EN: "Contact us",
						FR: "Contactez-nous"

					},
					Content: {
						DE: "Sie haben Fragen? Nutzen Sie unser Kontaktformular und wir sind gleich für Sie da.",
						EN: "Do you have any questions? Use our contact form and we will be right there for you.",
						FR: "Avez-vous des questions ? Utilisez notre formulaire de contact et nous serons là pour vous."
					},
					CallToAction: {
						DE: "Zum Kontaktforumlar",
						EN: "Go to the contact form",
						FR: "Accéder au formulaire de contact"
					}
				},
				SocialMedia: {
					Headline: {
						DE: "Bleiben Sie auf dem Laufenden",
						EN: "Stay up to date",
						FR: "Restez au courant de l'actualité"
					},
					Content: {
						DE: "Folgen Sie uns in den sozialen Medien und profitieren Sie von tollen Sonderaktionen und Angeboten.",
						EN: "Follow us and benefit from great promotions and offers.",
						FR: "Suivez-nous et profitez de promotions et d'offres exceptionnelles."
					}
				},
				PaymentOptions: {
					Headline: {
						DE: "Zahlungsmethoden",
						EN: "Payment methods",
						FR: "Modes de paiement"
					},
					Content: {
						DE: "Bei uns können Sie aus einer Vielzahl von sicheren Zahlungsmethoden wählen. Es fallen keine zusätzlichen Gebühren an.",
						EN: "You can choose from a variety of secure payment methods. There are no additional fees.",
						FR: "Vous pouvez choisir parmi une variété de méthodes de paiement sécurisé. Il n'y a pas de frais supplémentaires."
					}
				},
			},


			FooterInfo: {
				CustomerCenter: {
					Headline: {
						DE: "Kundencenter",
						EN: "Customer Center",
						FR: "Centre clientèle"
					}
				},
				ShippingInfo: {
					Headline: {
						DE: "Versandinformationen",
						EN: "Shipping Information",
						FR: "Informations de livraison"
					},
					Text: {
						DE: "Der <strong>Mindestbestellwert</strong> beträgt für Lieferungen in Länder der Europäischen Union <strong>10€</strong>, für Lieferungen in alle weiteren Länder liegt der Mindestbestellwert bei <strong>50€</strong> pro Bestellung.<br><br>Die <strong>Lieferung</strong> erfolgt weltweit per <strong>UPS oder DPD</strong>, oder per <strong>Deutsche Post</strong>.",
						EN: "The <strong>minimum order value</strong> for deliveries in countries of the European Union is <strong>10€</strong>, for all other countries the minimum order value is <strong>50€</strong> per order.<br><br><strong>Worldwide delivery</strong> will be made via <strong>UPS, DPD or via the Deutsche Post</strong>.",
						FR: "Le <strong>montant minimum de la commande</strong> est de <strong>10€</strong> pour la livraison dans les pays de l'Union Européenne, et de <strong>50€</strong> pour la livraison dans les autres pays par commande.<br><br>La livraison s'effectue dans le monde entier par <strong>UPS</strong>, par <strong>DPD ou UPS</strong> ou par <strong>Deutsche Post</strong>."
					}
				},
				Newsletter: {
					Headline: {
						ALL: "Newsletter"
					},
					Text: {
						DE: "Hier können Sie sich für unseren Newsletter anmelden. Bitte geben Sie hierfür Ihre E-Mail-Adresse an.",
						EN: "Here you can sign up for our newsletter. For this, please provide your e-mail address.",
						FR: "Vous pouvez ici vous abonner à notre newsletter. Veuillez pour cela indiquer votre adresse e-mail."
					},
					Form: {
						Placeholder: {
							DE: "Bitte geben Sie Ihre E-Mail-Adresse ein",
							EN: "Please enter your email address",
							FR: "Veuillez entrer votre adresse e-mail"
						},
						Button: {
							DE: "Newsletter abonnieren",
							EN: "Subscribe to our newsletter",
							FR: "Inscription newsletter"
						}
					}
				},
				PaymentOptions: {
					Headline: {
						DE: "Zahlungsoptionen",
						EN: "Payment Options",
						FR: "Mode de paiement"
					}
				}
			},
			FooterBranding: {
				ALL: "Electronic-Shop SARL - We love electronics!"
			},
			FooterMetaInfo: {
				Tax: {
					Private: {
						DE: "Alle Preise inkl. gesetzlicher Mehrwertsteuer, zzgl. Versandkosten. Änderungen und Irrtümer vorbehalten. Abbildungen ähnlich. Zwischenverkauf vorbehalten.",
						EN: "All prices incl. VAT plus shipping costs. Faults and changes reserved. Pictures may differ from the original products. Subject to prior sale.",
						FR: "Tous les prix s'entendent TVA incluse, hors frais de port. Sous réserve de modifications ou d'erreurs. Similaires aux images. Sous réserve de vente."
					},
					Corporate: {
						DE: "Alle Preise exkl. gesetzlicher Mehrwertsteuer, zzgl. Versandkosten. Änderungen und Irrtümer vorbehalten. Abbildungen ähnlich. Nur solange der Vorrat reicht.",
						EN: "All prices excl. VAT plus shipping costs. Faults and changes reserved. Pictures may differ from the original products. Subject to prior sale.",
						FR: "Tous les prix sont hors TVA et frais de livraison. Sous réserve de modifications et erreurs. Similaires aux images. Dans la limite des stocks disponibles."
					}
				},
				Version: {
					ALL: "Version"
				}
			}
		}
	}
};
