import React from 'react';
import { Route } from 'react-router-dom';

const module = (shopApp) => {
	const Group = shopApp.dynamic(() => import('./Group'));
	const url = '/group';
	// const url = '/group';

	let config = {
		name: 'Group',
		routes: [<Route key={url} exact path={url} component={Group} />],
	};

	shopApp.registerPage(config);
};

export default module;
