import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import ShopProductContainer from './ShopProductContainer';
import ProductContainer from './EsdbProductContainer';
import { ProductErrorBoundary } from './ProductErrorBoundary';

class ShopProduct extends Component {
	static defaultProps = {
		gtmDetail: false,
		gtmList: null,
	};

	static propTypes = {
		SKU: PropTypes.number.isRequired,
		forceReload: PropTypes.bool,
		filterClass: PropTypes.string,
		gtmDetail: PropTypes.bool,
		gtmList: PropTypes.string,
		gtmImpression: PropTypes.bool,
	};

	render() {
		return (
			<ProductErrorBoundary>
				<ProductContainer
					SKU={this.props.SKU}
					forceReload={this.props.forceReload}
					filterClass={this.props.filterClass}
				>
					<ShopProductContainer {...this.props}>
						{this.props.children}
					</ShopProductContainer>
				</ProductContainer>
			</ProductErrorBoundary>
		);
	}
}

export default compose()(ShopProduct);
