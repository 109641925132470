export const trmUnits = {
	pathesToCheck: ["units"],
	tr: {
		units: {
			Weight: {
				DE: "Gewicht",
				EN: "Weight",
				FR: "Poids",
			}
		}
	}
};
