import Promise from 'bluebird';
import { action_once } from '@esnpm/node-toolbox';
import {
	checkCategoryDataExists,
	checkCategoryHasChildren,
	parentCategory,
} from '../../components/Categories';
import { apiCategory } from '@esnpm/esdb';
import { throw_toast_alert } from '../../lib/throw_toast_alert';

export const APP_SET_SIDEBAR_CATEGORY_REQUEST =
	'APP_SET_SIDEBAR_CATEGORY_REQUEST';
export const APP_SET_SIDEBAR_CATEGORY_SUCCESS =
	'APP_SET_SIDEBAR_CATEGORY_SUCCESS';
export const APP_SET_SIDEBAR_CATEGORY_FAILURE =
	'APP_SET_SIDEBAR_CATEGORY_FAILURE';

export const AppSetSidebarCategory_request = () => {
	return {
		type: APP_SET_SIDEBAR_CATEGORY_REQUEST,
		payload: {
			loading: true,
		},
	};
};

export const AppSetSidebarCategory_success = (data) => {
	return {
		type: APP_SET_SIDEBAR_CATEGORY_SUCCESS,
		payload: {
			loading: false,
			catPath: data.catPath,
			highlight: data.highlight,
		},
	};
};

export const AppSetSidebarCategory_failure = (data) => {
	return {
		type: APP_SET_SIDEBAR_CATEGORY_FAILURE,
		payload: {
			loading: false,
			error: data,
		},
	};
};

export const AppSetSidebarCategory = (data) => (dispatch, getState) =>
	new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			let origCat = data.catPath;
			let dispCat = data.catPath;

			dispatch(AppSetSidebarCategory_request());

			dispatch(
				action_once(
					(state) => !checkCategoryDataExists(state, origCat, 1),
					apiCategory({ CATEGORY_PATH: origCat, CHILDREN: 1 })
				)
			)
				.then(() => {
					// eslint-disable-next-line no-unused-vars
					new Promise((resolve, reject) => {
						if (!checkCategoryHasChildren(getState(), origCat)) {
							dispCat = parentCategory(origCat);
							dispatch(
								action_once(
									(state) => !checkCategoryDataExists(state, dispCat, 1),
									apiCategory({ CATEGORY_PATH: dispCat, CHILDREN: 1 })
								)
							)
								.then(() => {
									resolve();
								})
								.catch((e) => {
									throw_toast_alert({
										exception: new Error('Category not found'),
										msg: 'An error occured. Categories could not be loaded',
										variant: 'error',
									});
								});
						} else {
							resolve();
						}
					})
						.then(() => {
							dispatch(
								AppSetSidebarCategory_success({
									catPath: dispCat,
									highlight: origCat,
								})
							);
							resolve();
						})
						.catch((e) => {
							throw_toast_alert({
								exception: new Error('Category not found'),
								msg: 'An error occured. Categories could not be loaded',
								variant: 'error',
							});
						});
				})
				.catch((e) => {
					throw_toast_alert({
						exception: new Error('Category not found'),
						msg: 'An error occured. Categories could not be loaded',
						variant: 'error',
					});
				});
		}
	);

export const APP_SET_SIDEBAR_HIGHLIGHTED_SUCCESS =
	'APP_SET_SIDEBAR_HIGHLIGHTED_SUCCESS';

export const AppSetSidebarHighlighted_success = (data) => {
	return {
		type: APP_SET_SIDEBAR_HIGHLIGHTED_SUCCESS,
		payload: {
			loading: false,
			highlight: data.highlight,
		},
	};
};

export const AppSetSidebarHighlighted =
	(data) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) =>
		new Promise(
			// eslint-disable-next-line no-unused-vars
			(resolve, reject) => {
				dispatch(AppSetSidebarHighlighted_success(data));
				resolve();
			}
		);
