import {APP_USER_ADDITIONAL_CUSTOMER_EMAIL_SET_EDITED_SUCCESS, APP_USER_ADDITIONAL_CUSTOMER_ORDER_NUMBER_SET_EDITED_SUCCESS, APP_USER_ADDITIONAL_COMMENT_SET_EDITED_SUCCESS} from "../../actions/App/AppCheckout";
import update from "immutability-helper";

const initialState = {
	customerComment: {
		edited: null,
	},
	customerOrderNumber: {
		edited: null,
	},
	customerEmail: {
		edited: null,
	},
};

export const AppUserAdditionalCommentReducer = (state = initialState, action) => {
	switch (action.type) {
		case APP_USER_ADDITIONAL_COMMENT_SET_EDITED_SUCCESS:
			return update(state, {
				customerComment: {
					edited: {
						$set: action.payload,
					},
				},
			});

		case APP_USER_ADDITIONAL_CUSTOMER_EMAIL_SET_EDITED_SUCCESS:
			return update(state, {
				customerEmail: {
					edited: {
						$set: action.payload,
					},
				},
			});

		case APP_USER_ADDITIONAL_CUSTOMER_ORDER_NUMBER_SET_EDITED_SUCCESS:
			return update(state, {
				customerOrderNumber: {
					edited: {
						$set: action.payload,
					},
				},
			});

		default:
			return state;
	}
};
