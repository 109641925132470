import {API_SESSION_LOGOUT_FAILURE, API_SESSION_LOGOUT_REQUEST, API_SESSION_LOGOUT_SUCCESS} from "../actions/esshop2/apiSessionLogout";
import {API_AUTH_CURRENT_USER_FAILURE} from "@esnpm/eswebapp";
import {API_CONTACT_CHANGE_LANGUAGE_CODE_POST_SUCCESS} from "../actions/esshop2/apiContactChangeLanguageCode_post";
import {setShopPayload} from "../lib/setShopPayload";
import {API_AUTH_LOGIN_POST_SUCCESS} from "@esnpm/eswebapp/build/actions/apiAuthLogin_post";
import {API_AUTH_CURRENT_USER_SUCCESS} from "@esnpm/eswebapp/build/actions/apiAuthCurrentUser";

const initialState = {
	loading: false,
	data: null,
	error: null
};
export const ShopAuthReducer = (state = initialState, action) => {

	switch (action.type) {

		case API_SESSION_LOGOUT_REQUEST:
		case API_SESSION_LOGOUT_FAILURE:
		case API_AUTH_CURRENT_USER_FAILURE:

			setShopPayload({
				EMAIL: null
			});

			return Object.assign(
				{},
				state,
				{
					loading: action.payload.loading,
					data: null,
					error: action.payload.error,
				}
			);

		case API_SESSION_LOGOUT_SUCCESS:
			setShopPayload({
				EMAIL: null
			});

			return Object.assign(
				{},
				state,
				{
					loading: action.payload.loading,
					data: null,
					error: {},
				}
			);

		case API_CONTACT_CHANGE_LANGUAGE_CODE_POST_SUCCESS:
			return Object.assign({}, state, action.payload);

		case API_AUTH_LOGIN_POST_SUCCESS:
		case API_AUTH_CURRENT_USER_SUCCESS:
			setShopPayload({
				EMAIL: action.payload.data.USER_ID,
				CUSTOMER_ID: action.payload.data.CUSTOMER.CUSTOMER_ID
			});
			return state;


		default:
			return state;
	}
};
