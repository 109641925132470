import React from "react";
import {Route} from "react-router-dom";

const module = (shopApp) => {
	const PasswordReset = shopApp.dynamic(() => import("./PasswordReset"));

	const url = "/password_reset";

	let config = {
		name: "PasswordReset",
		routes: [
			<Route key={url} exact path={url} component={PasswordReset}/>
		]
	};

	shopApp.registerPage(config);

};

export default module;
