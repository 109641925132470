import Promise from "bluebird";

export const APP_CONTACT_RESULT_ENTRY_SUCCESS = "APP_CONTACT_RESULT_ENTRY_SUCCESS";

export const AppContactResetResultEntry_success = (options) => {
	return {
		type: APP_CONTACT_RESULT_ENTRY_SUCCESS,
		payload: options,
	};
};

export const AppContactResetResultEntry = (options) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(AppContactResetResultEntry_success(options));
			resolve();
		},
	);
