import cookie from "react-cookies";
import {APP_COOKIE_SET_SUCCESS} from "../../actions/App/AppCookie";
import {COVID_COOKIE_SET_SUCCESS} from "../../actions/App/AppCovidCookie";

let cookie_value = (cookie.load("cm") != null) ? cookie.load("cm") : null;
let covid_cookie_value = (cookie.load("covid") != null) ? cookie.load("covid") : null;

const initialState = {
	cookie: cookie_value,
	covid: covid_cookie_value
};

export const AppCookiesReducer = (state = initialState, action) => {
	switch (action.type) {
		case  APP_COOKIE_SET_SUCCESS:
			return Object.assign({}, state, {
				cookie: "a998326e3c6f58039fac8bd09e7bf43f",
			});
		case COVID_COOKIE_SET_SUCCESS:
			return Object.assign({}, state, {
				covid: "a998326e3c6f58039fac8bd09e7bf43f",
			});
		default:
			return state;
	}
};
