import {combineReducers, routerReducer} from "redux-seamless-immutable";
import {TranslationReducer} from "@esnpm/translate";
import reduceReducers from "reduce-reducers";
import {authCurrentUserReducer} from "@esnpm/eswebapp";
import {VersionCheckerReducer} from "../lib/VersionChecker/VersionChecker";
import {authTranslationCurrentSessionReducer} from "./AuthTranslationCurrentSessionReducer";
import {CategoriesShopReducer} from "./CategoriesShopReducer";
import {CategoriesShopTranslationReducer} from "./CategoriesShopTranslationReducer";
import {SessionReducer} from "./SessionReducer";
import {ShippingMethodReducer} from "./ShippingMethodReducer";
import {ShippingMethodTranslationReducer} from "./ShippingMethodTranslationReducer";
import {ShopTransactionReducer} from "./ShopTransactionReducer";

import {
	CategoryReducer,
	CategoryTranslationReducer,
	CountryReducer,
	CountryTranslationReducer,
	OrderStatusExternTranslationReducer,
	ProductReducer,
	ProductsMultipleViewReducer,
	ProductTranslationReducer,
	TrStatusReducer,
	TypeMappingReducer,
	TypeMappingTranslationReducer
} from "@esnpm/esdb";

import {appInfoReducer} from "../actions/AppInfo";
import {ManufacturersReducer} from "@esnpm/esdb";
import {AutoCompleteReducer} from "./AutoCompleteReducer";
import {ShopAuthReducer} from "./ShopAuthReducer";
import {AppReducer} from "./App";
import {ShopManufacturersReducer} from "./ShopManufacturersReducer";
import {ShopOrdersReducer} from "./ShopOrdersReducer";

export default combineReducers({
		version: VersionCheckerReducer,
		currentSession: reduceReducers(
			// authCurrentSessionReducer, // Overwrite react-eswebapp->authCurrentSessionReducer
			SessionReducer
		),
		currentUser: reduceReducers(authCurrentUserReducer, ShopAuthReducer),
		routing: routerReducer,
		tr: reduceReducers(
			TranslationReducer,
			authTranslationCurrentSessionReducer,
			TypeMappingTranslationReducer,
			CountryTranslationReducer,
			CategoryTranslationReducer,
			ShippingMethodTranslationReducer,
			ProductTranslationReducer,
			CategoriesShopTranslationReducer,
			OrderStatusExternTranslationReducer,
			TrStatusReducer
		),
		categories: reduceReducers(
			CategoryReducer,
			CategoriesShopReducer
		),
		app: AppReducer,
		appInfo: appInfoReducer,
		country: CountryReducer,
		typemapping: TypeMappingReducer,
		shippingMethod: ShippingMethodReducer,
		products: ProductReducer,
		transactions: reduceReducers(
			ShopTransactionReducer
		),
		productsMultipleView: ProductsMultipleViewReducer,
		manufacturers: reduceReducers(
			ManufacturersReducer,
			ShopManufacturersReducer
		),
		autocomplete: AutoCompleteReducer,
		orders: ShopOrdersReducer
	}
);
