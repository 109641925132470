import {registerTranslation} from "@esnpm/translate";
import {API_SHIPPING_METHOD_TRANSLATION_SUCCESS} from "../actions/esshop2/apiShippingMethodTranslation";

const initialState = {
	loading: false,
	data: null,
	error: null,
};
export const ShippingMethodTranslationReducer = (state = initialState, action) => {
	switch (action.type) {
		case API_SHIPPING_METHOD_TRANSLATION_SUCCESS:
			if (action.payload.data == null || action.payload.data === {}) {
				return state;
			}
			return registerTranslation(state, "shippingMethod", action.payload.data);

		default:
			return state;
	}
};
