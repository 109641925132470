export const trmLanguages = {
	pathesToCheck: ["language"],
	tr: {
		language: {
			Button: {
				DE: "Deutsch / German",
				EN: "English",
				FR: "Français / French"
			},
			ChangeLanguage: {
				DE: "Sprache wechseln",
				EN: "Change language",
				FR: "Changer de langue"
			},
			Language: {
				DE: "Sprache",
				EN: "Language",
				FR: "Langue"
			},
			Languages: {
				DE: {
					ALL: "Deutsch"
				},
				EN: {
					ALL: "English"
				},
				FR: {
					ALL: "Français"
				},
			}
		}
	}
};
