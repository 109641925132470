import PropTypes from "prop-types";
import {connect} from "react-redux";
import React from "react";
import {compose} from "redux";

export class BusinessCustomer extends React.Component {
	static propTypes = {
		children: PropTypes.any,
		currentSession: PropTypes.any,
		currentUser: PropTypes.any
	};

	render() {
		let customer_type;
		if (this.props.currentUser.data != null && this.props.currentUser.data.CUSTOMER != null) {
			customer_type = this.props.currentUser.data.CUSTOMER.CUSTOMER_TYPE;
		} else {
			customer_type = this.props.currentSession.data.CUSTOMER_TYPE;
		}

		if (customer_type !== "COMPANY") {
			return null;
		} else {
			return this.props.children;
		}
	}
}

export default compose(
	connect(
		(state) => (
			{
				currentUser: state.currentUser,
				currentSession: state.currentSession
			}
		)
	)
)(BusinessCustomer);
