import React from "react";
import {Route} from "react-router-dom";


const module = (shopApp) => {
	const Contact = shopApp.dynamic(() => import("./Contact"));

	const url = "/contact";

	let config = {
		name: "Contact",
		routes: [
			<Route key={url} exact path={url} component={Contact}/>
		]
	};

	shopApp.registerPage(config);

};

export default module;
