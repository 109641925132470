import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import CategoriesSelect from './CategoriesSelect';

class SideBarCategorySelectContainer extends React.Component {
	static propTypes = {
		cat: PropTypes.any,
	};

	shouldComponentUpdate(nextProps) {
		return this.props.cat !== nextProps.cat;
	}

	render() {
		return null;
		//der key wird überschrieben damit alle Children neu gerendert werden. (Select wird neu gerendert und hat dann den richtigen wert)
		return <CategoriesSelect key={this.props.cat} cat={this.props.cat} />;
	}
}

export default compose()(SideBarCategorySelectContainer);
