import PropTypes from "prop-types";
import {connect} from "react-redux";
import SendIcon from "mdi-react/SendIcon";
import {apiNewsletterRegister_post} from "../actions/esshop2/apiNewsletterRegister_post";
import React from "react";
import {ESForm} from "@esnpm/forms";
import {ESLoadable} from "@esnpm/react-toolbox";
import Alert from "react-bootstrap/es/Alert";
import {Tr} from "@esnpm/translate";
import {compose} from "redux";

class FormNewsletterFooter extends React.Component {
	static propTypes = {
		app: PropTypes.any
	};

	constructor(props) {
		super(props);
		this.state = {
			button_disable: true,
		};
	}

	render() {
		return (
			<ESLoadable
				active={this.props.app.newsletter_register.result.loading}
			>
				<ESForm
					ref={c => {
						this.NEWSLETTER = c;
					}}
					classValid={"ESFormFieldValid"}
					form
				>
					<ESForm.InputButtonGroupEmail
						icon={SendIcon}
						onEnter={this.handleNewsLetterButton.bind(this)}
						onButtonClick={this.handleNewsLetterButton.bind(this)}
						bsStyle={"primary"}
						fieldName={"EMAIL"}
						noLabel
						hint={<Tr path={"esforms.fields.EMAIL.hint"}/>}

					/>
				</ESForm>
				{this.renderResultMessage()}
			</ESLoadable>
		);
	}

	renderResultMessage() {
		if (this.props.app.newsletter_register.places.indexOf("FOOTER") >= 0) {
			if (this.props.app.newsletter_register.result.error != null) {
				return (
					<Alert bsStyle={"warning"}>
						<Tr path={"newsletter." + this.props.app.newsletter_register.result.error.ERROR_CLASS}/>
					</Alert>);
			} else if (this.props.app.newsletter_register.result.data != null) {
				return (
					<Alert bsStyle={"success"}>
						<Tr path={"newsletter.Success"}/>
					</Alert>);
			}
		}
	}

	handleNewsLetterButton() {
		const form = this.NEWSLETTER.wrappedInstance;
		const data = form.value();

		this.props.dispatch(apiNewsletterRegister_post({
			EMAIL: data["EMAIL"],
			place: "FOOTER"
		})).then(
			() => {
				if (this.props.app.newsletter_register.result.data != null && this.props.app.newsletter_register.result.error == null) {
					form.fields["EMAIL"].setValFromExtern("");
				}
			}
		);
	}
}

export default compose(
	connect(
		(state) => (
			{
				app: {
					newsletter_register: state.app.newsletter_register
				}
			}
		)
	)
)(FormNewsletterFooter);
