import axios from "axios/index";
import {getAPIUrl} from "@esnpm/node-toolbox";
import Promise from "bluebird";

export const API_ADD_POSITION_TO_CART_POST_REQUEST = "API_ADD_POSITION_TO_CART_POST_REQUEST";
export const API_ADD_POSITION_TO_CART_POST_SUCCESS = "API_ADD_POSITION_TO_CART_POST_SUCCESS";
export const API_ADD_POSITION_TO_CART_POST_FAILURE = "API_ADD_POSITION_TO_CART_POST_FAILURE";
// TODO
export const api_add_position_to_cart_post_request = (options) => {
	return {
		type: API_ADD_POSITION_TO_CART_POST_REQUEST,
		options: options,
		payload: {
			loading: true
		}
	};
};

export const api_add_position_to_cart_post_success = (data) => {
	return {
		type: API_ADD_POSITION_TO_CART_POST_SUCCESS,
		payload: {
			loading: false,
			data: data,
			error: null
		}
	};
};

export const api_add_position_to_cart_post_failure = (error) => {
	return {
		type: API_ADD_POSITION_TO_CART_POST_FAILURE,
		payload: {
			loading: false,
			error: error
		}
	};
};

/*
	Session.apiAddPositionToCart_post
*/
export const apiAddPositionToCart_post = (options) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(api_add_position_to_cart_post_request(options));
			const url = getAPIUrl([
				{"url": "/api/session/cart/add/position", "parameters": []},
			], options);
			axios({
				method: "post",
				url: url,
				data: {
					AMOUNT: options.AMOUNT,
					SKU: options.SKU,
				},
			}).then(
				(response) => {
					if (response.data.SUCCESSFUL) {
						dispatch(api_add_position_to_cart_post_success(response.data.DATA));
						resolve();
					} else {
						dispatch(api_add_position_to_cart_post_failure(response.data));
						resolve();
					}
				},
				(err) => {
					// eslint-disable-next-line no-console
					console.log(err);
					reject();
				}
			);
		}
	);
