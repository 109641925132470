import {APP_CUSTOMER_TYPE_SELECTION_SUCCESS} from "../../actions/App/AppCheckout";
import update from "immutability-helper";

const initialState = {
	emailValid: null
};

export const AppCustomerTypeSelectionReducer = (state = initialState, action) => {
	switch (action.type) {
		case APP_CUSTOMER_TYPE_SELECTION_SUCCESS:
			return update(state, {
				$set: action.payload,
			});
		default:
			return state;
	}
};
