import {API_NEWSLETTER_OPT_IN_FAILURE, API_NEWSLETTER_OPT_IN_REQUEST, API_NEWSLETTER_OPT_IN_SUCCESS} from "../../actions/esshop2/apiNewsletterOptIn";
import update from "immutability-helper";

const initialState = {
	result: {
		loading: false,
		data: null,
		error: null,
	},
};

export const AppNewsletterOptInReducer = (state = initialState, action) => {
	switch (action.type) {
		case API_NEWSLETTER_OPT_IN_FAILURE:
		case API_NEWSLETTER_OPT_IN_SUCCESS:
		case API_NEWSLETTER_OPT_IN_REQUEST:
			return update(state, {
				result: {
					$set: Object.assign({}, state.result, action.payload),
				},
			});
		default:
			return state;
	}
};
