import React, { Component } from 'react';
import rootReducer from '../reducer';
import AuthActiveLanguageContainer from '../../../../esshop2_frontend/src/js/components/AuthActiveLanguageContainer';
import BaseApp from '../../../../esshop2_frontend/src/js/components/BaseApp';
import ShopApp from '../../../../esshop2_frontend/src/js/components/ShopApp';
import ShopAppHeaderArea from '../../../../esshop2_frontend/src/js/components/ShopAppHeaderArea';
import Header from './Header/Header';
import ShopAppFooterArea from '../../../../esshop2_frontend/src/js/components/ShopAppFooterArea';
import Footer from './Footer/Footer';
import '../../css/app.scss';
import LoginRegistrationPage from '../../../../esshop2_frontend/src/js/components/LoginRegistrationPage';
import AdditionalMenuRow from '../../../../esshop2_frontend/src/js/components/AdditionalMenuRow';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@material-ui/core';
import { theme } from '../../../../esshop2_frontend/src/js/shared/mui_setup/theme';

const pages = [
	require('../../../../esshop2_frontend/src/js/pages/Start'),
	require('../../../../esshop2_frontend/src/js/pages/Category'),
	require('../../../../esshop2_frontend/src/js/pages/NewProducts'),
	require('../../../../esshop2_frontend/src/js/pages/Manufacturers'),
	require('../../../../esshop2_frontend/src/js/pages/Manufacturer'),
	require('../../../../esshop2_frontend/src/js/pages/Search'),
	require('../../../../esshop2_frontend/src/js/pages/Sales'),
	require('../../../../esshop2_frontend/src/js/pages/ShoppingCart'),
	require('../../../../esshop2_frontend/src/js/pages/Checkout'),
	require('../../../../esshop2_frontend/src/js/pages/CheckoutType'),
	require('../../../../esshop2_frontend/src/js/pages/CheckoutSummary'),
	require('../../../../esshop2_frontend/src/js/pages/CheckoutDone'),
	require('../../../../esshop2_frontend/src/js/pages/Account'),
	require('../../../../esshop2_frontend/src/js/pages/ProductSingle'),
	require('../../../../esshop2_frontend/src/js/pages/ShippingCosts'),
	require('../../../../esshop2_frontend/src/js/pages/LegalNotice'),
	require('../../../../esshop2_frontend/src/js/pages/CookiePolicy'),
	require('../../../../esshop2_frontend/src/js/pages/PrivacyPolicy'),
	require('../../../../esshop2_frontend/src/js/pages/GeneralTermsCondition'),
	require('../../../../esshop2_frontend/src/js/pages/FrequentlyAskedQuestions'),
	require('../../../../esshop2_frontend/src/js/pages/Revocation'),
	require('../../../../esshop2_frontend/src/js/pages/Contact'),
	require('../../../../esshop2_frontend/src/js/pages/PasswordReset'),
	require('../../../../esshop2_frontend/src/js/pages/NewsletterConfirmation'),
	require('../../../../esshop2_frontend/src/js/pages/NewsletterCancelation'),
	require('../../../../esshop2_frontend/src/js/pages/ContactActivate'),
	require('../../../../esshop2_frontend/src/js/pages/LegoEducationSupport'),
	require('../../../../esshop2_frontend/src/js/pages/Group'),
];

class AppWrapper extends Component {
	render() {
		return (
			<ThemeProvider theme={theme}>
				<BaseApp
					rootReducer={rootReducer}
					activeLanguageContainer={<AuthActiveLanguageContainer />}
				>
					<ShopApp pages={pages} loginComponent={<LoginRegistrationPage />}>
						<ShopAppHeaderArea>
							<ToastContainer
								position="top-center"
								autoClose={2000}
								hideProgressBar
								newestOnTop={false}
								closeOnClick
								rtl={false}
								pauseOnFocusLoss={false}
								draggable={false}
								pauseOnHover
								limit={1}
								theme={'colored'}
							/>
							<Header />
							<AdditionalMenuRow />
						</ShopAppHeaderArea>
						<ShopAppFooterArea>
							<Footer />
						</ShopAppFooterArea>
					</ShopApp>
				</BaseApp>
			</ThemeProvider>
		);
	}
}

export default AppWrapper;
