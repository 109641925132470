import {registerTranslation} from "@esnpm/translate";
import {API_CATEGORIES_SHOP_MENU_SUCCESS} from "../actions/esshop2/apiCategoriesShopMenu";



const initialState = {
	loading: false,
	data: null,
	error: null,
};

export const CategoriesShopTranslationReducer = (state = initialState, action) => {
	let newState = null;

	switch (action.type) {

		case API_CATEGORIES_SHOP_MENU_SUCCESS:
			newState = Object.assign({}, state);

			Object.values(action.payload.data).map((v) => {
				const s = "categories." + v.PATH + ".NAME";
				newState = registerTranslation(newState, s, v.NAME);
			});

			return newState;

		default:

			return state;
	}
};
