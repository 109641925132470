import React from "react";
import {Route} from "react-router-dom";

const module = (shopApp) => {
	const NewsletterConfirmation = shopApp.dynamic(() => import("./NewsletterConfirmation"));

	const url = "/newsletter/confirmation";

	let config = {
		name: "NewsletterConfirmation",
		routes: [
			<Route key={url} exact path={url} component={NewsletterConfirmation}/>
		]
	};

	shopApp.registerPage(config);

};

export default module;
