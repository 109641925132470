import PropTypes from "prop-types";
import React from "react";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import Row from "react-bootstrap/es/Row";
import Col from "react-bootstrap/es/Col";
import Image from "react-bootstrap/es/Image";

class SimplePageHeader extends React.Component {
	static propTypes = {
    history: PropTypes.any,
    onLogoClick: PropTypes.func
  };

	static defaultProps = {
    onLogoClick: () => {}
  };

	render() {
		return (
			<Row className={"SimplePageHeader"}>
				<Col xs={12}>
					<Image
						responsive
						src={"/images/header/logo_positiv.png"}
						onClick={() => this.props.onLogoClick()}
					/>
					<hr/>
				</Col>
			</Row>
		);
	}
}

export default compose(
	withRouter
)(SimplePageHeader);
