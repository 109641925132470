import axios from "axios/index";
import {getAPIUrl} from "@esnpm/node-toolbox";
import Promise from "bluebird";
export const API_SESSION_CART_CHANGE_ADDRESS_POST_REQUEST = "API_SESSION_CART_CHANGE_ADDRESS_POST_REQUEST";
export const API_SESSION_CART_CHANGE_ADDRESS_POST_SUCCESS = "API_SESSION_CART_CHANGE_ADDRESS_POST_SUCCESS";
export const API_SESSION_CART_CHANGE_ADDRESS_POST_FAILURE = "API_SESSION_CART_CHANGE_ADDRESS_POST_FAILURE";

export const api_session_cart_change_address_post_request = (options) => {
	return {
		type: API_SESSION_CART_CHANGE_ADDRESS_POST_REQUEST,
		options: options,
		payload: {
			loading: true
		}
	};
};

export const api_session_cart_change_address_post_success = (data) => {
	return {
		type: API_SESSION_CART_CHANGE_ADDRESS_POST_SUCCESS,
		payload: {
			loading: false,
			data: data,
			error: null
		}
	};
};

export const api_session_cart_change_address_post_failure = (error) => {
	return {
		type: API_SESSION_CART_CHANGE_ADDRESS_POST_FAILURE,
		payload: {
			loading: false,
			error: error
		}
	};
};

/*
	Session.apiSessionCartChangeAddress_post
*/
export const apiSessionCartChangeAddress_post = (options) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(api_session_cart_change_address_post_request(options));
			const url=getAPIUrl([
				{"url": "/api/session/cart/change/address", "parameters": []},
			], options);
			axios({
				method: "post",
				url: url,
				data: {
					ADDRESS_LABEL: options.ADDRESS_LABEL,
					NEW_ADDRESS: options.NEW_ADDRESS,
					OLD_ADDRESS: options.OLD_ADDRESS,
				},
			}).then(
				(response) => {
					if (response.data.SUCCESSFUL) {
						dispatch(api_session_cart_change_address_post_success(response.data.DATA));
						resolve();
					} else {
						dispatch(api_session_cart_change_address_post_failure(response.data));
						resolve();
					}
				},
				(err) => {
					// eslint-disable-next-line no-console
					console.log(err);
					reject();
				}
			);
		}
	);
