import {APP_CUSTOMER_REGISTRATION_SET_ACCEPTED_SUCCESS, APP_CUSTOMER_REGISTRATION_SET_EDITED_SUCCESS} from "../../actions/App/AppRegistration";
import update from "immutability-helper";
import {API_CONTACT_EXISTS_POST_SUCCESS} from "../../actions/esshop2/apiContactExists_post";

const initialState = {
	edited: null,
	accepted: null,
	emailAlreadyExists: null,
};

export const AppCustomerRegistrationReducer = (state = initialState, action) => {
	switch (action.type) {
		case APP_CUSTOMER_REGISTRATION_SET_ACCEPTED_SUCCESS:
			return update(state, {
				accepted: {
					$set: action.payload.accepted,
				},
			});
		case APP_CUSTOMER_REGISTRATION_SET_EDITED_SUCCESS:
			return update(state, {
				edited: {
					$set: action.payload.edited,
				},
			});
		case API_CONTACT_EXISTS_POST_SUCCESS:
			return update(state, {
				emailAlreadyExists: {
					$set: action.payload.data.SUCCESSFUL,
				},
			});

		default:
			return state;
	}
};
