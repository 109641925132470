import {
	API_CATEGORIES_SHOP_MENU_FAILURE,
	API_CATEGORIES_SHOP_MENU_SUCCESS,
} from '../actions/esshop2/apiCategoriesShopMenu';

import { reduceCategories } from '@esnpm/esdb';
import _ from 'lodash';
import { throw_toast_alert } from '../lib/throw_toast_alert';

const initialState = {
	data: {},
	loading: false,
	error: null,
};

export const CategoriesShopReducer = (state = initialState, action) => {
	switch (action.type) {
		case API_CATEGORIES_SHOP_MENU_SUCCESS:
			return reduceCategories(state, action.payload);

		case API_CATEGORIES_SHOP_MENU_FAILURE:
			throw_toast_alert({
				msg: 'An error occured. Categories could not be loaded.',
				variant: 'error',
				exception: _.get(action.payload, 'error', null),
			});
			return state;

		default:
			return state;
	}
};
