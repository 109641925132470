import Promise from "bluebird";

export const APP_SHOW_LOGIN_FORM_SUCCESS = "APP_SHOW_LOGIN_FORM_SUCCESS";
export const APP_SHOW_FORGOT_PASSWORD_FORM_SUCCESS = "APP_SHOW_FORGOT_PASSWORD_FORM_SUCCESS";
export const APP_SHOW_REGISTRATION_FORM_SUCCESS = "APP_SHOW_REGISTRATION_FORM_SUCCESS";
export const APP_SHOW_LOGIN_REGISTER_PAGE_SUCCESS = "APP_SHOW_LOGIN_REGISTER_PAGE_SUCCESS";
export const APP_CUSTOMER_REGISTRATION_SET_EDITED_SUCCESS = "APP_CUSTOMER_REGISTRATION_SET_EDITED_SUCCESS";

export const AppShowLoginRegisterPage_success = (data) => {
	return {
		type: APP_SHOW_LOGIN_REGISTER_PAGE_SUCCESS,
		value: data,
	};
};
export const AppShowLoginRegisterPage = (data) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(AppShowLoginRegisterPage_success(data));
			resolve();
		},
	);


export const AppShowLoginForm = (data) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(AppShowLoginForm_success(data));
			resolve();
		},
	);

export const AppShowLoginForm_success = (data) => {
	return {
		type: APP_SHOW_LOGIN_FORM_SUCCESS,
		value: data,
	};
};

export const AppShowForgotPasswordForm = (data) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(AppShowForgotPasswordForm_success(data));
			resolve();
		},
	);

export const AppShowForgotPasswordForm_success = (data) => {
	return {
		type: APP_SHOW_FORGOT_PASSWORD_FORM_SUCCESS,
		value: data,
	};
};

export const AppShowRegistrationForm = (data) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(AppShowRegistrationForm_success(data));
			resolve();
		},
	);

export const AppShowRegistrationForm_success = (data) => {
	return {
		type: APP_SHOW_REGISTRATION_FORM_SUCCESS,
		value: data,
	};
};


// customerRegistration
export const APP_CUSTOMER_REGISTRATION_SET_ACCEPTED_SUCCESS = "APP_CUSTOMER_REGISTRATION_SET_ACCEPTED_SUCCESS";
export const AppCustomerRegistrationSetAccepted_success = (options) => {
	return {
		type: APP_CUSTOMER_REGISTRATION_SET_ACCEPTED_SUCCESS,
		payload: {
			accepted: options.accepted,
		},
	};
};
export const AppCustomerRegistrationSetAccepted = (options) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(AppCustomerRegistrationSetAccepted_success(options));
			resolve();
		},
	);

export const AppCustomerRegistrationSetEdited_success = (options) => {
	return {
		type: APP_CUSTOMER_REGISTRATION_SET_EDITED_SUCCESS,
		payload: {
			edited: options.edited,
		},
	};
};
export const AppCustomerRegistrationSetEdited = (options) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(AppCustomerRegistrationSetEdited_success(options));
			resolve();
		},
	);
