import {API_CONTACT_TRANSACTIONS_REQUEST, API_CONTACT_TRANSACTIONS_SUCCESS} from "../actions/esshop2/apiContactTransactions";
import {API_CONTACT_TRANSACTION_SUCCESS} from "../actions/esshop2/apiContactTransaction";
import _ from "lodash";
import {API_SHOP_TRANSACTION_BY_ORDER_NUMBER_FAILURE, API_SHOP_TRANSACTION_BY_ORDER_NUMBER_SUCCESS} from "../actions/esshop2/apiShopTransactionByOrderNumber";

const initialState = {
	loading: false,
	data: null,
	error: null,

};
export const ShopTransactionReducer = (state = initialState, action) => {
	switch (action.type) {
		case API_CONTACT_TRANSACTIONS_REQUEST:
			return Object.assign({},
				state,
				{
					loading: action.payload.loading,
				}
			);
		case API_CONTACT_TRANSACTIONS_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					loading: action.payload.loading,
					error: action.payload.error,
					data: action.payload.data
				}
			);

		case API_CONTACT_TRANSACTION_SUCCESS:
		case API_SHOP_TRANSACTION_BY_ORDER_NUMBER_SUCCESS:
			return (_.merge({}, state, {
				data: {
					TRANSACTIONS: {
						[action.payload.data.FULL_TR_NUMBER]: action.payload.data
					}
				}
			}));

		case API_SHOP_TRANSACTION_BY_ORDER_NUMBER_FAILURE:
			return _.merge({}, state, {
				error: true
			});

		default:
			return state;
	}
};

