import {APP_SHOW_FORGOT_PASSWORD_FORM_SUCCESS, APP_SHOW_LOGIN_FORM_SUCCESS, APP_SHOW_LOGIN_REGISTER_PAGE_SUCCESS, APP_SHOW_REGISTRATION_FORM_SUCCESS} from "../../actions/App/AppRegistration";
import {API_AUTH_LOGIN_POST_SUCCESS} from "@esnpm/eswebapp";
import {API_CONTACT_REGISTRATION_POST_FAILURE, API_CONTACT_REGISTRATION_POST_REQUEST, API_CONTACT_REGISTRATION_POST_SUCCESS} from "../../actions/esshop2/apiContactRegistration_post";
import {API_CONTACT_RESET_PASSWORD_REQUEST_POST_FAILURE, API_CONTACT_RESET_PASSWORD_REQUEST_POST_REQUEST, API_CONTACT_RESET_PASSWORD_REQUEST_POST_SUCCESS} from "../../actions/esshop2/apiContactResetPasswordRequest_post";
import update from "immutability-helper";


const initialState = {
	show_login_form: true,
	show_registration_form: false,
	show_forgot_password_form: false,
	show_login_register_page: false,
	registration: {
		result: {
			loading: false,
			data: null,
			error: null,
		}
	},
	reset_pw_request: {
		result: {
			loading: false,
			data: null,
			error: null,
		},
	}

};

export const AppRegistrationReducer = (state = initialState, action) => {
	switch (action.type) {
		case  APP_SHOW_LOGIN_FORM_SUCCESS:
			return Object.assign({}, state, {
				show_login_form: action.value,
				show_registration_form: false,
				show_forgot_password_form: false
			});

		case APP_SHOW_FORGOT_PASSWORD_FORM_SUCCESS:
			return Object.assign({}, state, {
				show_login_form: false,
				show_registration_form: false,
				show_forgot_password_form: action.value,
				reset_pw_request: {
					result: {
						loading: false,
						data: null,
						error: null,
					},
				}
			});
		case APP_SHOW_REGISTRATION_FORM_SUCCESS:
			return Object.assign({}, state, {
				show_login_form: false,
				show_registration_form: action.value,
				show_forgot_password_form: false,
				registration: {
					result: {
						loading: false,
						data: null,
						error: null,
					}
				},
			});


		case API_CONTACT_RESET_PASSWORD_REQUEST_POST_REQUEST:
		case API_CONTACT_RESET_PASSWORD_REQUEST_POST_FAILURE:
		case API_CONTACT_RESET_PASSWORD_REQUEST_POST_SUCCESS:
			return update(state, {
				reset_pw_request: {
					result: {
						$set: Object.assign({}, state.reset_pw_request.result, action.payload),
					},
				},
			});

		case APP_SHOW_LOGIN_REGISTER_PAGE_SUCCESS:
			return Object.assign({}, state, {
				show_login_register_page: action.value,
				registration: {
					result: {
						loading: false,
						data: null,
						error: null,
					}
				},
			});

		case API_AUTH_LOGIN_POST_SUCCESS:
			return Object.assign({}, state, {
				show_login_register_page: false,
				registration: {
					result: {
						loading: false,
						data: null,
						error: null,
					}
				}
			});

		case API_CONTACT_REGISTRATION_POST_SUCCESS:
		case API_CONTACT_REGISTRATION_POST_FAILURE:
		case API_CONTACT_REGISTRATION_POST_REQUEST:
			return update(state, {
				registration: {
					result: {
						$set: Object.assign({}, state.registration.result, action.payload),
					}
				},
			});
		default:
			return state;
	}
};
