import PropTypes from "prop-types";
import React from "react";
import {compose} from "redux";
import {withTr} from "@esnpm/translate";
import {trmButtons} from "../translations/trmButtons";
import {trmCorporate} from "../translations/trmCorporate";
import {trmCookie} from "../translations/trmCookie";
import {trmCustomer} from "../translations/trmCustomer";
import {trmFooter} from "../translations/trmFooter";
import {trmLanguages} from "../translations/trmLanguages";
import {trmLinks} from "../translations/trmLinks";
import {trmLogin} from "../translations/trmLogin";
import {trmNewsletter} from "../translations/trmNewsletter";
import {trmProduct} from "../translations/trmProduct";
import {trmRegistration} from "../translations/trmRegistration";
import {trmSideBar} from "../translations/trmSideBar";
import {trmUnits} from "../translations/trmUnits";
import {trmPolicies} from "../translations/trmPolicies";
import {trmNotifications} from "../translations/trmNotifications";
import {trmTransaction} from "../translations/trmTransaction";
import {trmMetaTexts} from "../translations/trmMetaTexts";
import {trmPages} from "../translations/pages/trmPages";
import {trmSearch} from "../translations/trmSearch";
import {trmAuth} from "@esnpm/eswebapp";
import {trmHeader} from "../translations/trmHeader";

class ShopTr extends React.Component {
	static propTypes = {
		children: PropTypes.any
	};

	render() {
		return this.props.children;
	}
}

export default compose(
	withTr([
		trmButtons,
		trmCorporate,
		trmCookie,
		trmCustomer,
		trmFooter,
		trmHeader,
		trmLanguages,
		trmLinks,
		trmLogin,
		trmNewsletter,
		trmProduct,
		trmRegistration,
		trmSideBar,
		trmUnits,
		trmPolicies,
		trmNotifications,
		trmTransaction,
		trmMetaTexts,
		trmPages,
		trmSearch,
		trmAuth
	]),
)(ShopTr);
