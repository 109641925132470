export const trmCookie = {
	pathesToCheck: ["cookie"],
	tr: {
		cookie: {
			CookieMessage: {
				DE: "Wir nutzen Cookies, um die Benutzerfreundlichkeit unserer Seite zu gewährleisten und zu verbessern. Mit Nutzung dieser Seite stimmen Sie unseren Cookie-Richtlinien und unserer Datenschutzerkärung zu.",
				EN: "We use cookies to ensure and improve the user-friendliness of our site. By using this site, you agree to our Cookie Policy and our Privacy Policy.",
				FR: "Nous utilisons des cookies pour assurer et améliorer la convivialité de notre site. En utilisant ce site, vous acceptez notre politique sur les cookies et notre politique de confidentialité."
			},
			CookieAgree: {
				DE: "Zustimmen",
				EN: "Agree",
				FR: "Agréé"
			}
		}
	}
};
