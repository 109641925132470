import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ProductContext, apiProduct } from '@esnpm/esdb';
import { new_custom_error } from '../../lib/throw_toast_alert';
import _ from 'lodash';

class ProductContainer extends Component {
	static propTypes = {
		SKU: PropTypes.number.isRequired,
		products: PropTypes.object.isRequired,
		ignoreLoading: PropTypes.bool,
		forceReload: PropTypes.bool,
		filterClass: PropTypes.string,
	};

	static defaultProps = {
		ignoreLoading: false,
		forceReload: false,
		filterClass: 'FULL',
	};

	componentDidMount() {
		this.loadProduct(this.props.SKU);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.SKU !== this.props.SKU) {
			this.loadProduct(this.props.SKU);
		}
	}

	loadProduct = (SKU) => {
		if (
			this.props.forceReload ||
			_.get(
				this.props.products,
				[this.props.SKU, 'filterClass', this.props.filterClass],
				null
			) == null
		) {
			this.props
				.dispatch(
					apiProduct({
						SKU: SKU,
						FILTER_CLASS: this.props.filterClass,
					})
				)
				.catch((e) => {
					throw new_custom_error({
						error_class: 'ProductNotFoundException',
						error_msg: 'Product with ' + SKU + ' not found.',
					});
				});
		}
	};

	getContextValues = (product) => {
		return Object.assign({}, product.data, { _error: product.error }, {});
	};

	handleLoading = () => {
		return null;
	};

	render() {
		if (
			_.get(
				this.props.products,
				[this.props.SKU, 'filterClass', this.props.filterClass],
				null
			) == null
		)
			return null;
		const { products, SKU } = this.props;

		const product = products[SKU];
		return (
			<ProductContext.Provider value={this.getContextValues(product)}>
				{this.props.children}
			</ProductContext.Provider>
		);
	}
}

export default compose(connect((state) => ({ products: state.products })))(
	ProductContainer
);
