import axios from "axios/index";
import {getAPIUrl} from "@esnpm/node-toolbox";
import Promise from "bluebird";
export const API_SHIPPING_METHOD_SHIPPING_COSTS_BY_COUNTRY_CODE_POST_REQUEST = "API_SHIPPING_METHOD_SHIPPING_COSTS_BY_COUNTRY_CODE_POST_REQUEST";
export const API_SHIPPING_METHOD_SHIPPING_COSTS_BY_COUNTRY_CODE_POST_SUCCESS = "API_SHIPPING_METHOD_SHIPPING_COSTS_BY_COUNTRY_CODE_POST_SUCCESS";
export const API_SHIPPING_METHOD_SHIPPING_COSTS_BY_COUNTRY_CODE_POST_FAILURE = "API_SHIPPING_METHOD_SHIPPING_COSTS_BY_COUNTRY_CODE_POST_FAILURE";

export const api_shipping_method_shipping_costs_by_country_code_post_request = (options) => {
	return {
		type: API_SHIPPING_METHOD_SHIPPING_COSTS_BY_COUNTRY_CODE_POST_REQUEST,
		options: options,
		payload: {
			loading: true
		}
	};
};

export const api_shipping_method_shipping_costs_by_country_code_post_success = (data) => {
	return {
		type: API_SHIPPING_METHOD_SHIPPING_COSTS_BY_COUNTRY_CODE_POST_SUCCESS,
		payload: {
			loading: false,
			data: data,
			error: null
		}
	};
};

export const api_shipping_method_shipping_costs_by_country_code_post_failure = (error) => {
	return {
		type: API_SHIPPING_METHOD_SHIPPING_COSTS_BY_COUNTRY_CODE_POST_FAILURE,
		payload: {
			loading: false,
			error: error
		}
	};
};

/*
	ShippingMethod.apiShippingMethodShippingCostsByCountryCode_post
	returns dict with Prices
			:param COUNTRY_CODE:
			:param CUSTOMER_TYPE:
			:return: {Weights{Methods{Price}}}
*/
export const apiShippingMethodShippingCostsByCountryCode_post = (options) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(api_shipping_method_shipping_costs_by_country_code_post_request(options));
			const url=getAPIUrl([
				{"url": "/api/shipping_method/shipping_costs/country_code", "parameters": []},
			], options);
			axios({
				method: "post",
				url: url,
				data: {
					CUSTOMER_TYPE: options.CUSTOMER_TYPE,
					COUNTRY_CODE: options.COUNTRY_CODE,
				},
			}).then(
				(response) => {
					if (response.data.SUCCESSFUL) {
						dispatch(api_shipping_method_shipping_costs_by_country_code_post_success(response.data.DATA));
						resolve();
					} else {
						dispatch(api_shipping_method_shipping_costs_by_country_code_post_failure(response.data));
						resolve();
					}
				},
				(err) => {
					// eslint-disable-next-line no-console
					console.log(err);
					reject();
				}
			);
		}
	);
