export const trmPages = {
	pathesToCheck: ["pages"],
	tr: {
		pages: {
			Start: {
				ALL: "Start"
			},
			ShippingCosts: {
				FR: "Frais de livraison",
				DE: "Versandkosten",
				EN: "Shipping Costs",
			},
			LegalNotice: {
				DE: "Impressum",
				EN: "Legal Notice",
				FR: "Mentions légales",
			},
			GTC: {
				DE: "Allgemeine Geschäftsbedingungen",
				EN: "General Terms and Conditions",
				FR: "Conditions générales de vente",
			},
			Revocation: {
				DE: "Widerrufsrecht",
				EN: "Right of cancellation",
				FR: "Droit de rétraction"
			},
			Contact: {
				DE: "Kontakt",
				EN: "Contact",
				FR: "Contact"
			},
			Search: {
				DE: "Ihr Suchergebnis",
				EN: "Your search result",
				FR: "Votre résultat de recherche"
			},
			Account: {
				DE: "Benutzerkonto",
				EN: "Account",
				FR: "Compte utilisateur"
			},
			ShoppingCart: {
				DE: "Ihr Warenkorb",
				EN: "Your Shopping Cart",
				FR: "Votre panier",
			},
			Checkout: {
				DE: "Meine Bestellung bei Electronic-Shop",
				EN: "My order at Electronic-Shop",
				FR: "Ma commande chez Electronic-Shop",
			},
			PasswordReset: {
				DE: "Passwort zurücksetzen",
				EN: "Reset Password",
				FR: "réinitialiser le mot de passe",
			},
			ContactActivate: {
				DE: "Registrierung abgeschlossen",
				EN: "Registration completed",
				FR: "Inscription terminée"
			},
			ContactNotActivate: {
				DE: "Registrierung",
				EN: "Registration",
				FR: "Inscription"
			},
			Newsletter: {
				ALL: "Newsletter",
			},
			CheckoutSummary: {
				DE: "Meine Bestellübersicht",
				EN: "My order summary",
				FR: "Aperçu de ma commande",
			},
			CheckoutDone: {
				DE: "Bestellung war erfolgreich",
				EN: "Order was successful",
				FR: "La commande a été couronnée de succès",
			},
			Manufacturers: {
				DE: "Hersteller A-Z",
				EN: "Manufacturer A-Z",
				FR: "Fabricant A-Z",
			},
			RegistrationSuccessful: {
				DE: "Ihre Registrierung ist fast abgeschlossen",
				EN: "Your registration is almost complete",
				FR: "Votre inscription est presque terminée"
			},
			NewProducts: {
				DE: "Neu und auf Lager",
				EN: "New Arrivals",
				FR: "Nouveaux produits",
			},
			Sale: {
				DE: "Angebote",
				EN: "Sale",
				FR: "Promotions"
			},
			FAQ: {
				DE: "Fragen & Antworten",
				EN: "Frequently Answered Questions",
				FR: "Foire aux questions"
			},
			PrivacyPolicy: {
				DE: "Datenschutzerklärung",
				EN: "Privacy Policy",
				FR: "Politique de Confidentialité"
			},
			CookiePolicy: {
				DE: "Cookie-Hinweis",
				EN: "Cookie Policy",
				FR: "Politique sur les cookies"
			},
		}
	}
};
