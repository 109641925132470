import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {withTrContext} from "@esnpm/translate";
import {ESLoadable} from "@esnpm/react-toolbox";
import {ESForm} from "@esnpm/forms";
import React from "react";
import {compose} from "redux";


class CategoriesSelect extends React.Component {

	static propTypes = {
		app: PropTypes.any,
		categories: PropTypes.any,
		history: PropTypes.any,
		trProvider: PropTypes.any
	};

	shouldComponentUpdate(nextProps) {
		return this.props.app.sidebarCategory.loading || (nextProps.app.sidebarCategory.catPath === nextProps.app.sidebarCategory.highlight);
	}


	mapCategoryPaths() {

		const cat = this.props.categories.data[this.props.app.sidebarCategory.catPath];
		let ret = [];
		Object.values(cat.CHILDREN).map((path) => {
			ret.push({
				value: path,
				textTr: "categories." + path + ".NAME",
				className: "subcat"
			});
		});
		ret = this.sortOptions(ret);

		return ret;
	}

	sortOptions(options) {
		//Hier werden aus den TrPath richtige Übersetzungen an die Options gehangen:
		let options_with_translations = options.map((entry) => {
			let text = entry.text;
			if (entry.text == null && entry.textTr != null) {
				text = this.props.trProvider.tr(entry.textTr);
			}
			return {value: entry.value, text: text, className: entry.className};
		});

		//alphabetisch sortieren:
		options_with_translations.sort((a, b) => {
			return a.text.localeCompare(b.text);
		});

		return options_with_translations;
	}

	render() {
		if (this.props.app.sidebarCategory.catPath == null) return null;
		let sub_cats = this.mapCategoryPaths();

		let current_cat = this.props.app.sidebarCategory.catPath.split(".");
		current_cat.splice(-1, 1);
		let parent_cat = current_cat.join(".");

		let header_cat = {
			value: parent_cat,
			textTr: "header.ChooseParentCat",
			className: "headercat"
		};

		let all_options;
		let placeHolderPath = "buttons.CategorySelect";

		// if (this.props.app.sidebarCategory.highlight !== "1.2" && this.props.location.pathname !== "/") {
		if (this.props.app.sidebarCategory.highlight !== "1.2") {
			all_options = [header_cat].concat(sub_cats);
			placeHolderPath = "categories." + this.props.app.sidebarCategory.highlight + ".NAME";
		} else {
			all_options = sub_cats;
		}
		return (
			<ESLoadable
				active={this.props.app.sidebarCategory.loading}
			>
				<ESForm noValidationClassChange
								noValidationLabelClassChange
								form
				>
					<ESForm.Select
						noLabel
						fieldName={"CATEGORY_SELECT"}
						options={all_options}
						// loadOptions={() => this.mapCategoryPaths()}
						value={this.props.app.sidebarCategory.highlight}
						onChange={(cat) => {
							if (cat == "1.2") {
								this.props.history.push("/");
							} else {
								this.props.history.push("/category/" + cat);
							}
						}}
						sortEntries={false}
						trPlaceHolder={placeHolderPath}
					/>

				</ESForm>
			</ESLoadable>
		);
	}
}

export default compose(
	connect(
		(state) => (
			{
				app: {
					sidebarCategory: state.app.sidebarCategory,
				},
				categories: state.categories,

			}
		)
	),
	withRouter,
	withTrContext
)(CategoriesSelect);
