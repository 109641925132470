import _ from "lodash";
import * as Sentry from "@sentry/react";

const payloadDefaults = {
	CUSTOMER_ID: null,
	EMAIL: null,
	CLIENT_ID: null
};

export const setShopPayload = (options) => {
	window._es_shopPayload = _.merge({}, payloadDefaults, window._es_shopPayload, options);
	if (window._es_sentryEnabled) {
		// Sentry set email
		if (_.has(options, "CUSTOMER_ID") || _.has(options, "EMAIL")) {
			Sentry.setUser({
				"id": options.CUSTOMER_ID,
				"email": options.EMAIL,
				"username": options.EMAIL
			});
		}
		// Sentry set client id
		if (_.has(options, "CLIENT_ID")) {
			Sentry.setTag("client_id", options.CLIENT_ID);
		}
	}
};
