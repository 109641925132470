import {Link} from "react-router-dom";
import React from "react";
import {compose} from "redux";
import WidgetWithIcon from "./WidgetWithIcon";
import CommentAccountOutlineIcon from "mdi-react/CommentAccountOutlineIcon";
import {Tr} from "@esnpm/translate";

/**
 * Widget Contact */
class WidgetContact extends React.Component {
	render() {
		return (
			<WidgetWithIcon
				icon={<CommentAccountOutlineIcon/>}
				headline={<Tr path={"footer.Widgets.Contact.Headline"}/>}
				content={<Tr path={"footer.Widgets.Contact.Content"}/>}
				callToAction={<Link to={"/contact"}><Tr path={"footer.Widgets.Contact.CallToAction"}/></Link>}
			/>
		);
	}
}
export default compose(

)(WidgetContact);
