import PropTypes from 'prop-types';
import React from 'react';
import * as Sentry from '@sentry/react';
import { parse_exception_to_js_error } from '../lib/throw_toast_alert';

class SentryErrorBoundary extends React.Component {
	static defaultProps = {
		enabled: true,
	};

	static propTypes = {
		children: PropTypes.any,
		enabled: PropTypes.bool,
	};

	constructor(props) {
		super(props);
		this.state = { error: null };
	}

	componentDidCatch(error, errorInfo) {
		if (this.props.enabled) {
			const parsed_exception = parse_exception_to_js_error(error);
			Sentry.withScope((scope) => {
				Object.keys(errorInfo).forEach((key) => {
					scope.setExtra(key, errorInfo[key]);
				});
				scope.setTag('error.caught', 'SentryErrorBoundary');
				Sentry.captureException(parsed_exception, scope);
				scope.clear();
			});
			this.setState({ error });
		}
	}

	render() {
		if (this.state.error && this.props.enabled) {
			//render fallback UI
			return (
				<React.Fragment>
					<span>Unfortunately an error has happened - </span>
					<a onClick={() => Sentry.showReportDialog()}>Report feedback</a>
				</React.Fragment>
			);
		} else {
			//when there's not an error, render children untouched
			return this.props.children;
		}
	}
}

export default SentryErrorBoundary;
