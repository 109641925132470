import React from "react";
import FooterMetaInfo from "./FooterMetaInfo";
import FooterInfo from "./FooterInfo";
import FooterBranding from "./FooterBranding";
import Grid from "react-bootstrap/es/Grid";
import {compose} from "redux";

/**
 * Main Footer Component */
class Footer extends React.Component {
	render() {
		return (
			<Grid className={"FooterContainer"}>
				<FooterInfo/>
				<FooterBranding/>
				<FooterMetaInfo/>
			</Grid>
		);
	}
}

export default compose(

)(Footer);


