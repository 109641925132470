import _ from 'lodash';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';

const debug = false;

export const new_custom_error = ({ error_class, error_msg }) => {
	let new_error = new Error();
	new_error.name = error_class;
	new_error.message = error_msg;
	return new_error;
};

export const parse_exception_to_js_error = (exception) => {
	// If exception is already JS Error, return exception
	if (exception instanceof Error) return exception;
	// Determinate error class
	const error_class =
		typeof exception === 'string' ? exception : _.get(exception, 'ERROR_CLASS');
	// Return exception if error_class is null
	if (_.isNil(error_class)) return new Error(exception);
	// Determinate error msg
	const error_msg = _.get(exception, 'ERROR', error_class);
	// Return new custom error
	return new_custom_error({ error_class, error_msg });
};

const enrich_sentry_data_with_exception_data = (scope, exception) => {
	// Return scope if exception has no ERROR_CLASS
	if (!_.has(exception, 'ERROR_CLASS')) return scope;
	// Set new context with exception data @scope
	scope.setContext('exception_data', exception);
	// Return scope
	return scope;
};

const enrich_sentry_data_with_session = (scope, session) => {
	// Return scope if session is null
	if (_.isNil(session)) return scope;
	// Extract data from session and set as tag @scope
	const account_id = _.get(session, ['ACCOUNT_ID'], null);
	if (!_.isNil(account_id)) scope.setTag('account_id', account_id);
	scope.setTag('session_id', _.get(session, ['SESSION_ID']));
	scope.setTag('client_ids', _.get(session, ['CLIENT_IDS'], []).join());
	// Return scope
	return scope;
};

export const throw_toast_alert = (options) => {
	// Default options
	const default_options = {
		msg: 'It seems that something went wrong.',
		exception: null,
		send_to_sentry: false,
		variant: 'info',
		session: null,
	};

	// Merge options into default options
	const { msg, exception, send_to_sentry, variant, session } = _.merge(
		{},
		default_options,
		options
	);

	// Log options if debug is true
	if (debug) {
		console.log('msg', msg);
		console.log('exception', exception);
		console.log('exception', parse_exception_to_js_error(exception));
		// console.log('session', session);
		// console.log('generated stack', new Error().stack);
	}

	// Throw snackbar
	if (_.isFunction(toast)) {
		switch (variant) {
			case 'info': {
				toast.info(msg);
				break;
			}
			case 'error': {
				toast.error(msg);
				break;
			}
			default:
				toast(msg);
		}
	}

	// Sentry error handling
	if (!_.isNil(exception) && send_to_sentry) {
		// Parse exception to instance of Error
		const parsed_exception = parse_exception_to_js_error(exception);
		// Enrich scope
		let scope = new Sentry.Scope();
		scope = enrich_sentry_data_with_exception_data(scope, exception);
		scope = enrich_sentry_data_with_session(scope, session);
		scope.setTag('error.caught', 'Toast Alert');
		// Log scope if debug is true
		if (debug) console.log('scope', scope);
		// Send exception and scope to sentry
		Sentry.captureException(parsed_exception, scope);
		// Clear scope
		scope.clear();
		// Log cleared scope if debug is true
		if (debug) console.log('cleared scope', scope);
	}
};
