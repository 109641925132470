import PropTypes from 'prop-types';
import React from 'react';
import { apiElasticsearch_search_post } from '@esnpm/esdb';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { ESInput } from '@esnpm/forms';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import AutoComplete from '../../../../../esshop2_frontend/src/js/components/AutoComplete';

class SearchBar extends React.Component {
	static propTypes = {
		autocomplete: PropTypes.any,
		history: PropTypes.any,
		location: PropTypes.any,
	};

	constructor(props) {
		super(props);
		this.state = {
			searchString: '',
			showAutoComplete: false,
			selected: null,
			iter: -1,
		};
		this.timeout = 0;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.searchString !== this.state.searchString) {
			if (this.state.searchString.length >= 3) {
				clearTimeout(this.timeout);
				this.timeout = setTimeout(() => {
					this.handleSearch(this.state.searchString);
				}, 500);
			}
		}
	}

	handleSearch(val) {
		// render-api: Change options.VIEW?
		const options = {
			q: val,
			SKIP: '0',
			LIMIT: '7',
			SORT: { SKU: -1 },
			FILTER_CLASS: 'LIST',
			VIEW: 'autocomplete',
		};
		this.props.dispatch(apiElasticsearch_search_post(options));
	}

	resetSearch() {
		this.setState({
			searchString: '',
			showAutoComplete: false,
			selected: null,
			iter: -1,
		});
	}

	handleSearchEnter() {
		if (this.state.selected != null) {
			this.openSelected();
		} else {
			if (this.props.autocomplete.data != null) {
				if (this.props.autocomplete.data.MAX_HIT === 1) {
					this.openSingleResult();
				} else {
					this.openSearchString();
				}
			}
		}
	}

	handleSearchClick() {
		if (this.props.autocomplete.data != null) {
			if (this.props.autocomplete.data.MAX_HIT === 1) {
				this.openSingleResult();
			} else {
				this.openSearchString();
			}
		}
	}

	openSearchString() {
		this.props.history.push('/search?q=' + encodeURI(this.state.searchString));
		this.resetSearch();
	}

	openSelected() {
		this.props.history.push('/product/' + this.state.selected);
		this.resetSearch();
	}

	openSingleResult() {
		this.props.history.push(
			'/product/' + this.props.autocomplete.data.PRODUCTS[0]
		);
		this.resetSearch();
	}

	handleSearchChange(val) {
		const search_terms = val
			.split(' ')
			.filter((term) => term.length >= 2)
			.join(' ');

		this.setState({
			searchString: search_terms,
			showAutoComplete: true,
			selected: null,
			iter: -1,
		});
	}

	handleKeyDown(e) {
		const products = _.get(this.props.autocomplete, ['data', 'PRODUCTS'], []);
		if (products.length === 0) return null;

		let selected = null;
		let iter = null;

		if (e.key === 'ArrowUp') {
			e.preventDefault();
			if (this.state.iter > -1) {
				selected = products[this.state.iter - 1];
				iter = this.state.iter - 1;

				this.setState({
					selected: selected,
					iter: iter,
				});
			}
		} else if (e.key === 'ArrowDown') {
			e.preventDefault();
			if (this.state.iter < 7) {
				selected = products[this.state.iter + 1];
				iter = this.state.iter + 1;

				this.setState({
					selected: selected,
					iter: iter,
				});
			}
		}
	}

	handleOnBlur() {
		this.setState({
			showAutoComplete: false,
		});
	}

	render() {
		return (
			<div
				onKeyDown={this.handleKeyDown.bind(this)}
				onBlur={this.handleOnBlur.bind(this)}
			>
				<ESInput.InputButtonGroupText
					icon={MagnifyIcon}
					onEnter={this.handleSearchEnter.bind(this)}
					onButtonClick={this.handleSearchClick.bind(this)}
					onChange={this.handleSearchChange.bind(this)}
					value={this.state.searchString}
					autoFocus={this.props.location.pathname === '/'}
					ref={(c) => {
						this.searchInput = c;
					}}
					bsStyle={'primary'}
				/>
				{this.renderAutocomplete()}
			</div>
		);
	}

	renderAutocomplete() {
		if (this.state.searchString.length < 3) return null;
		if (!this.state.showAutoComplete) return null;
		if (
			this.props.autocomplete.data !== null &&
			this.state.searchString !== this.props.autocomplete.data.SEARCH_STRING
		)
			return null;
		return (
			<AutoComplete
				searchBar={this}
				selected={this.state.selected}
				searchString={this.state.searchString}
			/>
		);
	}
}

export default compose(
	connect((state) => ({
		autocomplete: state.autocomplete,
	})),
	withRouter
)(SearchBar);
