import React from "react";
import {Route} from "react-router-dom";

const module = (shopApp) => {
	const GeneralTermsConditions = shopApp.dynamic(() => import("./GeneralTermsConditions"));

	const url = "/gtc";

	let config = {
		name: "GeneralTermsConditions",
		routes: [
			<Route key={url} exact path={url} component={GeneralTermsConditions}/>
		]
	};

	shopApp.registerPage(config);

};

export default module;
