import {APP_PAGE_SET_PREFERENCES_SUCCESS} from "../../actions/App/Page/AppPageSetPreferences";

const initialState = {
	footerVisible: true,
	headerVisible: true,
};

export const AppPageReducer = (state = initialState, action) => {
	switch (action.type) {
		case APP_PAGE_SET_PREFERENCES_SUCCESS:
			return Object.assign({}, state, action.payload);
		default:
			return state;
	}
};
