import PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";
import RegistrationForm from "./RegistrationForm";
import {compose} from "redux";
import {ResponsiveRender} from "@esnpm/react-toolbox";

class RegistrationDialog extends React.Component {
	static propTypes = {
		app: PropTypes.any
	};

	render() {
		if (this.props.app.registration.show_registration_modal) {
			return null;
		} else {
			return (
				<React.Fragment>
					<ResponsiveRender xs>
						<RegistrationForm horizontal={false}/>
					</ResponsiveRender>
					<ResponsiveRender sm md lg>
						<RegistrationForm horizontal={true}/>
					</ResponsiveRender>
				</React.Fragment>
			);
		}

	}
}

export default compose(
	connect(
		(state) => (
			{
				app: state.app
			}
		)
	)
)(RegistrationDialog);
