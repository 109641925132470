import PropTypes from "prop-types";
import React from "react";
import {compose} from "redux";
import {Link} from "react-router-dom";
import {AppShowForgotPasswordForm} from "../actions/App/AppRegistration";
import {ESForm, ESValidator} from "@esnpm/forms";
import {apiAuthLogin_post, AuthLoginError} from "@esnpm/eswebapp";
import Row from "react-bootstrap/es/Row";
import Col from "react-bootstrap/es/Col";
import Button from "react-bootstrap/es/Button";
import {Tr} from "@esnpm/translate";
import {withShopAppContext} from "./ShopAppContext";
import {connect} from "react-redux";
import {apiSessionUpdateTotal} from "../actions/esshop2/apiSessionUpdateTotal";

class LoginForm extends React.Component {

	static defaultProps = {
		horizontal: true
	};

	static propTypes = {
		horizontal: PropTypes.any,
		app: PropTypes.any,
		shopApp: PropTypes.any,
		currentUser: PropTypes.object
	};

	constructor(props) {
		super(props);
		this.state = {
			button_disable: true,
		};
	}

	handleLoginAction() {
		let data = this.loginForm.getWrappedInstance().value();
		this.props.dispatch(
			apiAuthLogin_post({
				USERNAME: data["EMAIL"],
				PASSWORD: data["PASSWORD"],
			})).then(
			() => {
				this.props.dispatch(apiSessionUpdateTotal()).then(() => {
					if (this.props.currentUser.error == null) {
						this.props.shopApp.setShowLoginComponent(false);
					}
				});
			}
		);
	}

	handleLoginButton() {
		this.handleLoginAction();
	}

	handleFormValidChange(valid) {
		this.setState({button_disable: !valid});
	}

	render() {
		return (
			<React.Fragment>
				<AuthLoginError/>
				<ESForm
					form
					horizontal={false}
					classValid={"ESFormFieldValid"}
					onValidChange={this.handleFormValidChange.bind(this)}
					ref={c => {
						this.loginForm = c;
					}}
					onEnter={() => this.handleLoginAction()}
				>
					<ESForm.Email
						autoComplete={true}
						validators={[
							new ESValidator.MinLength(2),
							new ESValidator.MaxLength(50),
							new ESValidator.Pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+[A-Za-z0-9-]+\\.[A-Za-z]{2,6}$")
						]}
						noValidationLabelClassChange
					/>
					<ESForm.Password
						noHint
						noValidationClassChange
						noValidationLabelClassChange
						validators={[
							new ESValidator.MinLength(6),
							new ESValidator.Pattern("^[a-zA-Z0-9\\-_\\.,!?:\\-&\\/äöüÄÖÜß]{6,20}$")
						]}
						autoComplete={false}
					/>
				</ESForm>
				<Row>
					<Col xs={12}>
						<Link
							onClick={() => {
								this.props.dispatch(AppShowForgotPasswordForm(true));
							}}
							className={"fsSmall Link"}
							to={"#"}
						>
							<Tr
								path={"loginDialog.ForgotPassword"}/>
						</Link>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<Button
							className={"pull-right"}
							bsStyle={"primary"}
							onClick={this.handleLoginButton.bind(this)}
							disabled={this.state.button_disable}
						>
							<Tr path={"buttons.Login"}/>
						</Button>
					</Col>
				</Row>
			</React.Fragment>
		);
	}
}


export default compose(
	withShopAppContext,
	connect(
		(state) => (
			{
				app: {
					registration: state.app.registration,
				},
				currentSession: state.currentSession,
				currentUser: state.currentUser,
				tr: state.tr
			}
		)
	)
)(LoginForm);
