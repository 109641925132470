import axios from "axios/index";
import {getAPIUrl} from "@esnpm/node-toolbox";
import Promise from "bluebird";
export const API_CONTACT_EMAIL_TO_DEPARTMENT_POST_REQUEST = "API_CONTACT_EMAIL_TO_DEPARTMENT_POST_REQUEST";
export const API_CONTACT_EMAIL_TO_DEPARTMENT_POST_SUCCESS = "API_CONTACT_EMAIL_TO_DEPARTMENT_POST_SUCCESS";
export const API_CONTACT_EMAIL_TO_DEPARTMENT_POST_FAILURE = "API_CONTACT_EMAIL_TO_DEPARTMENT_POST_FAILURE";

export const api_contact_email_to_department_post_request = (options) => {
	return {
		type: API_CONTACT_EMAIL_TO_DEPARTMENT_POST_REQUEST,
		options: options,
		payload: {
			loading: true
		}
	};
};

export const api_contact_email_to_department_post_success = (data) => {
	return {
		type: API_CONTACT_EMAIL_TO_DEPARTMENT_POST_SUCCESS,
		payload: {
			loading: false,
			data: data,
			error: null
		}
	};
};

export const api_contact_email_to_department_post_failure = (error) => {
	return {
		type: API_CONTACT_EMAIL_TO_DEPARTMENT_POST_FAILURE,
		payload: {
			loading: false,
			error: error
		}
	};
};

/*
	Contact.apiContactEmailToDepartment_post
*/
export const apiContactEmailToDepartment_post = (options) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(api_contact_email_to_department_post_request(options));
			const url=getAPIUrl([
				{"url": "/api/contact/email/department", "parameters": []},
			], options);
			axios({
				method: "post",
				url: url,
				data: {
					ORDER: options.ORDER,
					FIRSTNAME: options.FIRSTNAME,
					EMAIL: options.EMAIL,
					MESSAGE: options.MESSAGE,
					LASTNAME: options.LASTNAME,
					SKU: options.SKU,
					COMPANY: options.COMPANY,
					DEPARTMENT: options.DEPARTMENT,
				},
			}).then(
				(response) => {
					if (response.data.SUCCESSFUL) {
						dispatch(api_contact_email_to_department_post_success(response.data.DATA));
						resolve();
					} else {
						dispatch(api_contact_email_to_department_post_failure(response.data));
						resolve();
					}
				},
				(err) => {
					// eslint-disable-next-line no-console
					console.log(err);
					reject();
				}
			);
		}
	);
