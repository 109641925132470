export const trmRegistration = {
	pathesToCheck: ["registerDialog"],
	tr: {
		registerTeaser: {
			Headline: {
				DE: "Willkommen bei Electronic-Shop!",
				EN: "Welcome to Electronic-Shop!",
				FR: "Bienvenue sur Electronic-Shop !"
			},
			Content: {
				DE: "Hier können Sie sich in Ihren Account einloggen, sich für in unserem Shop registrieren oder Ihr Passwort zurücksetzen.",
				EN: "Here you can log in to your account, register for our shop or reset your password.",
				FR: "Ici, vous pouvez vous connecter à votre compte, vous inscrire à notre boutique ou réinitialiser votre mot de passe."
			}
		},
		registerDialog: {
			Register: {
				DE: "Registrieren",
				EN: "Register",
				FR: "Inscription",
			},
			Alert: {
				EMailAlreadyExistsException: {
					FR: "Cette adresse email est déjà utilisée.",
					DE: "Diese E-Mail-Adresse ist bereits in Benutzung.",
					EN: "This e-mail address is already in use.",
				},

				RegistrationEMailAlreadySendException: {
					FR: "Un e-mail avec le lien d'activation a déjà été envoyé à cette adresse.",
					DE: "Es wurde bereits eine EMail mit dem Aktivierungs-Link an diese Adresse verschickt.",
					EN: "An email with the activation link has already been sent to this address."
				},
				Note: {}
			},
			Processing: {
				DE: "Ihre Registrierung wird verarbeitet.",
				EN: "Your registration will be processed.",
				FR: "Votre inscription sera traitée."
			}
		}
	}
};
