import axios from "axios/index";
import {getAPIUrl} from "@esnpm/node-toolbox";
import Promise from "bluebird";
export const API_CONTACT_REGISTRATION_POST_REQUEST = "API_CONTACT_REGISTRATION_POST_REQUEST";
export const API_CONTACT_REGISTRATION_POST_SUCCESS = "API_CONTACT_REGISTRATION_POST_SUCCESS";
export const API_CONTACT_REGISTRATION_POST_FAILURE = "API_CONTACT_REGISTRATION_POST_FAILURE";

export const api_contact_registration_post_request = (options) => {
	return {
		type: API_CONTACT_REGISTRATION_POST_REQUEST,
		options: options,
		payload: {
			loading: true
		}
	};
};

export const api_contact_registration_post_success = (data) => {
	return {
		type: API_CONTACT_REGISTRATION_POST_SUCCESS,
		payload: {
			loading: false,
			data: data,
			error: null
		}
	};
};

export const api_contact_registration_post_failure = (error) => {
	return {
		type: API_CONTACT_REGISTRATION_POST_FAILURE,
		payload: {
			loading: false,
			error: error
		}
	};
};

/*
	Contact.apiContactRegistration_post
*/
export const apiContactRegistration_post = (options) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(api_contact_registration_post_request(options));
			const url=getAPIUrl([
				{"url": "/api/contact/registration", "parameters": []},
			], options);
			axios({
				method: "post",
				url: url,
				data: {
					STREET: options.STREET,
					CUSTOMER_TYPE: options.CUSTOMER_TYPE,
					COUNTRY_CODE: options.COUNTRY_CODE,
					COMPANY: options.COMPANY,
					NEWSLETTER: options.NEWSLETTER,
					ZIPCODE: options.ZIPCODE,
					FIRSTNAME: options.FIRSTNAME,
					EMAIL: options.EMAIL,
					LASTNAME: options.LASTNAME,
					TELEPHONE: options.TELEPHONE,
					PASSWORD: options.PASSWORD,
					CITY: options.CITY,
					VATID: options.VATID,
					DEPARTMENT: options.DEPARTMENT,
				},
			}).then(
				(response) => {
					if (response.data.SUCCESSFUL) {
						dispatch(api_contact_registration_post_success(response.data.DATA));
						resolve();
					} else {
						dispatch(api_contact_registration_post_failure(response.data));
						resolve();
					}
				},
				(err) => {
					// eslint-disable-next-line no-console
					console.log(err);
					reject();
				}
			);
		}
	);
