import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import PercentIcon from "mdi-react/PercentIcon";
import React from "react";
import {InlineIcon, ResponsiveRender} from "@esnpm/react-toolbox";
import {Tr} from "@esnpm/translate";
import {compose} from "redux";
import Grid from "react-bootstrap/es/Grid";

class AdditionalMenuRow extends React.Component {
	render() {
		return (
			<ResponsiveRender sm md lg>
				<Grid>
					<Row className={"AdditionalMenuRow"}>
						<Col xs={12}>
							<ul>
								<li role={"presentation"}>
									<Link to={"/new_products"} role={"button"}>
										<Tr path={"links.NewProducts"}/>
									</Link>
								</li>
								<li role={"presentation"}>
									<Link to={"/manufacturers"} role={"button"}>
										<Tr path={"links.ManufacturerAZ"}/>
									</Link>
								</li>
								<li role={"presentation"}>
									<Link to={"/sales"} className={"circle"}>
										<InlineIcon icon={PercentIcon}/>
										<Tr path={"links.Sale"}/>
									</Link>
								</li>
							</ul>
						</Col>
					</Row>
				</Grid>
			</ResponsiveRender>
		);
	}
}

export default compose(

)(AdditionalMenuRow);
