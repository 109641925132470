import PropTypes from "prop-types";
import update from "immutability-helper";
import React from "react";
import {connect} from "react-redux";
import {API_VERSION_SUCCESS, apiVersion} from "../../actions/esshop2/apiVersion";
import {compose} from "redux";
// eslint-disable-next-line no-unused-vars
import {version} from "../../../../../package.json";

export const VersionCheckerReducer = (
	state = {
		packageVersion: version,
		availableVersion: null,
		reloadNeeded: false,
		reloadDetected: null,
	}, action) => {
	let newState;
	let version;
	let reloadNeeded;
	let updateDict = {};

	switch (action.type) {

		case API_VERSION_SUCCESS:

			version = action.payload.data.VERSION;
			reloadNeeded = (version !== state.packageVersion);

			updateDict = {
				availableVersion: {
					$set: version,
				},
				reloadNeeded: {
					$set: reloadNeeded,
				},
			};

			if (state.reloadNeeded !== reloadNeeded && reloadNeeded == true) {
				updateDict.reloadDetected = {$set: Math.floor(Date.now() / 1000)}; // Timestamp in seconds
			}

			newState = update(state, updateDict);
			return newState;

		default:
			return state;
	}
};

class VersionChecker extends React.Component {
	static defaultProps = {
		interval: 5000,
		timeToShowDialog: 10
	};

	static propTypes = {
		children: PropTypes.any,
		interval: PropTypes.number,
		timeToShowDialog: PropTypes.number,
		version: PropTypes.any
	};

	constructor(props, context) {
		super(props, context);
		this.timer = setInterval(this.lookForVersion.bind(this), this.props.interval);
	}

	lookForVersion() {
		if (this.props.version.reloadNeeded === false) {
			this.props.dispatch(apiVersion());
		}
	}

	render() {
		if (this.props.version.reloadNeeded === false) return null;
		if (this.props.version.reloadDetected + this.props.timeToShowDialog > Math.floor(Date.now() / 1000)) return null;

		return this.props.children;
	}
}

export default compose(
	connect(
		(state) => (
			{
				version: state.version,
			}
		)
	)
)(VersionChecker);
