import React from "react";
import {Route} from "react-router-dom";

const module = (shopApp) => {
	const CheckoutDone = shopApp.dynamic(() => import("./CheckoutDone"));

	const url = "/checkout_done/:ORDER_NUMBER";

	let config = {
		name: "CheckoutDone",
		routes: [
			<Route key={url} exact path={url} component={CheckoutDone}/>
		]
	};

	shopApp.registerPage(config);

};

export default module;
