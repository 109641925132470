import {APP_LANGUAGE_MODAL_SUCCESS, APP_SHOW_SETTINGS_MODAL_SUCCESS} from "../../actions/App/AppCommonDialog";
import {API_AUTH_LOGOUT_SUCCESS} from "@esnpm/eswebapp";
import {TRANSLATION_CHANGE_LANGUAGE_SUCCESS} from "@esnpm/translate";
import {API_SESSION_SET_CUSTOMER_DATA_POST_SUCCESS} from "../../actions/esshop2/apiSessionSetCustomerData_post";

const initialState = {
	show_language_modal: false,
	show_setting_modal: false,

};


export const AppCommonDialogReducer = (state = initialState, action) => {
	switch (action.type) {




		case APP_LANGUAGE_MODAL_SUCCESS:
			return Object.assign({}, state, {
				show_language_modal: action.value,
			});
		case APP_SHOW_SETTINGS_MODAL_SUCCESS:
			return Object.assign({}, state, {
				show_setting_modal: action.value,
			});
		case API_AUTH_LOGOUT_SUCCESS:
		case TRANSLATION_CHANGE_LANGUAGE_SUCCESS:
		case API_SESSION_SET_CUSTOMER_DATA_POST_SUCCESS:
			return Object.assign({}, state, {
				show_language_modal: false,
				show_setting_modal: false,
			});
		default:
			return state;
	}
};
