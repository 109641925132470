import {API_SHIPPING_METHOD_SHIPPING_COSTS_BY_COUNTRY_CODE_POST_SUCCESS} from "../actions/esshop2/apiShippingMethodShippingCostsByCountryCode_post";
import {API_SHIPPING_METHOD_FREE_SHIPPING_THRESHOLD_SUCCESS, API_SHIPPING_METHOD_COUNTRIES_SUCCESS} from "@esnpm/esdb";
import _ from "lodash";

const initialState = {
	costs: {
		loading: false,
		data: null,
		error: null
	},
	countries: {
		loading: false,
		data: null,
		error: null
	},

	loading: false,
	data: {},
	error: null

};
export const ShippingMethodReducer = (state = initialState, action) => {
	switch (action.type) {
		case API_SHIPPING_METHOD_SHIPPING_COSTS_BY_COUNTRY_CODE_POST_SUCCESS:
			return Object.assign({}, state, {
				costs: action.payload
			});

		case API_SHIPPING_METHOD_COUNTRIES_SUCCESS:
			return Object.assign({}, state, {
				countries: action.payload
			});

		case API_SHIPPING_METHOD_FREE_SHIPPING_THRESHOLD_SUCCESS:
			return _.merge({}, state, action.payload);

		default:
			return state;
	}
};
