import {API_MANUFACTURERS_SHOP_SUCCESS} from "../actions/esshop2/apiManufacturersShop";
import _ from "lodash";

const initialState = {
	_apiManufacturersShop: false
};
export const ShopManufacturersReducer = (state = initialState, action) => {
		switch (action.type) {
			case API_MANUFACTURERS_SHOP_SUCCESS:

				if (action.type === API_MANUFACTURERS_SHOP_SUCCESS) {
					state = _.merge({}, state, {
						_apiManufacturersShop: true
					});
				}
				return _.merge({}, state, action.payload.data.reduce((obj, item) => {
					obj[item.MANUFACTURER_ID] = {
						loading: action.payload.loading,
						error: action.payload.error,
						data: item
					};
					return obj;
				}, {}),);

			default:
				return state;
		}
	}
;

// let apiManufacturersShop = false;
// 			if (action.type === "API_MANUFACTURERS_SHOP_SUCCESS") {
// 				console.log(action);
// 			}
