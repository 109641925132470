import React from "react";
import {Tr} from "@esnpm/translate";
import Row from "react-bootstrap/es/Row";
import Col from "react-bootstrap/es/Col";
import Image from "react-bootstrap/es/Image";
import {compose} from "redux";

/**
 * Widget Payment
 * Contains Logos of available Payment options */
class WidgetPayment extends React.Component {
	render() {
		return (
			<React.Fragment>
				<h3><Tr path={"footer.Widgets.PaymentOptions.Headline"}/></h3>
				<p><Tr path={"footer.Widgets.PaymentOptions.Content"}/></p>
				<Row>
					<Col xs={4}>
						<div className={"payment-option-wrap"}>
							<Image src={"/images/icons/paymentmethods/logo-paypal.png"} responsive/>
						</div>
					</Col>
					<Col xs={4}>
						<div className={"payment-option-wrap"}>
							<Image src={"/images/icons/paymentmethods/logo-visa-mastercard.png"} responsive/>
						</div>
					</Col>
					<Col xs={4}>
						<div className={"payment-option-wrap"}>
							<Image src={"/images/icons/paymentmethods/logo-wiretransfer-de.png"} responsive/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xsPush={10} xs={2} style={{paddingTop: 60}}>
						<div style={{padding: 5}}>
							<Image src={"/images/icons/vedes_spielzeug-ring.png"} responsive/>
						</div>
					</Col>
				</Row>
			</React.Fragment>
		);
	}
}

export default compose(

)(WidgetPayment);
