import PropTypes from "prop-types";
import React from "react";
import {compose} from "redux";
import {withShopAppContext} from "./ShopAppContext";

class ShopAppFooterArea extends React.Component {

	static propTypes = {
		children: PropTypes.any,
		shopApp: PropTypes.object.isRequired
	};

	componentDidMount() {
		React.Children.map(this.props.children, (child) => {
			this.props.shopApp.addFooterItem(child);
		});
	}

	render() {
		return null;
	}
}


export default compose(
	withShopAppContext
)(ShopAppFooterArea);
