import axios from "axios";
import Promise from "bluebird";

export const APP_INFO_SUCCESS = "VERSION_SUCCESS";
export const APP_INFO_REQUEST = "VERSION_REQUEST";
export const APP_INFO_FAILURE = "VERSION_FAILURE";


export const app_info_request = () => {
	return {
		type: APP_INFO_REQUEST
	};
};

export const app_info_success = (data) => {

	return {
		type: APP_INFO_SUCCESS,
		data: data
	};
};

export const app_info_failure = (error) => {

	return {
		type: APP_INFO_FAILURE,
		error: error
	};
};


// eslint-disable-next-line no-unused-vars
export const app_info = (options) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		(resolve, reject) => {
			dispatch(app_info_request());
			axios.get("/api/app_info").then(
				(response) => {
					let json = response.data;
					if (json.SUCCESSFUL) {
						dispatch(app_info_success(json.DATA));
						resolve();
					} else {
						// console.log(json);
						dispatch(app_info_failure(null));
						resolve();
					}
				},
				(err) => {
					// eslint-disable-next-line no-console
					console.error(err);
					reject();
				}
			);
		}
	);

export const appInfoReducer = (state = {
	VERSION: null,
	MONGODB_DB: null,
	DB_DATABASE: null
}, action) => {

	switch (action.type) {
		case APP_INFO_SUCCESS:
			return Object.assign({}, state, action.data);

		default:
			return state;
	}
};
