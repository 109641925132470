import PropTypes from "prop-types";
import {connect} from "react-redux";
import React from "react";
import TranslationSelectorDialog from "./TranslationSelectorDialog";
import {compose} from "redux";


class TranslationSelector extends React.Component {
	static propTypes = {
		app: PropTypes.any
	};

	render() {
		if (!this.props.app.commonDialog.show_language_modal) {
			return null;
		} else return (<TranslationSelectorDialog/>);
	}
}

export default compose(
	connect(
		(state) => (
			{
				app: {
					commonDialog: {
						show_language_modal: state.app.commonDialog.show_language_modal
					}
				},
			}
		)
	)
)(TranslationSelector);
