import Promise from "bluebird";

export const APP_COOKIE_SET_SUCCESS = "APP_COOKIE_SET_SUCCESS";

export const AppCookieSet_success = () => {
	return {
		type: APP_COOKIE_SET_SUCCESS,
	};
};

export const AppCookieSet = () =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(AppCookieSet_success());
			resolve();
		},
	);
