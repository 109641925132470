export const trmLinks = {
	pathesToCheck: ["links"],
	tr: {
		links: {
			ShippingCosts: {
				DE: "Versandkosten",
				EN: "Shipping costs",
				FR: "Frais de livraison",
			},
			Contact: {
				DE: "Kontakt",
				EN: "Contact",
				FR: "Contact",
			},
			Settings: {
				DE: "Einstellungen",
				EN: "Settings",
				FR: "Paramètres",
			},
			Login: {
				DE: "Anmelden",
				EN: "Login",
				FR: "S\"inscrire",
			},
			Profile: {
				DE: "Mein Profil",
				EN: "My Profile",
				FR: "Mon profil",
			},
			Categories: {
				FR: "Catégories",
				DE: "Hauptkategorien",
				EN: "Main Categories",
			},
			GTC: {
				Short: {
					DE: "AGB",
					EN: "GTC",
					FR: "CGV",
				},
				Long: {
					DE: "Allgemeine Geschäftsbedingungen",
					EN: "General Terms and Conditions",
					FR: "Conditions générales de vente",
				},
			},
			LegalNotice: {
				DE: "Impressum",
				EN: "Legal Notice",
				FR: "Mentions légales",
			},
			PrivacyPolicy: {
				DE: "Datenschutzerklärung",
				EN: "Privacy Policy",
				FR: "Politique de confidentialité"
			},
			CookiePolicy: {
				DE: "Hinweise zu Cookies",
				EN: "Cookie Policy",
				FR: "Politique sur les cookies"
			},
			Revocation: {
				DE: "Wiederrufsrecht",
				EN: "Right of Revocation",
				FR: "Droit de révocation"
			},
			NewProducts: {
				FR: "Nouveaux produits",
				DE: "Neue Produkte",
				EN: "New Products",
			},
			ManufacturerAZ: {
				FR: "Fabricants d'A-Z",
				DE: "Hersteller A-Z",
				EN: "Manufacturer A-Z",
			},
			Sale: {
				DE: "Angebote",
				EN: "Sale",
				FR: "Promotions"
			},
			FAQ: {
				DE: "Fragen & Antworten",
				EN: "FAQ",
				FR: "Questions-Réponses"
			}
		}
	}
};
