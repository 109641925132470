export const trmSideBar = {
	pathesToCheck: ['sidebar'],
	tr: {
		sidebar: {
			FreeShipping: {
				Text: {
					FR: "Nous éxpédions gratuitement votre commande à partir d'un montant minimum de {FREE_SHIPPING_VALUE} vers différents pays. Plus d'informations ici:",
					DE: 'Ab einem Warenwert von {FREE_SHIPPING_VALUE} liefern wir kostenlos in verschiedene Länder. Weitere Informationen erhalten Sie hier:',
					EN: 'We offer you a Free Shipping option to various countries, if your order value is over {FREE_SHIPPING_VALUE}. For more information please check:',
				},
				Headline: {
					FR: 'Livraison gratuite',
					DE: 'Kostenloser Versand',
					EN: 'Free Shipping',
				},
				Link: {
					Text: {
						FR: 'En savoir plus sur les conditions de la livraison gratuite',
						DE: 'Mehr über die Bedingungen zum kostenlosen Versand',
						EN: 'Free Shipping Information and Conditions',
					},
				},
			},
			Pickupnote: {
				Headline: {
					FR: 'Enlèvement',
					DE: 'Abholung',
					EN: 'Pickup',
				},
				Text: {
					FR: 'Enlèvement des marchandises possible seulement sur commande préalable et après confirmation de la disponibilité de la marchandise .',
					DE: 'Warenabholung nur nach vorheriger Bestellung und erfolgter Bestätigung, dass die Ware abholbereit ist.',
					EN: 'You can pickup your goods, after you placed an order and and received a ready-for-pickup notification.',
				},
			},
			Social: {
				Headline: {
					FR: 'Nous vous tenons au courant',
					DE: 'Wir halten Sie auf dem Laufenden',
					EN: 'We will keep you posted',
				},
			},
			Sale: {
				SelectManufacturer: {
					DE: 'Nach Hersteller filtern',
					EN: 'Filter by manufacturer',
					FR: 'Filtrer par fabricant',
				},
			},
			LegoEducation: {
				Headline: {
					ALL: 'LEGO® Education',
				},
				SubHeadline: {
					ALL: 'STEM / MINT',
				},
				Text: {
					DE: 'Sie möchten mehr über die Unterrichtslösungen von LEGO Education erfahren oder haben Fragen? <br/><br/>Als offizieller Handelspartner stehen wir Ihnen mit Rat und Tat zur Seite.',
					EN: 'Want to learn more about LEGO Education classroom solutions or have any questions? <br/><br/>As an official trading partner, we are here to help you.',
				},
				Link: {
					Text: {
						DE: 'Mehr erfahren',
						EN: 'Learn more',
					},
				},
			},
		},
	},
};
