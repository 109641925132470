import React from "react";
import PropTypes from "prop-types";
import {action_once} from "@esnpm/node-toolbox";
import {apiAuthCurrentSession} from "@esnpm/eswebapp";
import _ from "lodash";
import {compose} from "redux";
import {connect} from "react-redux";
import {wrapComponent} from "@esnpm/node-toolbox";

class AuthCurrentSessionContainerComponent extends React.Component {

	static propTypes = {
		children: PropTypes.any,
		currentSession: PropTypes.any
	};

	componentDidMount() {
		this.props.dispatch(action_once((state) => (state.currentSession.data == null), apiAuthCurrentSession()));
	}

	render() {
		const session = _.get(this.props, "currentSession.data", null);
		if (session == null) return null;
		return React.Children.map(this.props.children, (child) => React.cloneElement(child, {
			currentSession: this.props.currentSession
		}));
	}
}

const AuthCurrentSessionContainer = compose(
	connect(
		(state) => ({
			currentSession: state.currentSession
		})
	)
)(AuthCurrentSessionContainerComponent);

export const withAuthCurrentSession = wrapComponent(AuthCurrentSessionContainer);

