import {compose} from "redux";
import React from "react";
import WidgetWithIcon from "./WidgetWithIcon";
import EmailOutlineIcon from "mdi-react/EmailOutlineIcon";
import {Tr} from "@esnpm/translate";
import FormNewsletterFooter from "../../../../../esshop2_frontend/src/js/components/FormNewsletterFooter";

/**
 * Widget Newsletter */
class WidgetNewsletter extends React.Component {
	render() {
		return (
			<WidgetWithIcon
				icon={<EmailOutlineIcon/>}
				headline={<Tr path={"footer.Widgets.Newsletter.Headline"}/>}
				content={<Tr path={"footer.Widgets.Newsletter.Content"}/>}
				callToAction={<FormNewsletterFooter/>}
			/>
		);
	}
}

export default compose()(
	WidgetNewsletter
);
