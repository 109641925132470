import Row from "react-bootstrap/es/Row";
import React from "react";
import Col from "react-bootstrap/es/Col";
import {compose} from "redux";

class FooterBranding extends React.Component {
	render() {
		return (
			<Row className={"FooterBranding"}>
				<Col xs={12}>
					<div className={"brand-holder"}>&nbsp;</div>
				</Col>
			</Row>
		);
	}
}

export default compose(

)(FooterBranding);
