import React, { Fragment, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TagNavHorizontalPortalButton } from '../TagNavigation/TagNavHorizontalPortalButton';
import {
	TagNavHorizontalPortal,
	TagNavHorizontalPortalPaper,
} from '../TagNavigation/TagNavHorizontalPortal';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { getTranslation } from '../../functions/translate';
import { withTrContext } from '@esnpm/translate';
import { compose } from 'redux';
import { Grid } from '@material-ui/core';

const TagMenuHorizontal = ({ data, trProvider }) => {
	const { activeLanguage } = trProvider;
	const { title, items } = data;
	const { buttonRef, close, isOpen, onButtonClick } = useContext(
		TagNavHorizontalPortalButton.Context
	);

	const rows = useMemo(() => {
		const items_count = items.length;
		const items_per_column = Math.ceil(items_count / 4);
		return _.chunk(items, items_per_column <= 3 ? 3 : items_per_column);
	}, [items]);

	// Return link with button, if only one child item is available
	if (items.length === 1)
		return (
			<Link to={`/group?grouptags=${items[0].grouptags}`}>
				<Button>{getTranslation(title, activeLanguage)}</Button>
			</Link>
		);

	return (
		<Fragment>
			<div
				ref={buttonRef}
				onClick={onButtonClick}
				className={'TagMenuHorizontal'}
			>
				{getTranslation(title, activeLanguage)}
			</div>
			{isOpen && (
				<TagNavHorizontalPortal>
					<TagNavHorizontalPortalPaper>
						<Grid
							container
							className={'TagNavHorizontalPortalPaperRow'}
							spacing={2}
						>
							{rows.map((row_items, index) => {
								return (
									<Grid
										item
										key={index}
										xs={12}
										sm={3}
										md={3}
										className={'col'}
									>
										<ul>
											{row_items
												.filter(
													(item) => _.get(item, 'grouptags', []).length !== 0
												)
												.map((item, idx) => {
													const tags = item.grouptags.join(',');
													const link = `/group?grouptags=${tags}`;
													return (
														<li key={item.grouptags.join('')}>
															<Link to={link} key={idx} onClick={() => close()}>
																{getTranslation(item.title, activeLanguage)}
															</Link>
														</li>
													);
												})}
										</ul>
									</Grid>
								);
							})}
						</Grid>
					</TagNavHorizontalPortalPaper>
				</TagNavHorizontalPortal>
			)}
		</Fragment>
	);
};
TagMenuHorizontal.propTypes = {
	data: PropTypes.object,
	variant: PropTypes.string,
	trProvider: PropTypes.any,
};

TagMenuHorizontal.defaultProps = {
	data: {
		title: {},
		items: [],
	},
	variant: 'sidebar',
};

export default compose(withTrContext)(TagMenuHorizontal);
