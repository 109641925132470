import {version} from "../../../../../package";
import {API_VERSION_SUCCESS} from "../../actions/esshop2/apiVersion";
import update from "immutability-helper";

const initialState = {
	packageVersion: version,
	availableVersion: null,
	reloadNeeded: false,
};

export const AppVersionReducer = (state = initialState, action) => {
	let version;
	let updateNeeded;
	switch (action.type) {
		case API_VERSION_SUCCESS:
			version = action.payload.data.VERSION;
			updateNeeded = (version !== state.version.packageVersion);

			return update(state, {
				availableVersion: {
					$set: version,
				},
				updateNeeded: {
					$set: updateNeeded,
				},
			});

		default:
			return state;
	}
};
