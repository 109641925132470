import {API_SESSION_CART_ADD_ADDRESS_SUCCESS} from "../../actions/esshop2/apiSessionCartAddAddress";
import {APP_ADDRESS_LIST_SET_EDITED_SUCCESS, APP_ADDRESS_LIST_SET_ADDED_SUCCESS, APP_ADDRESS_LIST_SET_DELETE_MARKET_SUCCESS} from "../../actions/App/AppAddress";
import {API_SESSION_CART_PICKUP_ADDRESS_SUCCESS} from "../../actions/esshop2/apiSessionCartPickupAddress";
import update from "immutability-helper";

const initialState = {
	edited: null,
	added: null,
	emptyAddress: null,
	pickupAddress: null,
	delete: null,
};

export const AppAddressReducer = (state = initialState, action) => {

	switch (action.type) {
		case API_SESSION_CART_ADD_ADDRESS_SUCCESS:
			return update(state, {
				emptyAddress: {
					$set: action.payload.data,
				},
			});
		case API_SESSION_CART_PICKUP_ADDRESS_SUCCESS:
			return update(state, {
				pickupAddress: {
					$set: action.payload.data,
				},
			});
		case APP_ADDRESS_LIST_SET_EDITED_SUCCESS:
			return update(state, {
				edited: {
					$set: action.payload,
				},
			});

		case APP_ADDRESS_LIST_SET_ADDED_SUCCESS:
			return update(state, {
				added: {
					$set: action.payload,
				},
			});

		case APP_ADDRESS_LIST_SET_DELETE_MARKET_SUCCESS:
			return update(state, {
				delete: {
					$set: action.payload,
				},
			});
		default:
			return state;
	}
};
