import axios from "axios/index";
import {getAPIUrl} from "@esnpm/node-toolbox";
import Promise from "bluebird";
export const API_SESSION_SHIPPING_METHOD_UPDATE_POST_REQUEST = "API_SESSION_SHIPPING_METHOD_UPDATE_POST_REQUEST";
export const API_SESSION_SHIPPING_METHOD_UPDATE_POST_SUCCESS = "API_SESSION_SHIPPING_METHOD_UPDATE_POST_SUCCESS";
export const API_SESSION_SHIPPING_METHOD_UPDATE_POST_FAILURE = "API_SESSION_SHIPPING_METHOD_UPDATE_POST_FAILURE";

export const api_session_shipping_method_update_post_request = (options) => {
	return {
		type: API_SESSION_SHIPPING_METHOD_UPDATE_POST_REQUEST,
		options: options,
		payload: {
			loading: true
		}
	};
};

export const api_session_shipping_method_update_post_success = (data) => {
	return {
		type: API_SESSION_SHIPPING_METHOD_UPDATE_POST_SUCCESS,
		payload: {
			loading: false,
			data: data,
			error: null
		}
	};
};

export const api_session_shipping_method_update_post_failure = (error) => {
	return {
		type: API_SESSION_SHIPPING_METHOD_UPDATE_POST_FAILURE,
		payload: {
			loading: false,
			error: error
		}
	};
};

/*
	Session.apiSessionShippingMethodUpdate_post
*/
export const apiSessionShippingMethodUpdate_post = (options) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(api_session_shipping_method_update_post_request(options));
			const url=getAPIUrl([
				{"url": "/api/session/shipping_method/update", "parameters": []},
			], options);
			axios({
				method: "post",
				url: url,
				data: {
					SHIPPING_METHOD: options.SHIPPING_METHOD,
				},
			}).then(
				(response) => {
					if (response.data.SUCCESSFUL) {
						dispatch(api_session_shipping_method_update_post_success(response.data.DATA));
						resolve();
					} else {
						dispatch(api_session_shipping_method_update_post_failure(response.data));
						resolve();
					}
				},
				(err) => {
					// eslint-disable-next-line no-console
					console.log(err);
					reject();
				}
			);
		}
	);
