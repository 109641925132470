export const trmNotifications = {
	pathesToCheck: ["notifications"],
	tr: {
		notifications: {
			Email: {
				Error: {
					NotRegistered: {
						DE: "Diese E-Mail-Adresse ist uns nicht bekannt.",
						EN: "This e-mail address is not registered in our system.",
						FR: "Cette adresse e-mail n'est pas enregistrée dans notre système.",
					}
				}
			},
			ContactRequest: {
				Success: {
					DE: "Ihre Anfrage wurde erfolgreich verschickt.",
					EN: "Your request has been successfully sent.",
					FR: "Votre demande a été envoyée avec succès.",
				},
				Error: {
					DE: "Leider ist ein Fehler aufgetreten.",
					EN: "Unfortunately an error occurred.",
					FR: "Malheureusement, une erreur s'est produite.",
				}
			},
			Forms: {
				Change: {
					Success: {
						DE: "Ihre Änderungen wurden erfolgreich übernommen.",
						EN: "Your changes have been successfully applied.",
						FR: "Vos changements ont été appliqués avec succès."
					},
					Error: {
						DE: "Beim Speichern der Änderungen ist ein Fehler aufgetreten.",
						EN: "An error occurred when saving the changes.",
						FR: "Une erreur s'est produite lors de la sauvegarde des modifications."
					}
				}

			},
			Types: {
				Warning: {
					DE: "Achtung",
					EN: "Warning",
					FR: "Alerte"
				},
				Success: {
					DE: "Erfolg!",
					EN: "Success!",
					FR: "Succès!",
				},
				Error: {
					DE: "Fehler!",
					EN: "Error!",
					FR: "Erreur!",
				}
			}
		}
	}
};
