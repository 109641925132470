import PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {withShopAppContext} from "./ShopAppContext";
import LoginForm from "./LoginForm";

class LoginDialog extends React.Component {
	static propTypes = {
		app: PropTypes.any,
		shopApp: PropTypes.any,
		currentUser: PropTypes.object

	};

	render() {
		const {app} = this.props;
		if (!app.registration.show_login_form) return null;
		return (
			<React.Fragment>
				<LoginForm horizontal={false}/>
			</React.Fragment>
		);
	}
}

export default compose(
	withShopAppContext,
	connect(
		(state) => (
			{
				app: {
					registration: state.app.registration,
				},
				currentSession: state.currentSession,
				currentUser: state.currentUser,
				tr: state.tr
			}
		)
	)
)(LoginDialog);

