import {combineReducers} from "redux-seamless-immutable";
import {AppCheckoutReducer} from "./AppCheckoutReducer";
import {AppSidebarReducer} from "./AppSidebarReducer";
import {AppCookiesReducer} from "./AppCookiesReducer";
import {AppCommonDialogReducer} from "./AppCommonDialogReducer";
import {AppRegistrationReducer} from "./AppRegistrationReducer";
import {AppContactReducer} from "./AppContactReducer";
import {AppAddressReducer} from "./AppAddressReducer";
import {AppNewsletterOptOutReducer} from "./AppNewsletterOptOutReducer";
import {AppNewsletterOptInReducer} from "./AppNewsletterOptInReducer";
import {AppPaymentMethodOverviewReducer} from "./AppPaymentMethodOverviewReducer";
import {AppUserAdditionalCommentReducer} from "./AppUserAdditionalCommentReducer";
import {AppCustomerRegistrationReducer} from "./AppCustomerRegistrationReducer";
import {AppSubscribeToNewsletterReducer} from "./AppSubscribeToNewsletterReducer";
import {AppNewsletterRegisterReducer} from "./AppNewsletterRegisterReducer";
import {AppRightOfRevocationReducer} from "./AppRightOfRevocationReducer";
import {AppAccountReducer} from "./AppAccountReducer";
import {AppCartProductsReducer} from "./AppCartProductsReducer";
import {AppVersionReducer} from "./AppVersionReducer";
import {AppCustomerTypeSelectionReducer} from "./AppCustomerTypeSelectionReducer";
import {AppPageReducer} from "./AppPageReducer";

export const AppReducer = combineReducers(
	{
		account: AppAccountReducer,
		addressContainer: AppAddressReducer,
		checkout: AppCheckoutReducer,
		commonDialog: AppCommonDialogReducer,
		contact: AppContactReducer,
		cookies: AppCookiesReducer,
		customerTypeSelection: AppCustomerTypeSelectionReducer,
		customerRegistration: AppCustomerRegistrationReducer,
		hasNoSale: AppCartProductsReducer,
		newsletter_optout: AppNewsletterOptOutReducer,
		newsletter_optin: AppNewsletterOptInReducer,
		newsletter_register: AppNewsletterRegisterReducer,
		registration: AppRegistrationReducer,
		rightOfRevocation: AppRightOfRevocationReducer,
		paymentMethodsOverview: AppPaymentMethodOverviewReducer,
		sidebarCategory: AppSidebarReducer,
		subscribeToNewsletter: AppSubscribeToNewsletterReducer,
		userAdditionalCommentContainer: AppUserAdditionalCommentReducer,
		version: AppVersionReducer,
		page: AppPageReducer
	}
);

