import PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import SettingsDialogModal from "./SettingsDialogModal";

class SettingsDialog extends React.Component {
	static propTypes = {
		app: PropTypes.any
	};

	render() {
		if (!this.props.app.commonDialog.show_setting_modal) {
			return null;
		} else return (<SettingsDialogModal/>);
	}
}

export default compose(
	connect(
		(state) => (
			{
				app: {
					commonDialog: {
						show_setting_modal: state.app.commonDialog.show_setting_modal
					}
				}
			}
		)
	)
)(SettingsDialog);
