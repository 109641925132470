import React from 'react';
import ReactDOM from 'react-dom';
import AppWrapper from './components/AppWrapper';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import SentryErrorBoundary from '../../../esshop2_frontend/src/js/components/SentryErrorBoundary';
import { version } from '../../../package.json';
import { gtmInit } from '../../../esshop2_frontend/src/js/lib/gtm/gtm';

gtmInit();

window._es_sentryEnabled = process.env.NODE_ENV === 'production';
// window._es_sentryEnabled = true;

if (window._es_sentryEnabled) {
	Sentry.init({
		dsn: 'https://bd28d5c314de4addab8be09d1fea9ee2@o235504.ingest.sentry.io/1401039',
		integrations: [new BrowserTracing()],
		// We recommend adjusting this value in production, or using tracesSampler for finer control
		tracesSampleRate: 0.1,
		release: window._es_env.app.entryPoint + '@' + version,
		environment: window.location.hostname,
		enabled: window._es_sentryEnabled,
	});
}

ReactDOM.render(
	<SentryErrorBoundary enabled={window._es_sentryEnabled}>
		<AppWrapper />
	</SentryErrorBoundary>,
	document.getElementById('app')
);
