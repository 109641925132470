import Promise from "bluebird";

export const APP_ADDRESS_LIST_SET_EDITED_SUCCESS = "APP_ADDRESS_LIST_SET_EDITED_SUCCESS";
export const AppAddressListSetEdited_success = (options) => {
	return {
		type: APP_ADDRESS_LIST_SET_EDITED_SUCCESS,
		payload: {
			id: options.id,
		},
	};
};

export const AppAddressListSetEdited = (options) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(AppAddressListSetEdited_success(options));
			resolve();
		},
	);

export const APP_ADDRESS_LIST_SET_ADDED_SUCCESS = "APP_ADDRESS_LIST_SET_ADDED_SUCCESS";

export const AppAddressListSetAdded_success = (options) => {
	return {
		type: APP_ADDRESS_LIST_SET_ADDED_SUCCESS,
		payload: {
			id: options.id,
		},
	};
};

export const AppAddressListSetAdded = (options) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(AppAddressListSetAdded_success(options));
			resolve();
		},
	);

export const APP_ADDRESS_LIST_SET_DELETE_MARKET_SUCCESS = "APP_ADDRESS_LIST_SET_DELETE_MARKET_SUCCESS";
export const AppAddressListSetDeleteMarket_success = (options) => {
	return {
		type: APP_ADDRESS_LIST_SET_DELETE_MARKET_SUCCESS,
		payload: options.address,
	};
};

export const AppAddressListSetDeleteMarke = (options) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(AppAddressListSetDeleteMarket_success(options));
			resolve();
		},
	);
