import Promise from "bluebird";

export const COVID_COOKIE_SET_SUCCESS = "COVID_COOKIE_SET_SUCCESS";

export const AppCovidCookieSet_success = () => {
	return {
		type: COVID_COOKIE_SET_SUCCESS,
	};
};

export const AppCookieSet = () =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(AppCovidCookieSet_success());
			resolve();
		},
	);
