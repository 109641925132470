import {API_AUTH_CURRENT_SESSION_SUCCESS} from "@esnpm/eswebapp";
import {API_SESSION_SET_ACTIVE_LANGUAGE_POST_SUCCESS} from "../actions/esshop2/apiSessionSetActiveLanguage_post";

const initialState = {
	loading: false,
	data: null,
	error: null
};

export const authTranslationCurrentSessionReducer = (state = initialState, action) => {

	switch (action.type) {
		case API_AUTH_CURRENT_SESSION_SUCCESS:
		case API_SESSION_SET_ACTIVE_LANGUAGE_POST_SUCCESS:
			return Object.assign({}, state, {
				activeLanguage: (action.payload.data.ACTIVE_LANGUAGE != null) ? action.payload.data.ACTIVE_LANGUAGE : state.activeLanguage
			});

		default:
			return state;
	}
};
