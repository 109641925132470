import React, { memo, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const Portal = ({ id, children }) => {
	const el = useRef(
		document.getElementById(id) || document.createElement('div')
	);
	const [dynamic] = useState(!el.current.parentElement);
	useEffect(() => {
		if (dynamic) {
			el.current.id = id;
			document.body.appendChild(el.current);
		}
		return () => {
			if (dynamic && el.current.parentElement) {
				// eslint-disable-next-line
				el.current.parentElement.removeChild(el.current);
			}
		};
		// eslint-disable-next-line
	}, [id]);
	return createPortal(children, el.current);
};
Portal.propTypes = {
	id: PropTypes.string.isRequired,
};
const MemoPortal = memo(Portal);

const MuiPortal = ({ id, children }) => (
	// NOSSR
	// <NoSsr>
	<MemoPortal id={id}>{children}</MemoPortal>
	// </NoSsr>
);

MuiPortal.propTypes = {
	id: PropTypes.string.isRequired,
};

export { MuiPortal };
