export const trmLogin = {
	pathesToCheck: ["loginDialog"],
	tr: {
		loginDialog: {
			Login: {
				DE: "Anmelden",
				EN: "Login",
				FR: "S'inscrire"
			},
			EMail: {
				DE: "E-Mail-Adresse",
				EN: "Email Address",
				FR: "Adresse e-mail",
			},
			EMailPlaceholder: {
				DE: "Bitte geben Sie Ihre E-Mail-Adresse ein",
				EN: "Please enter your email address",
				FR: "Veuillez entrer votre adresse e-mail"
			},
			Password: {
				DE: "Passwort",
				EN: "Password",
				FR: "Mot de passe"
			},
			PasswordPlaceholder: {
				DE: "Bitte geben Sie Ihr Passwort ein",
				EN: "Please enter your password",
				FR: "Veuillez entrer votre mot de passe"
			},
			Register: {
				DE: "Sie haben noch kein Konto? Registrieren Sie sich hier.",
				EN: "You don't have an account? Register here.",
				FR: "Vous n'avez pas encore de compte? Inscrivez-vous ici."
			},
			ForgotPassword: {
				DE: "Passwort vergessen?",
				EN: "I forgot my password?",
				FR: "Mot de passe oubliè"
			},
			BackToLogin: {
				DE: "Zurück zur Anmeldung",
				EN: "Back to login form",
				FR: "Retour à l'inscription"
			},
			BackToShop: {
				DE: "Zurück zum Shop",
				EN: "Back to the shop",
				FR: "Retour à la boutique"
			},
			ResetPassword: {
				DE: "Passwort zurücksetzen",
				EN: "Reset password",
				FR: "Réinitialiser le mot de passe"

			},
			ResetPasswordSuccess: {
				DE: "Sie erhalten in Kürze eine Bestätigungsemail zum Zurücksetzen Ihres Passwortes.",
				EN: "You will shortly receive a confirmation email to reset your password.",
				FR: "Vous recevrez sous peu un e-mail de confirmation pour réinitialiser votre mot de passe."
			},
			InternalPasswordError: {
				DE: "<p>Sehr geehrte Kundin, sehr geehrter Kunde, </p><p>durch einen internen Fehler in unserer Verwaltung ist es möglich, dass Ihr bisheriges Passwort mit einem zufälligen Passwort überschrieben wurde.</p><p>Sollten Sie sich nicht einloggen können, versuchen Sie bitte Ihr <strong>Passwort zurückzusetzen</strong> (Link oben). Danach sollte der Login wieder funktionieren.</p><p>Wir bitten um Ihr Verständnis!</p><p>Mit freunlichen Grüßen,</p><p>Ihr Team von Electronic-Shop SARL</p>",
				EN: "<p>Dear customer,</p><p>through an internal error in our system it is currently possible, that your password was overwritten with a random password.</p><p>If you've problems with the login, please <strong>request a password reset</strong> (link above). After this reset, the login should work again.</p><p>We ask for your understanding!</p><p>Yours sincerely,</p><p>Your team from Electronic-Shop SARL</p>",
				FR: "<p>Cher client,</p><p>par une erreur interne dans notre système, il est actuellement possible que votre mot de passe ait été écrasé par un mot de passe aléatoire.</p><p>Si vous avez des problèmes avec l'ouverture de session, veuillez demander une <strong>réinitialisation du mot de passe</strong>. Après cette réinitialisation, le login devrait fonctionner à nouveau.</p><p>Nous vous demandons votre compréhension !</p><p>Sincèrement vôtre,</p><p>Votre équipe de Electronic-Shop SARL</p>"
			}
		}
	}
};
