import axios from "axios/index";
import {getAPIUrl} from "@esnpm/node-toolbox";
import Promise from "bluebird";
export const API_CONTACT_TRANSACTION_REQUEST = "API_CONTACT_TRANSACTION_REQUEST";
export const API_CONTACT_TRANSACTION_SUCCESS = "API_CONTACT_TRANSACTION_SUCCESS";
export const API_CONTACT_TRANSACTION_FAILURE = "API_CONTACT_TRANSACTION_FAILURE";

export const api_contact_transaction_request = (options) => {
	return {
		type: API_CONTACT_TRANSACTION_REQUEST,
		options: options,
		payload: {
			loading: true
		}
	};
};

export const api_contact_transaction_success = (data) => {
	return {
		type: API_CONTACT_TRANSACTION_SUCCESS,
		payload: {
			loading: false,
			data: data,
			error: null
		}
	};
};

export const api_contact_transaction_failure = (error) => {
	return {
		type: API_CONTACT_TRANSACTION_FAILURE,
		payload: {
			loading: false,
			error: error
		}
	};
};

/*
	Contact.apiContactTransaction
*/
export const apiContactTransaction = (options) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(api_contact_transaction_request(options));
			const url=getAPIUrl([
				{"url": "/api/contact/transaction/${options.FULL_TR_NUMBER}", "parameters": ["FULL_TR_NUMBER"]},
			], options);
			axios({
				method: "get",
				url: url,
			}).then(
				(response) => {
					if (response.data.SUCCESSFUL) {
						dispatch(api_contact_transaction_success(response.data.DATA));
						resolve();
					} else {
						dispatch(api_contact_transaction_failure(response.data));
						resolve();
					}
				},
				(err) => {
					// eslint-disable-next-line no-console
					console.log(err);
					reject();
				}
			);
		}
	);
