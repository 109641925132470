import Promise from "bluebird";

export const APP_RESET_MYACCOUNT_RESULTS = "APP_RESET_MYACCOUNT_RESULTS";

export const AppResetMyAccountResults_success = () => {
	return {
		type: APP_RESET_MYACCOUNT_RESULTS,
	};
};

export const AppResetMyAccountResults = () =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(AppResetMyAccountResults_success());
			resolve();
		},
	);
