import React from "react";
import PropTypes from "prop-types";
import Promise from "bluebird";
import {action_once, wrapComponent} from "@esnpm/node-toolbox";
import {apiCountries, apiShippingMethodCountries, apiShippingMethodFreeShippingThreshold} from "@esnpm/esdb";
import _ from "lodash";
import {compose} from "redux";
import {connect} from "react-redux";
import {shipping_country_codes} from "../constants/shipping_country_codes";


class ShippingMethodCountriesContainerComponent extends React.Component {

	static propTypes = {
		children: PropTypes.any,
		country: PropTypes.object,
		shippingMethod: PropTypes.object,
	};


	componentDidMount() {
		Promise.all([
			this.props.dispatch(action_once((state) => (state.country.data == null), apiCountries())),
			this.props.dispatch(action_once((state) => (state.shippingMethod.countries.data == null), apiShippingMethodCountries())),
			this.props.dispatch(action_once((state) => (state.shippingMethod.data.FREE_SHIPPING == null), apiShippingMethodFreeShippingThreshold()))
		]);
	}

	render() {
		const country = _.get(this.props, "country.data", null);
		const shippingMethodCountries = _.get(this.props, "shippingMethod.countries.data", null);
		const freeShippingThreshold = _.get(this.props, "shippingMethod.data.FREE_SHIPPING", null);


		if (country == null || shippingMethodCountries == null || freeShippingThreshold == null) return null;
		return React.Children.map(this.props.children, (child) => React.cloneElement(child, {
			country: this.props.country,
			shippingMethod: this.props.shippingMethod,
			loadShippingCountries: () => shipping_country_codes.map((cc) => ({
				value: cc,
				textTr: "countries." + cc
			}))
		}));

	}
}

const ShippingMethodCountriesContainer = compose(
	connect(
		(state) => ({
			country: state.country,
			shippingMethod: state.shippingMethod
		})
	)
)(ShippingMethodCountriesContainerComponent);

export const withShippingMethodCountries = wrapComponent(ShippingMethodCountriesContainer);
