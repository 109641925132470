import {API_SESSION_PAYMENT_CHECKOUT_POST_SUCCESS} from "../../actions/esshop2/apiSessionPaymentCheckout_post";
import {API_SESSION_PAYMENT_CHECKOUT_CANCEL_SUCCESS} from "../../actions/esshop2/apiSessionPaymentCheckoutCancel";
import {API_SESSION_PAYMENT_CHECKOUT_SUCCESS_SUCCESS} from "../../actions/esshop2/apiSessionPaymentCheckoutSuccess";
import {APP_CHECKOUT_SET_REDIRECT_TO_PROVIDER_SUCCESS, APP_CHECKOUT_SET_DONE_SUCCESS} from "../../actions/App/AppCheckout";
import {API_SESSION_PAYMENT_CLAIM_AND_FINISH_SUCCESS} from "../../actions/esshop2/apiSessionPaymentClaimAndFinish";
import {APP_CHECKOUT_SET_PAYMENT_AUTHORIZATION_EXPIRED_SUCCESS} from "../../actions/App/AppCheckout";

import update from "immutability-helper";

const initialState = {
	redirectToProvider: null,
	redirect: null,
	done: null,
	trSummary: null,
	paymentAuthorizationExpired: false
};

export const AppCheckoutReducer = (state = initialState, action) => {
	switch (action.type) {
		case API_SESSION_PAYMENT_CHECKOUT_POST_SUCCESS:
		case API_SESSION_PAYMENT_CHECKOUT_CANCEL_SUCCESS:
		case API_SESSION_PAYMENT_CHECKOUT_SUCCESS_SUCCESS:
			return Object.assign({}, state, {
				redirect: action.payload.data.REDIRECT
			});

		case API_SESSION_PAYMENT_CLAIM_AND_FINISH_SUCCESS:
			return update(state, {
				redirect: {
					$set: action.payload.data.REDIRECT,
				},
				trSummary: {
					$set: action.payload.data.TR_SUMMARY,
				},
			});
		case APP_CHECKOUT_SET_DONE_SUCCESS:
			return update(state, {
				done: {
					$set: action.payload.done,
				},
			});
		case APP_CHECKOUT_SET_REDIRECT_TO_PROVIDER_SUCCESS:
			return update(state, {
				redirectToProvider: {
					$set: action.payload.redirectToProvider,
				},
			});

		case APP_CHECKOUT_SET_PAYMENT_AUTHORIZATION_EXPIRED_SUCCESS:
			return Object.assign({}, state, action.payload);

		default:
			return state;
	}
};
