import React from "react";
import PropTypes from "prop-types";
import {Well} from "react-bootstrap";

const classnames = require("classnames");

export class InformationWell extends React.Component {

	static defaultProps = {
		className: ""
	};

	static propTypes = {
		children: PropTypes.any,
		className: PropTypes.string
	};

	render() {
		return (
			<Well className={classnames("InformationWell", this.props.className)}>
				{this.props.children}
			</Well>
		);
	}
}
