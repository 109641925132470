import PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";
import Link from "react-router-dom/es/Link";
import {Alert, Button, Col, Row} from "react-bootstrap";
import {Tr} from "@esnpm/translate";
import {withTypeMapping} from "@esnpm/esdb";
import {ESForm, ESValidator} from "@esnpm/forms";
import {apiContactRegistration_post} from "../actions/esshop2/apiContactRegistration_post";
import {InformationWell} from "./InformationWell";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import _ from "lodash";
import {withAuthCurrentSession} from "../factories/withAuthCurrentSession";
import {withShippingMethodCountries} from "../factories/withShippingMethodCountries";
import BackToLoginForm from "./BackToLoginForm";
import {apiAuthCurrentUser, apiAuthCurrentSession} from "@esnpm/eswebapp";
import Promise from "bluebird";
import {ESLoadable} from "@esnpm/react-toolbox";

class RegistrationForm extends React.Component {
	static propTypes = {
		app: PropTypes.any,
		country: PropTypes.any,
		currentSession: PropTypes.any,
		horizontal: PropTypes.any,
		loadShippingCountries: PropTypes.func,
		shippingMethod: PropTypes.any
	};

	constructor(props) {
		super(props);
		const address = this.getDeliveryAddressFromSession();
		let countryCode = ((address.COUNTRY_CODE != "") ? address.COUNTRY_CODE : "LU");
		this.state = {
			button_disable: true,
			initialized: false,
			customerType: props.currentSession.data.CUSTOMER_TYPE,
			countryCode: countryCode
		};
		this.vatIdValidator = new ESValidator.VatIdPattern(countryCode);
		this.zipcodeValidator = new ESValidator.ZipCodePattern(countryCode);
		this.pwConfirmValidator = new ESValidator.TwoValueConfirm();
	}

	componentWillMount() {
		const address = this.getDeliveryAddressFromSession();
		this.setState({
			initialized: true,
			customerType: this.props.currentSession.data.CUSTOMER_TYPE,
			countryCode: ((address.COUNTRY_CODE != "") ? address.COUNTRY_CODE : "LU")
		});

	}


	loadCustomerTypeOptions() {
		return ["PRIVATE", "COMPANY"].map((k) => ({
			value: k,
			textTr: "typeMapping.CUSTOMER_TYPE." + k
		}));
	}

	getDeliveryAddressFromSession() {
		return this.props.currentSession.data.ADDRESSES.filter(adr => adr.ADDRESS_TYPES.indexOf("DELIVERY") >= 0)[0];
	}

	returnLinkToGTC() {
		return (
			<Link to={"/gtc"}><Tr path={"links.GTC"}/></Link>
		);
	}

	registrationButton() {
		return (
			<React.Fragment>
				<Button
					bsStyle={"primary"}
					className={"pull-right"}
					onClick={this.handleRegistrationAction.bind(this)}
					ref={c => {
						this.registerButton = c;
					}}
					disabled={this.state.button_disable}
				>
					{this.renderButtonContent()}

				</Button>
			</React.Fragment>


		);
	}

	renderButtonContent() {
		return (
			<Tr path={"buttons.RegisterNow"}/>
		);
	}

	handleCustomerTypeChange(value) {
		this.setState({customerType: value});
		let form = this.registerForm.getWrappedInstance();
		// da neue Felder hinzukommen wird hier von aussen die Validierung neu angestossen
		form.handleFieldValidChange(null, null);
	}

	handleCountryChange(value) {
		if (this.vatidField != null) {
			this.vatidField.setCountryCode(value);
		}
		this.zipcodeField.setCountryCode(value);
		let form = this.registerForm.getWrappedInstance();
		// da sich die Validatoren für einige Felder ändern wird hier von aussen die Validierung neu angestossen
		form.handleFieldValidChange(null, null);

		this.setState({countryCode: value});

	}

	handlePasswordOnChange(value) {
		this.pwConfirmValidator.changeValue(value);
		this.passwordRepeat.handleOnChange(this.passwordRepeat.state.value);
	}

	handleFormValidChange(valid) {
		this.setState({button_disable: !valid});
	}

	handleRegistrationAction() {
		let form = this.registerForm.getWrappedInstance();
		let data = form.value();
		this.props.dispatch(apiContactRegistration_post(data)).then(() => {
			Promise.all([
				this.props.dispatch(apiAuthCurrentUser()),
				this.props.dispatch(apiAuthCurrentSession())
			]);
		});
	}

	renderCompanyField(company) {
		if (this.state.customerType != "COMPANY") {
			return null;
		}
		const loading = this.props.app.registration.registration.result.loading;
		return (
			<ESForm.Company
				disabled={loading}
				value={company}
				validators={[
					new ESValidator.MinLength(2),
					new ESValidator.MaxLength(60),
					new ESValidator.Pattern("^[A-Za-z0-9áÁàÀâÂäÄãÃåÅçÇðÐéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕßþÞúÚùÙûÛüÜýÝÿ\\s\\,\\.\\-\\/]*$")
				]}
			/>
		);
	}

	renderDepartmentField(address) {
		if (this.state.customerType != "COMPANY") {
			return null;
		}
		const loading = this.props.app.registration.registration.result.loading;
		return (
			<ESForm.Department
				disabled={loading}
				value={address.DEPARTMENT}
				validators={[
					new ESValidator.MinLength(2),
					new ESValidator.MaxLength(60),
					new ESValidator.Pattern("^[A-Za-záÁàÀâÂäÄãÃåÅçÇðÐéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕßþÞúÚùÙûÛüÜýÝÿ\\s\\.\\-\\/]*$")
				]}
				optional
			/>
		);
	}


	renderVatIdField() {
		if (this.state.customerType != "COMPANY") {
			return null;
		}
		if (!this.props.country.data[this.state.countryCode].EU_COUNTRY) {
			return null;
		}
		const loading = this.props.app.registration.registration.result.loading;
		return (
			<ESForm.VatId
				disabled={loading}
				validators={[this.vatIdValidator]}
				value={this.props.currentSession.VATID}
				onRef={(ref) => {
					this.vatidField = ref;
				}}
			/>
		);
	}

	renderErrorMessage() {
		if (this.props.app.registration.registration.result.error == null) {
			return null;
		}
		let trPath = "";

		if (this.props.app.registration.registration.result.error.ERROR_CLASS === "EMailAlreadyExistsException") {
			trPath = "registerDialog.Alert.EMailAlreadyExistsException";
		} else if (this.props.app.registration.registration.result.error.ERROR_CLASS === "RegistrationEMailAlreadySendException") {
			trPath = "registerDialog.Alert.RegistrationEMailAlreadySendException";
		}
		return (
			<Alert bsStyle={"danger"}>
				<Tr path={trPath}/>
			</Alert>
		);
	}

	renderForm() {
		const address = this.getDeliveryAddressFromSession();
		const email = this.props.currentSession.data.EMAIL;
		const loading = this.props.app.registration.registration.result.loading;
		return (
			<ESForm
				form
				horizontal={this.props.horizontal}
				classValid={"ESFormFieldValid"}
				onValidChange={this.handleFormValidChange.bind(this)}
				ref={c => {
					this.registerForm = c;
				}}
			>

				<ESForm.CustomerType
					value={this.state.customerType}
					onChange={this.handleCustomerTypeChange.bind(this)}
					loadOptions={this.loadCustomerTypeOptions.bind(this)}
					disabled={loading}
				/>
				<ESForm.Country
					value={this.state.countryCode}
					onChange={this.handleCountryChange.bind(this)}
					loadOptions={() => _.map(this.props.loadShippingCountries())}
					disabled={loading}
				/>
				<ESForm.Break>
					<hr/>
				</ESForm.Break>

				{this.renderCompanyField(address.COMPANY)}
				{this.renderVatIdField()}
				{this.renderDepartmentField(address)}

				<ESForm.Firstname
					disabled={loading}
					value={address.FIRSTNAME}
					validators={[
						new ESValidator.MinLength(2),
						new ESValidator.MaxLength(60),
						new ESValidator.Pattern("^[A-Za-záÁàÀâÂäÄãÃåÅçÇðÐéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕßþÞúÚùÙûÛüÜýÝÿ\\s\\.\\-\\/]*$")
					]}
				/>
				<ESForm.Lastname
					disabled={loading}
					value={address.LASTNAME}
					validators={[
						new ESValidator.MinLength(2),
						new ESValidator.MaxLength(60),
						new ESValidator.Pattern("^[A-Za-záÁàÀâÂäÄãÃåÅçÇðÐéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕßþÞúÚùÙûÛüÜýÝÿ\\s\\.\\-\\/]*$")
					]}
				/>


				<ESForm.Street
					disabled={loading}
					value={address.STREET}
					validators={[
						new ESValidator.MinLength(2),
						new ESValidator.MaxLength(60),
						new ESValidator.Pattern("^[A-Za-z0-9áÁàÀâÂäÄãÃåÅçÇðÐéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕßþÞúÚùÙûÛüÜýÝÿ\\s\\,\\.\\-\\/]*$")
					]}
				/>
				<ESForm.Zipcode
					disabled={loading}
					value={address.ZIPCODE}
					validators={[this.zipcodeValidator]}
					onRef={(ref) => {
						this.zipcodeField = ref;
					}}
				/>
				<ESForm.City
					disabled={loading}
					value={address.CITY}
					validators={[
						new ESValidator.MinLength(2),
						new ESValidator.MaxLength(60),
						new ESValidator.Pattern("^[A-Za-z0-9áÁàÀâÂäÄãÃåÅçÇðÐéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕßþÞúÚùÙûÛüÜýÝÿ\\s\\,\\.\\-\\/]*$")
					]}
				/>

				<ESForm.Break>
					<hr/>
				</ESForm.Break>
				<ESForm.Phone
					disabled={loading}
					validators={[
						new ESValidator.MinLength(2),
						new ESValidator.MaxLength(25),
						new ESValidator.Pattern("^[0-9\\-\\+]*$")
					]}
					optional
				/>

				<ESForm.Email
					disabled={loading}
					value={email}
					validators={[
						new ESValidator.MinLength(2),
						new ESValidator.MaxLength(50),
						new ESValidator.Pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+[A-Za-z0-9-]+\\.[A-Za-z]{2,6}$")
					]}
				/>
				<ESForm.Password
					disabled={loading}
					onChange={this.handlePasswordOnChange.bind(this)}
					autoComplete={false}
					validators={[
						new ESValidator.MinLength(6),
						new ESValidator.Pattern("^[a-zA-Z0-9\\-_\\.,!?:\\-&\\/äöüÄÖÜß]{6,20}$")
					]}
				/>
				<ESForm.PasswordRepeat
					disabled={loading}
					notFormIncluded
					autoComplete={false}
					onRef={(ref) => {
						this.passwordRepeat = ref;
					}}
					validators={[
						this.pwConfirmValidator
					]}
				/>

				<ESForm.Break>
					<hr/>
				</ESForm.Break>
				<ESForm.Newsletter
					disabled={loading}
					// trOn={"newsletter.Toggle.On"}
					// trOff={"newsletter.Toggle.Off"}
					bsSize={"sm"}
					optional
				/>

				{/*Ich erkläre mich mit den {LINK_TO_GTC} einverstanden. Ich habe die {LINK_TO_PRIVACY_POLICY}, die {LINK_TO_REVOCATION}, sowie die die {LINK_TO_COOKIE_POLICY} wahrgenommen.*/}

				<ESForm.Break>
					<hr/>
				</ESForm.Break>

				<ESForm.Break label={<Tr path={"policies.TermsOfUse"}/>}>
					<InformationWell>
						<Tr path={"policies.AcceptAll"} format={{
							LINK_TO_GTC: <Link to={"/gtc"}><Tr
								path={"links.GTC.Long"}/></Link>,
							LINK_TO_PRIVACY_POLICY: <Link to={"/privacy"}><Tr
								path={"links.PrivacyPolicy"}/></Link>,
							LINK_TO_REVOCATION: <Link to={"/revocation"}><Tr
								path={"links.Revocation"}/></Link>,
							LINK_TO_COOKIE_POLICY: <Link to={"/cookies"}><Tr
								path={"links.CookiePolicy"}/></Link>
						}}/>
					</InformationWell>
				</ESForm.Break>
				<ESForm.GTCToggle
					disabled={loading}
					bsSize={"sm"}
					noLabel
				/>

			</ESForm>
		);
	}

	render() {
		if (!this.state.initialized) {
			return null;
		}
		return (
			<ESLoadable
				active={this.props.app.registration.registration.result.loading}
			>
				{this.renderForm()}
				{this.renderErrorMessage()}
				<Row>
					<Col xs={12}>
						{this.registrationButton()}
					</Col>
				</Row>
				<BackToLoginForm/>
			</ESLoadable>
		);
	}
}

export default compose(
	withTypeMapping(["CUSTOMER_TYPE"]),
	withAuthCurrentSession,
	withRouter,
	withShippingMethodCountries,
	connect((state) => ({
		app: state.app
	}))
)(RegistrationForm);
