import PropTypes from "prop-types";
import React from "react";
import {Tr} from "@esnpm/translate";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import {withShopProductContext} from "../ShopProductContext";

const classnames = require("classnames");

class AutoCompleteProduct extends React.Component {
	static propTypes = {
		product: PropTypes.object.isRequired,
		history: PropTypes.any,
		searchBar: PropTypes.any,
		selected: PropTypes.any
	};

	render() {
		const {product} = this.props;
		return (
			<div
				className={classnames("AutoCompleteProduct", {"selected": this.props.selected})}
				onMouseDown={this.handleOnMouseDown.bind(this)}>
				<span>
					<Tr path={product.NAME_TEXT_PATH}/>
				</span>
			</div>
		);
	}

	handleOnMouseDown() {
		this.props.history.push("/product/" + this.props.product.SKU);
		this.props.searchBar.resetSearch();
	}

}

export default compose(
	withRouter,
	withShopProductContext
)(AutoCompleteProduct);
