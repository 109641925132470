import _ from 'lodash';
import TagManager from 'react-gtm-module';
import { productBaseData } from '../../components/ShopProduct/ShopProductContainer';

const remove_empty_keys = (obj) => {
	return _.omitBy(obj, (v) => _.isUndefined(v) || _.isNull(v) || v === '');
};

export const gtmInit = () => {
	const { gtm } = window._es_env;
	if (_.isNull(gtm.gtmId) || _.isEmpty(gtm.gtmId)) return null;
	const initParameters = _.merge(
		{},
		{
			gtmId: gtm.gtmId,
		},
		_.isString(gtm.auth) && gtm.auth.length > 0
			? {
					auth: gtm.auth,
					preview: gtm.preview,
			  }
			: null
	);
	TagManager.initialize(initParameters);
};

export const push_to_data_layer = (dataLayer) => {
	const { gtm } = window._es_env;
	const enabled = !(_.isNull(gtm.gtmId) || _.isEmpty(gtm.gtmId));
	if (enabled) {
		// noinspection JSUnresolvedFunction
		TagManager.dataLayer({ ecommerce: null });
		// noinspection JSUnresolvedFunction
		TagManager.dataLayer({ dataLayer });
	}
};

const get_gtm_product = (data, list_name, amount) => {
	if (_.isNil(data)) return null;
	return remove_empty_keys({
		item_id: data.sku.toString(),
		item_name: data.NAME_TEXT_EN,
		item_brand: _.get(data, 'MANUFACTURER_NAME', null),
		item_list_name: list_name,
		price: _.get(data, ['PRICE', 'BRUTTO_SP'], null),
		currency: _.get(data, 'currency', 'EUR'),
		quantity: amount,
	});
};

export const gtmProductInfo = (product, list = null, amount = null) => {
	return Object.assign(
		{},
		{
			name: product.NAME_TEXT_EN,
			id: product.SKU.toString(),
			brand: product.MANUFACTURER_NAME,
		},
		_.has(product, 'PRICE') ? { price: product.PRICE.NETTO_SP } : null,
		list != null ? { list: list } : null,
		amount != null ? { quantity: amount } : null
	);
};

export const gtmImpression = (product, list) => {
	const dataLayer = {
		event: 'view_item_list',
		ecommerce: {
			items: [get_gtm_product(product, list)],
		},
	};
	push_to_data_layer(dataLayer);
};

export const gtmClick = (product, list) => {
	const dataLayer = {
		event: 'select_item',
		ecommerce: {
			items: [get_gtm_product(product, list)],
		},
	};
	push_to_data_layer(dataLayer);
};

export const gtmDetail = (product, list) => {
	const dataLayer = {
		event: 'view_item',
		ecommerce: {
			items: [get_gtm_product(product, list)],
		},
	};
	push_to_data_layer(dataLayer);
};

export const gtmAddToCart = (product, amount) => {
	const data_layer_product = get_gtm_product(product, null, amount);
	const dataLayer = {
		event: 'add_to_cart',
		ecommerce: {
			currency: product.CURRENCY,
			items: [data_layer_product],
			value: data_layer_product.price * amount,
		},
	};
	push_to_data_layer(dataLayer);
};

export const gtmRemoveFromCart = (product, amount) => {
	const data_layer_product = get_gtm_product(product, null, amount);
	const dataLayer = {
		event: 'remove_from_cart',
		ecommerce: {
			currency: product.CURRENCY,
			items: [data_layer_product],
			value: data_layer_product.price * amount,
		},
	};
	push_to_data_layer(dataLayer);
};

/*
 * CHECKOUT STARTS HERE
 * */

const get_gtm_position = (data, tr_provider) => {
	if (_.isNil(data)) return null;
	const product_data = productBaseData(data, tr_provider);
	return remove_empty_keys({
		item_id: data.SKU.toString(),
		item_name: product_data.NAME_TEXT_EN,
		// item_brand: _.get(data, 'MANUFACTURER_NAME'), // Not returned by productBaseData
		// price: _.get(data, 'PRICE'), // Not returned by productBaseData
		currency: _.get(data, 'CURRENCY', 'EUR'), // TODO - CURRENCY is not part of the provided data, see also issue #177
		quantity: data.AMOUNT,
	});
};

const get_product_data = (session_context, tr_provider) => {
	if (_.isNil(session_context)) return null;
	const positions = _.get(session_context, ['data', 'CART', 'POSITIONS'], []);
	return Object.values(positions).map((position) =>
		get_gtm_position(position, tr_provider)
	);
};

const get_session_transaction_data = (session_context) => {
	return remove_empty_keys({
		value: _.get(session_context, ['data', 'CART', 'TOTAL'], null),
		currency: 'EUR', // TODO - CURRENCY is not part of the provided data, see also issue #177
		transaction_id: _.get(
			session_context,
			['data', 'CART', 'ORDER_NUMBER'],
			null
		),
		shipping: _.get(session_context, ['data', 'CART', 'TOTAL_SHIPMENT'], null),
	});
};

export const gtmCheckoutInfo = (step, option = null, products = null) => {
	const actionField = Object.assign(
		{},
		{
			step: step,
		},
		option != null ? { option: option } : null
	);

	return _.merge(
		{},
		{
			event: 'checkoutStep',
			ecommerce: {
				currencyCode: 'EUR',
				checkout: {
					actionField: actionField,
					products: products,
				},
			},
		},
		products != null
			? { ecommerce: { checkout: { products: products } } }
			: null
	);
};

export const gtmCheckoutCheckout = () => {
	return null; // Todo Follow if GA4 works
	TagManager.dataLayer({ dataLayer: gtmCheckoutInfo(2) });
};

export const gtmCheckoutCheckoutSummary = () => {
	// TagManager.dataLayer({ dataLayer: gtmCheckoutInfo(3) });
};

export const checkout_custom_event = (
	event_name,
	session_context = null,
	tr_provider = null
) => {
	const product_data = get_product_data(session_context, tr_provider);
	const dataLayer = remove_empty_keys({
		event: event_name,
		ecommerce: remove_empty_keys(
			_.merge(
				{},
				{
					items: product_data,
				},
				get_session_transaction_data(session_context)
			)
		),
	});
	push_to_data_layer(dataLayer);
};

export const gtmCheckoutDone = (transaction) => {
	const transaction_data = remove_empty_keys({
		value: _.get(transaction, 'TOTAL_PRICE'),
		tax: _.get(transaction, 'TOTAL_TAX'),
		currency: 'EUR',
		transaction_id: _.get(transaction, ['ORDERS', [0], 'ORDER_NUMBER']),
		shipping: _.get(transaction, 'TOTAL_SHIPMENT_WITHOUT_TAX'),
		items: transaction.CHILDREN[0].POSITIONS.map((pos) => {
			return {
				name: pos.NAME,
				id: pos.SKU.toString(),
				quantity: pos.AMOUNT,
				price: pos.PRICE,
			};
		}),
	});

	const dataLayer = {
		event: 'checkout_purchase_completed',
		ecommerce: transaction_data,
	};

	push_to_data_layer(dataLayer);
};
