import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import {Link} from "react-router-dom";
import {InlineIcon} from "@esnpm/react-toolbox";
import CartIcon from "mdi-react/CartIcon";
import {compose} from "redux";
import React from "react";
import {ESFormat} from "@esnpm/format";

class CartPreview extends React.Component {

	static propTypes = {
		currentSession: PropTypes.any
	};

	render() {
		const classNames = classnames("CartPreview", "nav", "navbar-nav", "navbar-right");

		return (
			<ul className={classNames}>
				<li role={"presentation"}>
					<Link to={"/shopping_cart"}>
						<InlineIcon icon={CartIcon} className={"cart-icon"}/>
						<span>
							&nbsp;(<ESFormat.Price
							value={this.props.currentSession.data.CART.TOTAL} currency={"EUR"}/>)
						</span>
					</Link>
				</li>
			</ul>
		);
	}
}

export default compose(
	connect(
		(state) => (
			{
				currentSession: state.currentSession
			}
		)
	)
)(CartPreview);
