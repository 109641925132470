import React, { useCallback, useContext, useRef } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { TagNavHorizontalContext } from './TagNavHorizontal';

export const TagNavHorizontalPortalButtonContext = React.createContext(null);
TagNavHorizontalPortalButtonContext.displayName = 'TagNavHorizontalPortalButtonContext';

export const TagNavHorizontalPortalButton = ({ children, name }) => {
	const {
		selectedIconPortal,
		setSelectedIconPortal,
		activeIconRef
	} = useContext(TagNavHorizontalContext);

	const buttonRef = useRef(null);
	const isOpen = selectedIconPortal === name;
	const close = useCallback(() => {
		setSelectedIconPortal(null);
	}, [setSelectedIconPortal]);
	const onButtonClick = useCallback(() => {
		if (_.isNil(selectedIconPortal) || selectedIconPortal !== name) {
			activeIconRef.current = buttonRef.current;
			setSelectedIconPortal(name);
		} else {
			setSelectedIconPortal(null);
		}
	}, [
		selectedIconPortal,
		setSelectedIconPortal,
		activeIconRef,
		buttonRef,
		name
	]);

	const value = {
		buttonRef,
		isOpen,
		close,
		onButtonClick,
		name
	};

	return (
		<TagNavHorizontalPortalButtonContext.Provider value={value}>
			{children}
		</TagNavHorizontalPortalButtonContext.Provider>
	);
};
TagNavHorizontalPortalButton.propTypes = {
	name: PropTypes.string.isRequired
};
TagNavHorizontalPortalButton.Context = TagNavHorizontalPortalButtonContext;
