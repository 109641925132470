// Fix for #373
// Make sure just shipping countries are loaded with enabled shipping method
// and not all distinct countries from shipping_method incl. inactive countries
// Replaces this.props.shippingMethod.countries.data
// See also esdb -> esdb/esdb/controllers/ShippingMethod.py#29
export const shipping_country_codes = [
	'AD',
	'AL',
	'AT',
	'AU',
	'BA',
	'BE',
	'BG',
	'CA',
	'CH',
	'CN',
	'CY',
	'CZ',
	'DE',
	'DK',
	'EE',
	'ES',
	'FI',
	'FO',
	'FR',
	'GB',
	'GR',
	'HR',
	'HU',
	'IE',
	'IL',
	'IS',
	'IT',
	'JP',
	'KR',
	'LI',
	'LT',
	'LU',
	'LV',
	'MC',
	'MD',
	'ME',
	'MK',
	'MT',
	'MY',
	'NL',
	'NO',
	'NZ',
	'PL',
	'PT',
	'RO',
	'RS',
	'SE',
	'SG',
	'SI',
	'SK',
	'SM',
	'TH',
	'TW',
	'US',
	'ZA',
];
