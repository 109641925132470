import PropTypes from "prop-types";
import {compose} from "redux";
import React from "react";

/**
 * Footer Widget With Icon
 * For example newsletter or contact*/
class WidgetWithIcon extends React.Component {

	static propTypes = {
		icon: PropTypes.object.isRequired,
		headline: PropTypes.any.isRequired,
		content: PropTypes.any.isRequired,
		callToAction: PropTypes.any.isRequired
	};

	render() {
		return (
			<div className={"WidgetWithIcon"}>
				<div className={"icon-wrap"}>
					{this.props.icon}
				</div>
				<div className={"content-wrap"}>
					<h3>{this.props.headline}</h3>
					<p>{this.props.content}</p>
					{this.props.callToAction}
				</div>
			</div>
		);
	}
}

export default compose(

)(WidgetWithIcon);
