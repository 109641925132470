import { createTheme } from '@material-ui/core';
import { amber, red, lightGreen, green } from '@material-ui/core/colors';
import _ from 'lodash';

export const theme = createTheme({
	overrides: {
		// MuiChip: { label: { fontSize: 14 } },
	},
	palette: {
		primary: _.merge({}, lightGreen, { contrastText: '#fff' }),
		secondary: amber,
		tableError: {
			light: red[100],
			dark: red[300],
		},
	},
	typography: {
		fontSize: 16,
	},
});
