import React from "react";
import {Route} from "react-router-dom";

const module = (shopApp) => {
	const LegalNotice = shopApp.dynamic(() => import("./LegalNotice"));

	const url = "/legal";

	let config = {
		name: "LegalNotice",
		routes: [
			<Route key={url} exact path={url} component={LegalNotice}/>
		]
	};

	shopApp.registerPage(config);
	
};

export default module;
