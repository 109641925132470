export const trmCorporate = {
	pathesToCheck: ["corporate"],
	tr: {
		corporate: {
			ContactDetails: {
				DE: "Adresse und Öffnungszeiten",
				EN: "Address and Opening Times",
				FR: "Adresse et horaires d'ouverture",
			},
			Address: {
				Luxembourg: {
					DE: "Luxemburg",
					EN: "Luxembourg",
					FR: "Luxembourg",
				}
			},
			Contact: {
				PhoneNumber: {
					DE: "Telefon",
					EN: "Phone",
					FR: "Téléphone",
				},
				FaxNumber: {
					DE: "Fax",
					EN: "Fax",
					FR: "Fax",
				},
				EmailAddress: {
					DE: "E-Mail",
					EN: "Email",
					FR: "E-mail",

				},
			},
			Openingtimes: {
				Headline: {
					FR: "Heures de service",
					DE: "Servicezeiten",
					EN: "Service Times",
				},
				Workweek: {
					Days: {
						FR: "Du lundi au vendredi ",
						DE: "Montag bis Freitag ",
						EN: "Monday to Friday ",
					},
					Time: {
						DE: "09:00-12:00 Uhr<br/>14:00-17:00 Uhr",
						EN: "09:00 to 12:00<br/>14:00 to 17:00",
						FR: "De 9h-12h<br/>14h-17h",
					},
				},
				Weekend: {
					DE: "Geschlossen",
					EN: "Closed",
					FR: "Fermé.",
				},
			},
			Management: {
				Management: {
					DE: "Geschäftsführung",
					EN: "Management",
					FR: "Direction",
				},
				Publisher: {
					FR: "Editeur / Adresse",
					DE: "Herausgeber / Anschrift",
					EN: "Publisher / Address",
				},
				Manager: {
					FR: "Directeur",
					DE: "Geschäftsführer",
					EN: "Manager",
				},
				RepresentedBy: {
					DE: "Vertreten durch",
					EN: "Represented by",
					FR: "Représenté par"
				}
			},
			Register: {
				CommercialRegister: {
					DE: "Handelsregister",
					EN: "Trade register",
					FR: "Registre du commerce",
				},
				Matricule: {
					DE: "Registrierungsnummer",
					EN: "Registration number",
					FR: "Matricule",
				},
				VatID: {
					DE: "Umsatzsteuer-Identifikationsnummer",
					EN: "VAT identification number",
					FR: "Numéro d'identification fiscale",
				},
			}
		}
	}
};
