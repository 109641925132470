import React from "react";
import {Route} from "react-router-dom";

const module = (shopApp) => {

	const Start = shopApp.dynamic(() => import("./Start"));
	const url = "/";

	let config = {
		name: "Start",
		routes: [
			<Route key={url} exact path={url} component={Start}/>
		]
	};

	shopApp.registerPage(config);

};

export default module;
