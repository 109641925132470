import React from "react";
import {Route} from "react-router-dom";


const module = (shopApp) => {
	const CookiePolicy = shopApp.dynamic(() => import("./CookiePolicy"));

	const url = "/cookies";

	let config = {
		name: "CookiePolicy",
		routes: [
			<Route key={url} exact path={url} component={CookiePolicy}/>
		]
	};

	shopApp.registerPage(config);

};

export default module;
