import React from "react";
import PropTypes from "prop-types";
import {apiAuthCurrentUser} from "@esnpm/eswebapp";
import {compose} from "redux";
import {connect} from "react-redux";
import {action_once} from "@esnpm/node-toolbox/build/lib/estoolbox";
import _ from "lodash";
import {wrapComponent} from "@esnpm/node-toolbox";

class AuthCurrentUserContainerComponent extends React.Component {
	static propTypes = {
		children: PropTypes.any,
		currentUser: PropTypes.any,
	};

	constructor(props) {
		super(props);
		// this.props.dispatch(apiAuthCurrentUser());
	}

	componentDidMount() {
		this.props.dispatch(
			action_once((state) => {
					return (!(
						_.get(state, "currentUser.data", null) != null ||
						_.get(state, "currentUser.error", null) != null
					));
				}, apiAuthCurrentUser()
			)
		);
	}

	render() {
		if (!(
			_.get(this.props, "currentUser.data", null) != null ||
			_.get(this.props, "currentUser.error", null) != null
		)) return null;
		return React.Children.map(this.props.children, (child) => React.cloneElement(child, {
			currentUser: this.props.currentUser
		}));
	}
}

const AuthCurrentUserContainer = compose(
	connect(
		(state) => ({
			currentUser: state.currentUser
		})
	)
)(AuthCurrentUserContainerComponent);

export const withAuthCurrentUser = wrapComponent(AuthCurrentUserContainer);
