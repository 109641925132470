import React from "react";
import {Route} from "react-router-dom";


const module = (shopApp) => {
	const NotFound = shopApp.dynamic(() => import("./NotFound"));
	const url = "not_found";

	let config = {
		name: "NotFound",
		routes: [
			<Route key={url} url={url} status={404} component={NotFound}/>,
			<Route key={"404"} status={404} component={NotFound}/>
		]
	};

	shopApp.registerPage(config);

};

export default module;
