import PropTypes from "prop-types";
import React, {Component} from "react";
import ScrollTop from "./ScrollTop";
import {compose} from "redux";
import ShopTr from "./ShopTr";
import {Switch, withRouter} from "react-router-dom";
import {ShopAppContext} from "./ShopAppContext";
import {connect} from "react-redux";
import Promise from "bluebird";
import {ESForm} from "@esnpm/forms";
import {apiTypeMapping, ProductsMultipleView} from "@esnpm/esdb";
import {app_info} from "../actions/AppInfo";
import _ from "lodash";
import {IndexHtml} from "./IndexHtml";
import dynamic from "next/dynamic";


const notFoundModule = require("../pages/NotFound");


class ShopApp extends Component {
	static defaultProps = {
		loginComponent: null
	};

	static propTypes = {
		app: PropTypes.object,
		children: PropTypes.any,
		loginComponent: PropTypes.node,
		pages: PropTypes.arrayOf(PropTypes.any).isRequired
	};

	dynamic = (callableImport) => {
		return dynamic(callableImport, {
			loading: () => <IndexHtml/>
		});
	};



	constructor(props, context) {
		super(props, context);
		this.pages = {};
		this.pageOrder = [];
		this.redirects = [];
		this.routes = [];
		this.menu = [];
		this.headerItems = [];
		this.footerItems = [];
		this.registerPages();
		this.state = {
			initialized: false,
			showLoginComponent: false
		};
	}


	componentDidMount() {
		Promise.all([
			this.props.dispatch(ESForm.registerTranslation()), // TODO: ESForm auf withTr umstellen
			this.props.dispatch(ProductsMultipleView.registerTranslation()), // TODO: ESForm auf withTr umstellen
			this.props.dispatch(app_info()),
			this.props.dispatch(apiTypeMapping({SET: "CUSTOMER_TYPE"})),
			this.props.dispatch(apiTypeMapping({SET: "PAYMENT_METHOD"}))
		]).then(() => {
			this.setState({initialized: true});
		});
	}

	setShowLoginComponent(show) {
		this.setState({
			showLoginComponent: show
		});
	}


	registerPages() {
		const pages = _.concat([], this.props.pages);
		pages.map((module) => module.default(this));

		// NotFound page registrieren ( Am Ende weil Fallback )
		notFoundModule.default(this);
	}

	registerPage(config) {

		config = _.merge({}, {redirects: [], routes: []},config);

		this.pages[config.name] = config;
		this.pageOrder.push(config.name);

		//		Redirects
		config.redirects.map((redirect) => this.redirects.push(redirect));
		//		Routes
		config.routes.map((route) => this.routes.push(route));

	}

	renderRoutes() {
		// Erst redirects
		let routes = [
			// 	<Redirect key={"/"} exact from='/' to='/dashboard' state={{status: 302}}/>
		];

		//		Redirects
		this.redirects.map((redirect) => routes.push(redirect));
		//		Routes
		this.routes.map((route) => routes.push(route));


		return routes;
	}


	renderContent = () => {
		return (
			<Switch>
				{this.renderRoutes()}
			</Switch>
		);
	};

	addHeaderItem = (item) => (
		this.headerItems.push(item)
	);

	addFooterItem = (item) => (
		this.footerItems.push(item)
	);

	getContextValues = () => ({
		setState: this.setState.bind(this),
		renderPages: this.renderContent,
		page: {...this.props.app.page},
		addHeaderItem: this.addHeaderItem,
		renderHeaderItems: () => this.headerItems,
		addFooterItem: this.addFooterItem,
		renderFooterItems: () => this.footerItems,
		showLoginComponent: this.state.showLoginComponent,
		loginComponent: this.props.loginComponent,
		setShowLoginComponent: this.setShowLoginComponent
	});


	render() {
		if (!this.state.initialized) return null;
		return (
			<ShopAppContext.Provider value={this.getContextValues()}>
				<ShopTr>
					<ScrollTop>
						<div className={"App"}>
							{/*<NewVersionAvailable/>*/}
							{this.renderContent()}
							{this.props.children}
						</div>
					</ScrollTop>
				</ShopTr>
			</ShopAppContext.Provider>
		);
	}


}

export default compose(
	withRouter,
	connect((state) => ({
		app: state.app
	}))
)(ShopApp);
