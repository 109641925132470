export const trmPolicies = {
	pathesToCheck: ["policies"],
	tr: {
		policies: {
			TermsOfUse: {
				DE: "Nutzungsbedingungen",
				EN: "Terms of use",
				FR: "Conditions d'utilisation"
			},
			AcceptAll: {
				DE: "Ich erkläre mich mit den {LINK_TO_GTC} einverstanden. Ich habe die {LINK_TO_PRIVACY_POLICY}, die {LINK_TO_REVOCATION}, sowie die die {LINK_TO_COOKIE_POLICY} wahrgenommen.",
				EN: "I agree to the general {LINK_TO_GTC}. I also agree to the {LINK_TO_PRIVACY_POLICY}, the {LINK_TO_REVOCATION} and the {LINK_TO_COOKIE_POLICY}.",
				FR: "Je suis d'accord avec les {LINK_TO_GTC}. J'ai remarqué la {LINK_TO_PRIVACY_POLICY}, la {LINK_TO_REVOCATION} et la {LINK_TO_COOKIE_POLICY}.}"
			}
		}
	}
};
