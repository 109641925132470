import PropTypes from "prop-types";
import React from "react";
import Row from "react-bootstrap/es/Row";
import Col from "react-bootstrap/es/Col";
import {compose} from "redux";

class CurrentBreakpoint extends React.Component {
	static defaultProps = {
		disabled: false
	};

	static propTypes = {
		disabled: PropTypes.bool
	};

	render() {
		if (this.props.disabled) return null;
		return (
			<Row className={"taCenter"}>
				<Col smHidden mdHidden lgHidden xs={12}>
					XS Breakpoint
				</Col>
				<Col xsHidden mdHidden lgHidden sm={12}>
					SM Breakpoint
				</Col>
				<Col xsHidden smHidden lgHidden md={12}>
					MD Breakpoint
				</Col>
				<Col xsHidden smHidden mdHidden lg={12}>
					LG Breakpoint
				</Col>
			</Row>
		);
	}
}

export default compose(

)(CurrentBreakpoint);
