import React from "react";
import {Tr, withTranslationSelector} from "@esnpm/translate";
import {AppShowLanguageModal} from "../actions/App/AppCommonDialog";
import Image from "react-bootstrap/es/Image";
import {compose} from "redux";
import Modal from "react-bootstrap/es/Modal";
import Button from "react-bootstrap/es/Button";
import {connect} from "react-redux";
import {apiSessionSetActiveLanguage_post} from "../actions/esshop2/apiSessionSetActiveLanguage_post";
import {apiSessionSetLanguageChanged_post} from "../actions/esshop2/apiSessionSetLanguageChanged_post";
import PropTypes from "prop-types";
import {apiContactChangeLanguageCode_post} from "../actions/esshop2/apiContactChangeLanguageCode_post";

class TranslationSelectorDialog extends React.Component {
	static propTypes = {
		currentUser: PropTypes.object.isRequired,
		setActiveLanguage: PropTypes.func.isRequired,
		trProvider: PropTypes.object.isRequired
	};

	changeLanguage(LC) {
		this.props.setActiveLanguage(LC);
		this.props.dispatch(apiSessionSetActiveLanguage_post({
			LANGUAGE_CODE: LC,
		})).then(
			() => {
				if (!(this.props.currentUser.data == null || Object.keys(this.props.currentUser.data).length === 0)) {
					//	user ist angemeldet:
					return this.props.dispatch(apiContactChangeLanguageCode_post({"LANGUAGE_CODE": LC}));
				} else {
					//	user ist NICHT angemeldet:
					return this.props.dispatch(apiSessionSetLanguageChanged_post({LANGUAGE_CHANGED: true}));
				}
			}).then(
			() => this.props.dispatch(AppShowLanguageModal(false))
		);


	}

	renderLanguages() {
		let language_list = this.props.trProvider.availableLanguages;
		let return_list = [];
		language_list.map((lc) => {
			const tr_string = "language.Languages." + lc;

			return_list.push(
				<Button bsStyle="default"
								block
								key={lc}
								onClick={() => this.changeLanguage(lc)}
								className={"LanguageDiv"}>
					<Image
						src={"/images/icons/flags/icon_nav_language_" + lc.toLowerCase() + ".png"}
					/>&nbsp;
					<Tr path={tr_string}/>
				</Button>);
		});
		return (return_list);
	}

	render() {
		return (
			<Modal
				show={true}
				animation={false}
				backdrop={"static"}
				onHide={() =>
					this.props.dispatch(AppShowLanguageModal(false))
				}
				className={"LanguageDialog"}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<Tr path={"language.ChangeLanguage"}/>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{this.renderLanguages()}
				</Modal.Body>
			</Modal>
		);
	}
}

export default compose(
	withTranslationSelector,
	connect((state) => ({currentUser: state.currentUser}))
)(TranslationSelectorDialog);
