import React, { useCallback, useContext, useEffect, useRef } from 'react';
import _ from 'lodash';
import { TagNavHorizontalContext } from './TagNavHorizontal';
import { MuiPortal } from '../MuiPortal';

const TagNavHorizontalPortal = ({ children }) => {
	// NOSSR
	return <MuiPortal id={'HorizontalMenuPortal'}>{children}</MuiPortal>;
};

const TagNavHorizontalPortalPlacer = ({ theme, children }) => {
	const horizontalMenuPortalRef = useRef(null);
	const { activeIconRef, selectedIconPortal, setSelectedIconPortal } =
		useContext(TagNavHorizontalContext);

	useEffect(() => {
		if (!_.isNil(selectedIconPortal)) {
			document.addEventListener('mousedown', handleOutsideClick);
			return () => {
				document.removeEventListener('mousedown', handleOutsideClick);
			};
		}
	}, [selectedIconPortal]);

	const handleOutsideClick = useCallback(
		(e) => {
			const outside = !horizontalMenuPortalRef.current.contains(e.target);
			const notIcon = !activeIconRef.current.contains(e.target);
			if (outside && notIcon) setSelectedIconPortal(null);
		},
		[horizontalMenuPortalRef, setSelectedIconPortal, activeIconRef]
	);

	return (
		<div
			id={'HorizontalMenuPortal'}
			className={'HorizontalMenuPortal'}
			ref={horizontalMenuPortalRef}
		>
			{children}
		</div>
	);
};

const TagNavHorizontalPortalPaper = ({ children }) => {
	return <div>{children}</div>;
};

export {
	TagNavHorizontalPortal,
	TagNavHorizontalPortalPlacer,
	TagNavHorizontalPortalPaper,
};
