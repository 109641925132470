export const trmButtons = {
	pathesToCheck: ['buttons'],
	tr: {
		buttons: {
			Save: {
				DE: 'Abspeichern',
				EN: 'Save',
				FR: 'Sauvegarder',
			},
			Submit: {
				DE: 'Absenden',
				EN: 'Submit',
				FR: 'Envoyer',
			},
			SaveChanges: {
				DE: 'Änderungen abspeichern',
				EN: 'Save changes',
				FR: 'Sauvegarder les modifications',
			},
			PasswordRequest: {
				DE: 'Passwort anfordern',
				EN: 'Request password',
				FR: 'Demande de mot de passe',
			},
			PasswordChange: {
				DE: 'Passwort ändern',
				EN: 'Change password',
				FR: 'Changer le mot de passe',
			},
			Close: {
				DE: 'Schließen',
				EN: 'Close',
				FR: 'Fermer',
			},
			Open: {
				DE: 'Aufklappen',
				EN: 'Open',
				FR: 'Ouvrir',
			},
			Abort: {
				DE: 'Abbrechen',
				EN: 'Abort',
				FR: 'Abandonner',
			},
			Continue: {
				DE: 'Weiter',
				EN: 'Continue',
				FR: 'Continuer',
			},
			Back: {
				DE: 'Zurück',
				EN: 'Back',
				FR: 'Retour',
			},
			Login: {
				DE: 'Anmelden',
				EN: 'Sign in',
				FR: "S'identifier",
			},
			Logout: {
				DE: 'Abmelden',
				EN: 'Logout',
				FR: 'Se désinscrire',
			},
			Register: {
				DE: 'Registrieren',
				EN: 'Register',
				FR: "S'inscrire",
			},
			RegisterNow: {
				DE: 'Jetzt registrieren',
				EN: 'Register now',
				FR: 'Inscription',
			},
			AlreadyCustomer: {
				DE: 'Sie haben bereits ein Konto? Dann loggen Sie sich hier ein.',
				EN: 'You already have an account? Log in here.',
				FR: 'Vous avez déjà un compte ? Connectez-vous ici !',
			},
			Checkout: {
				DE: 'Zur Kasse',
				EN: 'Checkout',
				FR: 'Passer à la caisse',
			},
			OrderNow: {
				DE: 'Jetzt kostenpflichtig bestellen',
				EN: 'Place binding order now',
				FR: 'Commande contre paiement',
			},
			AddToCart: {
				DE: 'In den Warenkorb',
				EN: 'Add to cart',
				FR: 'Ajouter au panier',
			},
			RemoveFromCart: {
				DE: 'Entfernen',
				EN: 'Remove',
				FR: 'Supprimer',
			},
			NewsletterSubscribe: {
				DE: 'Newsletter abonnieren',
				EN: 'Subscribe our newsletter',
				FR: 'Inscription à la newsletter',
			},
			Address: {
				Add: {
					DE: 'Adresse hinzufügen',
					EN: 'Add address',
					FR: 'Ajouter une adresse',
				},
				Edit: {
					DE: 'Adresse bearbeiten',
					EN: 'Edit address',
					FR: "Modifier l'adresse",
				},
				Remove: {
					DE: 'Adresse entfernen',
					EN: 'Remove address',
					FR: "Supprimer l'adresse",
				},
			},
			ProceedAsGuest: {
				DE: 'Als Gast bestellen',
				EN: 'Proceed as guest',
				FR: "Commander en tant qu'invité",
			},
			Edit: {
				DE: 'Bearbeiten',
				EN: 'Edit',
				FR: 'Editer',
			},
			Add: {
				DE: 'Hinzufügen',
				EN: 'Add',
				FR: 'Ajouter',
			},
			Remove: {
				DE: 'Entfernen',
				EN: 'Remove',
				FR: 'Supprimer',
			},
			CategorySelect: {
				DE: '-- Bitte wählen Sie eine Kategorie --',
				EN: '-- Please select a category --',
				FR: '-- Veuillez sélectionner une catégorie --',
			},
			Yes: {
				DE: 'Ja',
				EN: 'Yes',
				FR: 'Oui',
			},
			No: {
				DE: 'Nein',
				EN: 'No',
				FR: 'Non',
			},
			ToTop: {
				DE: 'Nach oben scrollen',
				EN: 'Back to top',
				FR: 'En haut de page',
			},
			BackToStart: {
				DE: 'Zurück zur Startseite',
				EN: 'Switch to home page',
				FR: "Page d'accueil",
			},
		},
	},
};
