export const trmHeader = {
	pathesToCheck: ["header"],
	tr: {
		header: {
			ChooseParentCat: {
				DE: "Zur Übergeordneten Kategorie",
				EN: "To parent category",
				FR: "À la catégorie supérieur"
			},
			Settings: {
				DE: "Einstellungen",
				EN: "Settings",
				FR: "Paramètres"
			},
			Covid: {
				Note: {
					DE: "Hinweis",
					EN: "Note",
					FR: "Note"
				},
				Text: {
					DE: "Durch die aktuelle Situation ist es möglich, dass es im Bestellablauf zu Verzögerungen kommt. Wir und unsere Lieferanten tun unser Bestes um Ihre Bestellung schnellstmöglich abzuwickeln. Vielen Dank für Ihre Unterstützung!",
					EN: "Due to the current situation it is possible that there may be delays in the order process. We and our suppliers do our best to handle your order as quickly as possible. Thank you very much for your support!",
					FR: "En raison de la situation actuelle, il est possible qu'il y ait des retards dans le processus de commande. Nos fournisseurs et nous-mêmes faisons de notre mieux pour traiter votre commande le plus rapidement possible. Merci beaucoup pour votre soutien !"

				}
			}
		}
	}
};
