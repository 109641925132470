import PropTypes from "prop-types";
import React from "react";
import {Button, Modal} from "react-bootstrap";
import {Tr} from "@esnpm/translate";
import {connect} from "react-redux";
import {AppShowSettingsModal} from "../actions/App/AppCommonDialog";
import {apiSessionSetCustomerData_post} from "../actions/esshop2/apiSessionSetCustomerData_post";
import {ESForm} from "@esnpm/forms";
import {ResponsiveRender} from "@esnpm/react-toolbox";
import {compose} from "redux";
import _ from "lodash";
import {withShippingMethodCountries} from "../factories/withShippingMethodCountries";
import {withTypeMapping} from "@esnpm/esdb";
import {withAuthCurrentSession} from "../factories/withAuthCurrentSession";

class SettingsDialogModal extends React.Component {
	static propTypes = {
		currentSession: PropTypes.any,
		shippingMethod: PropTypes.any,
		typemapping: PropTypes.any,
		loadShippingMethodCountries: PropTypes.func,
		loadShippingCountries: PropTypes.func
	};

	handleSaveButton() {
		const data = this.settingsDialog.getWrappedInstance().value();
		this.props.dispatch(apiSessionSetCustomerData_post({
				CUSTOMER_TYPE: data.CUSTOMER_TYPE,
				COUNTRY: data.COUNTRY_CODE,
			},
		));
	}

	loadCustomerTypes() {
		let ret = [];
		Object.values(this.props.typemapping.data.CUSTOMER_TYPE.OPTIONS).map((ct) => {
			if (["PRIVATE", "COMPANY"].indexOf(ct.NAME) >= 0) ret.push({
				value: ct.NAME,
				textTr: "typeMapping.CUSTOMER_TYPE." + ct.NAME
			});
		});
		return ret;

	}

	getCurrentCountryCode() {
		let adresses = this.props.currentSession.data.ADDRESSES.filter(adr => adr.ADDRESS_TYPES.indexOf("DELIVERY") >= 0);
		return (adresses.length == 0) ? "LU" : adresses[0].COUNTRY_CODE;
	}


	renderForm(horizontal = true) {
		return (
			<ESForm
				ref={c => {
					this.settingsDialog = c;
				}}
				noValidationClassChange
				noValidationLabelClassChange
				horizontal={horizontal}
				form
			>
				<ESForm.CustomerType
					loadOptions={() => this.loadCustomerTypes()}
					value={this.props.currentSession.data.CUSTOMER_TYPE}
				/>
				<ESForm.Country
					loadOptions={() => _.map(this.props.loadShippingCountries())}
					value={this.getCurrentCountryCode()}
				/>
			</ESForm>
		);
	}

	render() {
		return (
			<Modal
				show={true}
				animation={false}
				backdrop={"static"}
				onHide={() =>
					this.props.dispatch(AppShowSettingsModal(false))
				}
				className={"SettingsDialog"}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<Tr path={"header.Settings"}/>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ResponsiveRender xs>
						{this.renderForm(false)}
					</ResponsiveRender>
					<ResponsiveRender sm md lg>
						{this.renderForm(true)}
					</ResponsiveRender>
				</Modal.Body>
				<Modal.Footer>
					<Button
						className={"BackButton"}
						onClick={() => this.props.dispatch(AppShowSettingsModal(false))}>
						<Tr path={"buttons.Abort"}/>
					</Button>
					<Button
						className={"SaveButton"}
						bsStyle={"primary"}
						onClick={this.handleSaveButton.bind(this)}>
						<Tr path={"buttons.Save"}/>
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default compose(
	withShippingMethodCountries,
	withTypeMapping(["CUSTOMER_TYPE"]),
	withAuthCurrentSession,
	connect()
)(SettingsDialogModal);
