import Promise from "bluebird";

export const APP_LANGUAGE_MODAL_SUCCESS = "APP_LANGUAGE_MODAL_SUCCESS";
export const APP_SHOW_SETTINGS_MODAL_SUCCESS = "APP_SHOW_SETTINGS_MODAL_SUCCESS";

export const AppShowSettingsModal_success = (data) => {
	return {
		type: APP_SHOW_SETTINGS_MODAL_SUCCESS,
		value: data,
	};
};

export const AppShowSettingsModal = (data) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(AppShowSettingsModal_success(data));
			resolve();
		},
	);

export const AppLanguageModal_success = (data) => {
	return {
		type: APP_LANGUAGE_MODAL_SUCCESS,
		value: data,
	};
};

export const AppShowLanguageModal = (data) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(AppLanguageModal_success(data));
			resolve();
		},
	);
