import React from "react";
import {Route} from "react-router-dom";

const module = (shopApp) => {
	const Sales = shopApp.dynamic(() => import("./Sales"));

	const url = "/sales";

	let config = {
		name: "Sales",
		routes: [
			<Route key={url} exact path={url} component={Sales}/>
		]
	};

	shopApp.registerPage(config);

};

export default module;
