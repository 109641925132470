import React from "react";
import {Route} from "react-router-dom";

const module = (shopApp) => {
	const ProductSingle = shopApp.dynamic(() => import("./ProductSingle"));

	let config = {
		name: "ProductSingle",
		routes: [
			<Route key={"/product/:SKU"} path={"/product/:SKU"} component={ProductSingle}/>,
			<Route key={"/products/:SKU"} path={"/products/:SKU"} component={ProductSingle}/>,
		]
	};

	shopApp.registerPage(config);

};

export default module;
