export const trmProduct = {
	pathesToCheck: ['product'],
	tr: {
		product: {
			ArticleNo: {
				DE: 'Artikel-Nr.',
				EN: 'Article number',
				FR: "Numéro d'article",
			},
			Manufacturer: {
				DE: 'Hersteller',
				EN: 'Manufacturer',
				FR: 'Fabricant',
			},
			By: {
				DE: 'von',
				EN: 'by',
				FR: 'de',
			},
			MPN: {
				DE: 'Hersteller-Nummer',
				EN: 'Manufacturer Part No',
				FR: 'Numéro de fabricant',
			},
			EAN: {
				ALL: 'EAN',
			},
			Stock: {
				Timeframe10: {
					DE: 'Auf Lager, versandfertig am nächsten Werktag',
					EN: 'On stock, ready for delivery on the next business day',
					FR: 'En stock, prêt pour l"expédition le jour ouvrable suivant',
				},
				Timeframe20: {
					DE: 'Auf Lager, versandfertig in 2-3 Werktagen',
					EN: 'On stock, ready for delivery 2-3 business days',
					FR: 'En stock, prêt pour l"expédition sous 2-3 jours',
				},
				Timeframe30: {
					DE: 'Im Zulauf, versandfertig in 3-5 Werktagen',
					EN: 'On stock, ready for delivery 3-5 business days',
					FR: 'Rupture de stock, livrable en 3 à 5 jours ouvrables',
				},
				Timeframe40: {
					DE: 'Im Zulauf, versandfertig in 5-7 Werktagen',
					EN: 'Arriving soon, ready for delivery 5-7 business days',
					FR: 'Rupture de stock, livrable en 5 à 7 jours ouvrables',
				},
				Timeframe100: {
					DE: 'Liefertermin auf Anfrage',
					EN: 'Delivery time on request',
					FR: 'Date de livraison sur demande',
				},
				ExpectedDate: {
					DE: 'Im Zulauf, voraussichtlich lieferbar ab:',
					EN: 'In the feed, expected to be available from:',
					FR: 'Probablement disponible:',
				},
				PresaleDate: {
					DE: 'Vorverkauf, lieferbar ab:',
					EN: 'Presale, to be available from:',
					FR: 'Prévente, livrable dès le:',
				},
			},
			Price: {
				Standard: {
					FR: 'Prix',
					DE: 'Preis',
					EN: 'Price',
				},
				Previous: {
					FR: 'Prix d"origine',
					DE: 'Vorheriger Preis',
					EN: 'Previous price',
				},
				Offer: {
					FR: 'Prix promotionel',
					DE: 'Angebotspreis',
					EN: 'Special-offer price',
				},
			},
			Vat: {
				Excl: {
					DE: 'Alle Preise exkl. gesetzlicher&nbsp;Mehrwertsteuer.',
					EN: 'All prices excl. VAT.',
					FR: 'Tous les prix sont hors TVA',
				},
				Incl: {
					DE: 'Alle Preise inkl. gesetzlicher&nbsp;Mehrwertsteuer.',
					EN: 'All prices incl. VAT.',
					FR: 'Tous les prix s"entendent TVA incluse',
				},
			},
			Amount: {
				DE: 'Menge',
				EN: 'Amount',
				FR: 'Quantité',
			},
			Remaining: {
				FR: 'Liquidation! Il y a seulement une quantité limitée du produit disponible.',
				DE: 'Restposten! Es ist nur noch eine begrenzte Menge des Produktes verfügbar.',
				EN: 'Remaining stock! There is only a limited quantity available.',
			},
			NoSale: {
				Headline: {
					DE: 'Produkt nicht mehr erhältlich',
					EN: 'Product no longer available',
					FR: "Le produit n'est plus disponible",
				},
				Content: {
					General: {
						DE: 'Dieses Produkt ist leider nicht mehr Teil unseres Angebotes.',
						EN: 'This product is not longer part of our offer.',
						FR: 'Ce produit ne fait plus partie de notre offre.',
					},
					Discontinued: {
						DE: 'Diese Seite ist verfügbar für alle, die Datenblätter suchen oder einfach nur neugierig sind.',
						EN: 'These site is for those who are interested in datasheets or just interested in them.',
						FR: "Ces sites s'adressent à ceux qui s'y intéressent ou ceux qui s'intéressent aux fiches techniques.",
					},
					Updated: {
						DE: 'Ein aktualisierte Version finden Sie hier: {LINK}',
						EN: 'A updated version can be found here: {LINK}',
						FR: 'Une mise à jour peut être trouvée ici: {LINK}',
					},
				},
			},
			EOL: {
				Headline: {
					DE: 'Restposten',
					EN: 'Remaining stock',
					FR: 'Stock restant',
				},
				Description: {
					DE: 'Nur solange der Vorrat reicht !',
					EN: 'Only while stocks last !',
					FR: "Jusqu'à épuisement des stocks !",
				},
			},
			Sale: {
				Discount: {
					FR: 'Remise',
					DE: 'Sparen',
					EN: 'Save',
				},
				DiscountApproach: {
					DE: 'Sie sparen',
					EN: 'You save',
					FR: 'Vous économisez',
				},
			},
			InCart: {
				DE: '{AMOUNT}x im Warenkorb enthalten.',
				EN: '{AMOUNT} times in your shopping cart.',
				FR: "{AMOUNT}x dans votre panier d'achat.",
			},
			MOQHint: {
				DE: 'Mindestbestellmenge: {AMOUNT}',
				EN: 'Minimum order quantity: {AMOUNT}',
				FR: 'Quantité minimum de commande: {AMOUNT}',
			},
			Related: {
				Master: {
					DE: 'Kompatible Produkte',
					EN: 'Compatible products',
					FR: 'Produits compatibles',
				},
				Consumables: {
					DE: 'Passendes Verbrauchsmaterial',
					EN: 'Consumables for this product',
					FR: 'Consommable indiqué',
				},
				Accessories: {
					DE: 'Zubehör für dieses Produkt',
					EN: 'Accessories for this product',
					FR: 'Accessoires pour ce produit',
				},
			},
			Site: {
				Description: {
					DE: 'Produktbeschreibung',
					EN: 'Description',
					FR: 'Spécification du produit',
				},
				TechnicalSpecifications: {
					DE: 'Technische Daten',
					EN: 'Technical Specifications',
					FR: 'Caractéristiques techniques',
				},
			},
			Limited: {
				Note: {
					DE: 'Hinweis zur Bestellmenge',
					EN: 'Note on the maximum order quantity',
				},
				Amount: {
					DE: 'Die Bestellmenge ist auf {AMOUNT} Einheiten je Kunde begrenzt.',
					EN: 'The order quantity is limited to {AMOUNT} units per customer.',
					FR: 'La quantité commandée est limitée à {AMOUNT} unités par client.',
				},
				ExceptionTitle: {
					DE: 'Bildungseinrichtungen',
					EN: 'Educational Institutions',
				},
				Exception: {
					DE: 'Ausgenommen von dieser Regelung sind Bildungseinrichtungen.',
					EN: 'Educational institutions are excluded from this regulation.',
					FR: "Les établissements d'enseignement sont exclus de ce règlement.",
				},
				CallToAction: {
					DE: 'Sie bestellen für eine Bildungsrichtung und benötigen eine größere Menge? Kontaktieren Sie uns per E-Mail, Telefon oder unser Kontaktformular.',
					EN: 'You are ordering for an educational institution and need a larger quantity? Contact us by e-mail, phone or our contact form.',
					FR: "Vous commandez pour un établissement d'enseignement et avez besoin d'une quantité plus importante ? Contactez-nous par e-mail, par téléphone ou par notre formulaire de contact.",
				},
			},
			Links: {
				Heading: {
					DE: 'Weiterführende Informationen',
					EN: 'Further Information',
				},
				Stem: {
					Teaser: {
						DE: 'Sie möchten mehr über MINT und die angebotenen Lernkonzepte erfahren?',
						FR: 'You like to learn more about STEM and the learning concepts offered?',
					},
					Link: {
						DE: 'Besuchen Sie unsere Übersichtsseite',
						EN: 'Visit our STEM page',
					},
				},
			},
		},
	},
};
