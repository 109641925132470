import PropTypes from 'prop-types';
import React from 'react';
import { Col, Grid, Image, Navbar, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { ResponsiveRender } from '@esnpm/react-toolbox';
import SideBarCategorySelectContainer from '../../../../../esshop2_frontend/src/js/components/SideBarCategorySelectContainer';
import { compose } from 'redux';
import CartPreview from '../../../../../esshop2_frontend/src/js/components/CartPreview';
import { replaceableComponents } from '../../../../../esshop2_frontend/src/js/lib/ReplaceableComponents';
import TopNavBar from './TopNavBar';
import { TagNavHorizontal } from '../../../../../esshop2_frontend/src/js/shared/components/TagNavigation/TagNavHorizontal';

const { SearchBar } = replaceableComponents({
	SearchBar: require('./SearchBar').default,
});

class Header extends React.Component {
	static propTypes = {
		app: PropTypes.any,
	};

	render() {
		return (
			<React.Fragment>
				<TopNavBar />
				<Grid className={'HeaderContainer'}>
					<Row>
						<Col xs={3} sm={2} md={1} lg={2}>
							<Link to={'/'} className={'Logo'}>
								<Image responsive src={'/images/header/logo.png'} />
							</Link>
						</Col>
						<Col xs={9} sm={10} md={11} lg={10}>
							<Row>
								<Col xs={12} sm={9} md={12}>
									<SearchBar />
								</Col>
								<ResponsiveRender sm>
									<Col sm={3}>
										<CartPreview />
									</Col>
								</ResponsiveRender>
							</Row>
						</Col>
						<Col xs={12} sm={12} lg={10}>
							<Navbar className={'HeaderMenu'} style={{ position: 'relative' }}>
								{/*<HeaderCategories catPath={'1.2'} />*/}
								<TagNavHorizontal />
								<ResponsiveRender md lg>
									<CartPreview />
								</ResponsiveRender>
							</Navbar>
						</Col>
						<ResponsiveRender xs>
							<Col xs={12}>
								<SideBarCategorySelectContainer
									cat={this.props.app.sidebarCategory.catPath}
								/>
							</Col>
						</ResponsiveRender>
					</Row>
				</Grid>
			</React.Fragment>
		);
	}
}

export default compose(
	withRouter,
	connect((state) => ({
		currentSession: state.currentSession,
		app: state.app,
	}))
)(Header);
