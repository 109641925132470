import axios from "axios/index";
import {getAPIUrl} from "@esnpm/node-toolbox";
import Promise from "bluebird";
export const API_SESSION_PAYMENT_METHOD_OVERVIEW_REQUEST = "API_SESSION_PAYMENT_METHOD_OVERVIEW_REQUEST";
export const API_SESSION_PAYMENT_METHOD_OVERVIEW_SUCCESS = "API_SESSION_PAYMENT_METHOD_OVERVIEW_SUCCESS";
export const API_SESSION_PAYMENT_METHOD_OVERVIEW_FAILURE = "API_SESSION_PAYMENT_METHOD_OVERVIEW_FAILURE";

export const api_session_payment_method_overview_request = (options) => {
	return {
		type: API_SESSION_PAYMENT_METHOD_OVERVIEW_REQUEST,
		options: options,
		payload: {
			loading: true
		}
	};
};

export const api_session_payment_method_overview_success = (data) => {
	return {
		type: API_SESSION_PAYMENT_METHOD_OVERVIEW_SUCCESS,
		payload: {
			loading: false,
			data: data,
			error: null
		}
	};
};

export const api_session_payment_method_overview_failure = (error) => {
	return {
		type: API_SESSION_PAYMENT_METHOD_OVERVIEW_FAILURE,
		payload: {
			loading: false,
			error: error
		}
	};
};

/*
	Session.apiSessionPaymentMethodOverview
	Liefert Übersicht aller, für den Kunden verfügbaren PaymentMethods zurück
			:return:
*/
export const apiSessionPaymentMethodOverview = (options) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(api_session_payment_method_overview_request(options));
			const url=getAPIUrl([
				{"url": "/api/session/payment_method/overview", "parameters": []},
			], options);
			axios({
				method: "get",
				url: url,
			}).then(
				(response) => {
					if (response.data.SUCCESSFUL) {
						dispatch(api_session_payment_method_overview_success(response.data.DATA));
						resolve();
					} else {
						dispatch(api_session_payment_method_overview_failure(response.data));
						resolve();
					}
				},
				(err) => {
					// eslint-disable-next-line no-console
					console.log(err);
					reject();
				}
			);
		}
	);
