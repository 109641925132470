import React from "react";
import {Route} from "react-router-dom";

const module = (shopApp) => {
	const ContactActivate = shopApp.dynamic(() => import("./ContactActivate"));

	const url = "/contact_activate";

	let config = {
		name: "ContactActivate",
		routes: [
			<Route key={url} exact path={url} component={ContactActivate}/>
		]
	};

	shopApp.registerPage(config);

};

export default module;
