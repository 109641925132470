import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {applyMiddleware, compose, createStore} from "redux";
import {Provider} from "react-redux";
import thunk from "redux-thunk";
import history from "../lib/history";
import {ConnectedRouter, connectRouter, routerMiddleware} from "connected-react-router";
import {composeWithDevTools} from "redux-devtools-extension/logOnlyInProduction";
import {TrProvider} from "@esnpm/translate";
import {setCdsBaseUrl, setHeadBangerAdditionalTitle} from "@esnpm/react-toolbox";
import {isProduction} from "../lib/isProduction";

// Headbanger additional Title
setHeadBangerAdditionalTitle(window._es_env.app.additionalTitle);
setCdsBaseUrl("/cds");

const composeEnhancers = isProduction() ? compose() : composeWithDevTools({});

const newStore = (rootReducer) => {
	return createStore(
		connectRouter(history)(rootReducer), // new root reducer with router state
		{},
		composeEnhancers(
			applyMiddleware(
				routerMiddleware(history), // for dispatching history actions
				thunk,
			),
		),
	);
};

class BaseApp extends Component {
	static defaultProps = {
		activeLanguageContainer: <Fragment/>
	};

	static propTypes = {
		activeLanguageCallback: PropTypes.func,
		activeLanguageContainer: PropTypes.node,
		children: PropTypes.any,
		rootReducer: PropTypes.func.isRequired
	};

	constructor(props) {
		super(props);
		this.store = newStore(props.rootReducer);
	}

	render() {
		const availableLanguages = ["EN", "DE", "FR"];
		return (
			<Provider store={this.store}>
				<ConnectedRouter history={history}>
					{React.cloneElement(this.props.activeLanguageContainer,
						{availableLanguages: availableLanguages}, this.renderContent(availableLanguages))}
				</ConnectedRouter>
			</Provider>
		);
	}

	renderContent(availableLanguages) {
		return (
			<TrProvider
				activeLanguage={"EN"}
				availableLanguages={availableLanguages}
			>
				{this.props.children}
			</TrProvider>
		);
	}
}

export default compose(
)(BaseApp);
