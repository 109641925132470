import update from "immutability-helper";
import {API_NEWSLETTER_REGISTER_POST_FAILURE, API_NEWSLETTER_REGISTER_POST_REQUEST, API_NEWSLETTER_REGISTER_POST_SUCCESS} from "../../actions/esshop2/apiNewsletterRegister_post";

const initialState = {
	places: [],
	result: {
		loading: false,
		data: null,
		error: null,
	},
};

export const AppNewsletterRegisterReducer = (state = initialState, action) => {
	let newState = null;
	let place = null;

	switch (action.type) {
		case API_NEWSLETTER_REGISTER_POST_SUCCESS:
		case API_NEWSLETTER_REGISTER_POST_FAILURE:
		case API_NEWSLETTER_REGISTER_POST_REQUEST:
			newState = update(state, {
				result: {
					$set: Object.assign({}, state.result, action.payload),
				},
			});
			if (action.options != null) {
				place = action.options.place;
				let newPlaces = [];
				state.places.map((v) => {
					newPlaces.push(v);
				});
				if (newPlaces.indexOf(place) === -1) {
					newPlaces.push(place);
				}
				newState = update(state, {
					places: {
						$set: newPlaces,
					},
				});
			}
			return newState;

		default:
			return state;
	}
};
