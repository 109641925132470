import update from "immutability-helper";

import {
	API_AUTH_CURRENT_SESSION_FAILURE, API_AUTH_CURRENT_SESSION_REQUEST,
	API_AUTH_CURRENT_SESSION_SUCCESS
} from "@esnpm/eswebapp";
import {
	API_SESSION_SET_ACTIVE_LANGUAGE_POST_REQUEST,
	API_SESSION_SET_ACTIVE_LANGUAGE_POST_SUCCESS
} from "../actions/esshop2/apiSessionSetActiveLanguage_post";

import {
	API_SESSION_SET_CUSTOMER_DATA_POST_REQUEST,
	API_SESSION_SET_CUSTOMER_DATA_POST_SUCCESS
} from "../actions/esshop2/apiSessionSetCustomerData_post";
import {
	API_ADD_POSITION_TO_CART_POST_REQUEST,
	API_ADD_POSITION_TO_CART_POST_SUCCESS
} from "../actions/esshop2/apiAddPositionToCart_post";
import {
	API_SESSION_CART_CHANGE_ADDRESS_POST_REQUEST,
	API_SESSION_CART_CHANGE_ADDRESS_POST_SUCCESS
} from "../actions/esshop2/apiSessionCartChangeAddress_post";
import {
	API_SESSION_CART_REMOVE_ADDRESS_POST_REQUEST,
	API_SESSION_CART_REMOVE_ADDRESS_POST_SUCCESS
} from "../actions/esshop2/apiSessionCartRemoveAddress_post";
import {
	API_SESSOIN_VAT_ID_UPDATE_POST_REQUEST,
	API_SESSOIN_VAT_ID_UPDATE_POST_SUCCESS
} from "../actions/esshop2/apiSessoinVatIdUpdate_post";
import {
	API_SESSION_SHIPPING_METHOD_UPDATE_POST_REQUEST,
	API_SESSION_SHIPPING_METHOD_UPDATE_POST_SUCCESS
} from "../actions/esshop2/apiSessionShippingMethodUpdate_post";
import {
	API_SESSION_PAYMENT_METHOD_UPDATE_POST_REQUEST,
	API_SESSION_PAYMENT_METHOD_UPDATE_POST_SUCCESS
} from "../actions/esshop2/apiSessionPaymentMethodUpdate_post";
import {
	API_SESSION_CUSTOMER_COMMENT_UPDATE_POST_REQUEST,
	API_SESSION_CUSTOMER_COMMENT_UPDATE_POST_SUCCESS
} from "../actions/esshop2/apiSessionCustomerCommentUpdate_post";
import {
	API_SESSION_CUSTOMER_ORDER_NUMBER_UPDATE_POST_REQUEST,
	API_SESSION_CUSTOMER_ORDER_NUMBER_UPDATE_POST_SUCCESS
} from "../actions/esshop2/apiSessionCustomerOrderNumberUpdate_post";
import {
	API_SESSION_EMAIL_UPDATE_POST_REQUEST,
	API_SESSION_EMAIL_UPDATE_POST_SUCCESS
} from "../actions/esshop2/apiSessionEmailUpdate_post";
import {API_SESSION_DATA_CLEAR_REQUEST, API_SESSION_DATA_CLEAR_SUCCESS} from "../actions/esshop2/apiSessionDataClear";
import {
	API_SESSION_UPDATE_TOTAL_REQUEST,
	API_SESSION_UPDATE_TOTAL_SUCCESS
} from "../actions/esshop2/apiSessionUpdateTotal";
import {
	API_SESSION_FLAGS_REMOVE_POST_REQUEST,
	API_SESSION_FLAGS_REMOVE_POST_SUCCESS
} from "../actions/esshop2/apiSessionFlagsRemove_post";
import {
	API_SESSION_FLAGS_ADD_POST_REQUEST,
	API_SESSION_FLAGS_ADD_POST_SUCCESS
} from "../actions/esshop2/apiSessionFlagsAdd_post";
import {
	API_SESSION_PAYMENT_CHECKOUT_POST_REQUEST,
	API_SESSION_PAYMENT_CHECKOUT_POST_SUCCESS
} from "../actions/esshop2/apiSessionPaymentCheckout_post";
import {
	API_SESSION_PAYMENT_CHECKOUT_CANCEL_REQUEST,
	API_SESSION_PAYMENT_CHECKOUT_CANCEL_SUCCESS
} from "../actions/esshop2/apiSessionPaymentCheckoutCancel";
import {
	API_SESSION_PAYMENT_CHECKOUT_SUCCESS_REQUEST,
	API_SESSION_PAYMENT_CHECKOUT_SUCCESS_SUCCESS
} from "../actions/esshop2/apiSessionPaymentCheckoutSuccess";
import {
	API_SESSION_PAYMENT_CLAIM_AND_FINISH_REQUEST,
	API_SESSION_PAYMENT_CLAIM_AND_FINISH_SUCCESS
} from "../actions/esshop2/apiSessionPaymentClaimAndFinish";
import {API_SESSION_CART_ADOPT_POSITIONS_FROM_ORDER_POST_SUCCESS} from "../actions/esshop2/apiSessionCartAdoptPositionsFromOrder_post";
import {
	API_CONTACT_ACTIVATE_POST_REQUEST,
	API_CONTACT_ACTIVATE_POST_SUCCESS
} from "../actions/esshop2/apiContactActivate_post";
import {API_SESSION_CART_SORT_ADDRESSES_SUCCESS} from "../actions/esshop2/apiSessionCartSortAddresses";
import {
	API_SESSION_SET_LANGUAGE_CHANGED_POST_REQUEST,
	API_SESSION_SET_LANGUAGE_CHANGED_POST_SUCCESS
} from "../actions/esshop2/apiSessionSetLanguageChanged_post";
import {API_SESSION_LOGOUT_REQUEST, API_SESSION_LOGOUT_SUCCESS} from "../actions/esshop2/apiSessionLogout";

import {setShopPayload} from "../lib/setShopPayload";
import cookie from "react-cookies";
import {
	API_REMOVE_POSITION_FROM_CART_POST_REQUEST,
	API_REMOVE_POSITION_FROM_CART_POST_SUCCESS
} from "../actions/esshop2/apiRemovePositionFromCart_post";

const insert_CART_TOTAL_WITHOUT_TAX = (session) => {
	if (session.CART == null) return session;
	let newSession = update(session, {
		CART: {
			$merge: {
				TOTAL_WITHOUT_TAX: parseFloat((session.CART.TOTAL - session.CART.TOTAL_TAX).toFixed(2))
			}
		}
	});
	return newSession;
};


const initialState = {
	loading: false,
	data: null,
	error: null,
};

export const SessionReducer = (state = initialState, action) => {
	let newPayload;

	switch (action.type) {

		case API_SESSION_SET_ACTIVE_LANGUAGE_POST_REQUEST:
		case API_SESSION_SET_CUSTOMER_DATA_POST_REQUEST:
		case API_ADD_POSITION_TO_CART_POST_REQUEST:
		case API_REMOVE_POSITION_FROM_CART_POST_REQUEST:
		case API_SESSION_CART_CHANGE_ADDRESS_POST_REQUEST:
		case API_SESSION_CART_REMOVE_ADDRESS_POST_REQUEST:
		case API_SESSOIN_VAT_ID_UPDATE_POST_REQUEST:
		case API_SESSION_SHIPPING_METHOD_UPDATE_POST_REQUEST:
		case API_SESSION_PAYMENT_METHOD_UPDATE_POST_REQUEST:
		case API_SESSION_CUSTOMER_COMMENT_UPDATE_POST_REQUEST:
		case API_SESSION_CUSTOMER_ORDER_NUMBER_UPDATE_POST_REQUEST:
		case API_SESSION_EMAIL_UPDATE_POST_REQUEST:
		case API_SESSION_DATA_CLEAR_REQUEST:
		case API_SESSION_UPDATE_TOTAL_REQUEST:
		case API_SESSION_FLAGS_REMOVE_POST_REQUEST:
		case API_SESSION_FLAGS_ADD_POST_REQUEST:
		case API_SESSION_PAYMENT_CHECKOUT_POST_REQUEST:
		case API_SESSION_PAYMENT_CHECKOUT_CANCEL_REQUEST:
		case API_SESSION_PAYMENT_CHECKOUT_SUCCESS_REQUEST:
		case API_SESSION_PAYMENT_CLAIM_AND_FINISH_REQUEST:
		case API_CONTACT_ACTIVATE_POST_REQUEST:
		case API_SESSION_SET_LANGUAGE_CHANGED_POST_REQUEST:
		case API_SESSION_LOGOUT_REQUEST:
		case API_SESSION_LOGOUT_SUCCESS:
		case API_SESSION_SET_LANGUAGE_CHANGED_POST_SUCCESS:
		case API_SESSION_SET_ACTIVE_LANGUAGE_POST_SUCCESS:
		case API_SESSION_SET_CUSTOMER_DATA_POST_SUCCESS:
		case API_ADD_POSITION_TO_CART_POST_SUCCESS:
		case API_REMOVE_POSITION_FROM_CART_POST_SUCCESS:
		case API_SESSION_CART_CHANGE_ADDRESS_POST_SUCCESS:
		case API_SESSION_CART_REMOVE_ADDRESS_POST_SUCCESS:
		case API_SESSOIN_VAT_ID_UPDATE_POST_SUCCESS:
		case API_SESSION_SHIPPING_METHOD_UPDATE_POST_SUCCESS:
		case API_SESSION_PAYMENT_METHOD_UPDATE_POST_SUCCESS:
		case API_SESSION_CUSTOMER_COMMENT_UPDATE_POST_SUCCESS:
		case API_SESSION_CUSTOMER_ORDER_NUMBER_UPDATE_POST_SUCCESS:
		case API_SESSION_EMAIL_UPDATE_POST_SUCCESS:
		case API_SESSION_DATA_CLEAR_SUCCESS:
		case API_SESSION_UPDATE_TOTAL_SUCCESS:
		case API_SESSION_FLAGS_REMOVE_POST_SUCCESS:
		case API_SESSION_FLAGS_ADD_POST_SUCCESS:
		case API_CONTACT_ACTIVATE_POST_SUCCESS:
		case API_SESSION_CART_SORT_ADDRESSES_SUCCESS:
		// Overwrite react-eswebapp->authCurrentSessionReducer - Start
		// eslint-disable-next-line no-fallthrough
		case API_AUTH_CURRENT_SESSION_REQUEST:
		case API_AUTH_CURRENT_SESSION_FAILURE:
		case API_AUTH_CURRENT_SESSION_SUCCESS:
			// Overwrite react-eswebapp->authCurrentSessionReducer - End
			newPayload = (action.payload.data == null) ? action.payload : Object.assign({}, action.payload, {
				data: insert_CART_TOTAL_WITHOUT_TAX(action.payload.data)
			});

			setShopPayload({
				CLIENT_ID: cookie.load("clientId")
			});

			return Object.assign({}, state, newPayload);

		case API_SESSION_PAYMENT_CHECKOUT_POST_SUCCESS:
		case API_SESSION_PAYMENT_CHECKOUT_CANCEL_SUCCESS:
		case API_SESSION_PAYMENT_CHECKOUT_SUCCESS_SUCCESS:
		case API_SESSION_PAYMENT_CLAIM_AND_FINISH_SUCCESS:
		case API_SESSION_CART_ADOPT_POSITIONS_FROM_ORDER_POST_SUCCESS:

			newPayload = (action.payload.data == null) ? action.payload : Object.assign({}, action.payload, {
				data: insert_CART_TOTAL_WITHOUT_TAX(action.payload.data.SESSION)
			});

			return Object.assign({}, state, newPayload);

		default:
			return state;
	}
};

