import React from "react";
import dynamic from "next/dynamic";
import _ from "lodash";

export const withReplacedComponents = (components) =>
	(WrappedComponent) =>
		// eslint-disable-next-line react/display-name
		props => {
			// eslint-disable-next-line react/react-in-jsx-scope,react/prop-types
			return <WrappedComponent {...props} _rc={{...props._rc, ...components}}/>;
		};

export const withReplaceableComponents = (components) => (WrappedComponent) => {
	return class NewComponent extends WrappedComponent {
		_rc() {
			return Object.assign({}, components, this.props._rc);
		}
	};
};


export const registerReplaceableComponents = (components) => {
	if (document._replaceableComponentsRegistry == null) {
		document._replaceableComponentsRegistry = {};
	}
	document._replaceableComponentsRegistry = _.merge(
		{},
		document._replaceableComponentsRegistry,
		components
	);
};

export const replaceableComponent = (componentName, component) => {
	// console.log(componentName, document._replaceableComponentsRegistry[componentName]);
	if (document._replaceableComponentsRegistry == null) return component;
	const rc = document._replaceableComponentsRegistry[componentName];
	return (rc != null) ? rc : dynamic(() => component);
	// return component;
};

export const replaceableComponents = (components) => {
	if (document._replaceableComponentsRegistry == null) return components;
	return {...components, ...document._replaceableComponentsRegistry};
};
