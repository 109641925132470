import {API_SESSION_PAYMENT_METHOD_OVERVIEW_SUCCESS} from "../../actions/esshop2/apiSessionPaymentMethodOverview";
import update from "immutability-helper";

const initialState = {
	data: null,
};

export const AppPaymentMethodOverviewReducer = (state = initialState, action) => {
	switch (action.type) {
		case API_SESSION_PAYMENT_METHOD_OVERVIEW_SUCCESS:
			return update(state, {
				$set: action.payload,
			});


		default:
			return state;
	}
};
