import React from "react";
import {Route} from "react-router-dom";


const module = (shopApp) => {
	const LegoEducationSupport = shopApp.dynamic(() => import("./LegoEducationSupport"));

	const url = "/le_support";

	let config = {
		name: "LegoEducationSupport",
		routes: [
			<Route key={url} exact path={url} component={LegoEducationSupport}/>
		]
	};

	shopApp.registerPage(config);

};

export default module;
