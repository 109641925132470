import _ from "lodash";
import {API_SHOP_TRANSACTION_BY_ORDER_NUMBER_REQUEST, API_SHOP_TRANSACTION_BY_ORDER_NUMBER_SUCCESS} from "../actions/esshop2/apiShopTransactionByOrderNumber";


const initialState = {};

export const ShopOrdersReducer = (state = initialState, action) => {

	switch (action.type) {
		case API_SHOP_TRANSACTION_BY_ORDER_NUMBER_REQUEST:
			return _.merge({}, state, {[action.options.ORDER_NUMBER]: action.payload});

		case API_SHOP_TRANSACTION_BY_ORDER_NUMBER_SUCCESS:
			return _.mergeWith(
				{},
				state,
				{
					[action.payload.data.ORDERS[0].ORDER_NUMBER]: {
						data: {
							KB: [action.payload.data.FULL_TR_NUMBER]
						}
					}
				},
				(objValue, srcValue) => {
					if (_.isArray(objValue)) {
						return objValue.concat(srcValue);
					}
				}
			);

		default:
			return state;
	}
};
