import { action_once } from '@esnpm/node-toolbox';
import PropTypes from 'prop-types';
import React from 'react';
import { apiCategory } from '@esnpm/esdb';
import { compose } from 'redux';
import { throw_toast_alert } from '../lib/throw_toast_alert';
import _ from 'lodash';

export function parentCategory(catPath) {
	return catPath.split('.').slice(0, -1).join('.');
}

export function checkCategoryDataExists(state, catPath, children) {
	let cat = state.categories.data[catPath];

	// Category gar nicht geladen
	if (!cat) return false;

	// Category geladen und Children nicht benötigt -> alles gut
	if (_.isNil(children) || children === 0) return true;

	// Hat keine Children
	if (!cat.HAS_CHILDREN) return true;

	// Hat Children aber sind nicht geladen
	if (cat.CHILDREN.length === 0) return false;

	// Hat Children und sind geladen -> alles gut
	return true;
}

export function checkCategoryHasChildren(state, catPath) {
	if (_.isNil(state.categories.data[catPath])) return false;
	return !(state.categories.data[catPath].CHILDREN.length === 0);
}

export function checkSidebarCategoryLoaded(state, catPath) {
	if (_.isNil(state.app.sidebarCategory.catPath)) return false;
	return state.app.sidebarCategory.catPath === catPath;
}

export function checkSidebarCategoryHighlighted(state, catPath) {
	if (_.isNil(state.app.sidebarCategory.highlight)) return false;
	return state.app.sidebarCategory.highlight === catPath;
}

class Categories extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			initialized: false,
		};
	}

	static defaultProps = {
		catPath: '1.2',
		children: 1,
	};

	static propTypes = {
		catPath: PropTypes.string.isRequired,
		children: PropTypes.number.isRequire,
	};

	componentWillMount() {
		this.loadCategoryData();
	}

	loadCategoryData() {
		this.props
			.dispatch(
				action_once(
					(state) => !state.categories[this.props.catPath],
					apiCategory({
						CATEGORY_PATH: this.props.catPath,
						CHILDREN: this.props.children,
					})
				)
			)
			.then(() => {
				this.setState({ initialized: true });
			})
			.catch((e) => {
				throw_toast_alert({
					exception: new Error('Category not found'),
					msg: 'An error occured. Categories could not be loaded',
					variant: 'error',
				});
			});
	}
}

export default compose()(Categories);
