import React from "react";
import {Route} from "react-router-dom";

const module = (shopApp) => {
	const Category = shopApp.dynamic(() => import("./Category"));
	const url = "/category/:catPath";

	let config = {
		name: "Category",
		routes: [
			<Route key={url} exact path={url} component={Category}/>
		]
	};

	shopApp.registerPage(config);
};

export default module;
