import {API_SESSION_CART_PRODUCTS_HAS_NO_SALE_SUCCESS} from "../../actions/esshop2/apiSessionCartProductsHasNoSale";
import update from "immutability-helper";

const initialState = {
	hasNoSale: null,
};

export const AppCartProductsReducer = (state = initialState, action) => {
	switch (action.type) {
		case API_SESSION_CART_PRODUCTS_HAS_NO_SALE_SUCCESS:
			return update(state, {
				hasNoSale: {
					$set: action.payload.data.HAS_NO_SALE,
				},
			});
		default:
			return state;
	}
};
