export const trmNewsletter = {
	pathesToCheck: ['newsletter'],
	tr: {
		newsletter: {
			Toggle: {
				On: {
					DE: 'Abonnieren',
					EN: 'Subscribe',
					FR: "S'abonner",
				},
				Off: {
					DE: 'Nicht abonnieren',
					EN: 'Do not subscribe',
					FR: "Ne pas s'abonner",
				},
			},
			Tooltip: {
				DE: 'Wir versenden regelmäßig einen Newsletter mit interessanten Produkten, Angeboten und Neuigkeiten. Setzen Sie den Haken, wenn Sie Ihn erhalten möchten.',
				EN: 'Periodically we send a newsletter to our customers, containg interesting products, recent offers and news from the electronic market. If you want to receive this newsletter, please check the box on the left.',
				FR: 'Nous envoyons régulièrement une newsletter avec nos produits intéressants, nos offres et nos nouveautés. Cochez la case si vous souhaitez la recevoir',
			},
			EMailAlreadyExistsException: {
				DE: 'Diese E-Mail-Addresse ist schon für unseren Newsletter registriert.',
				EN: 'This e-mail address is already registered for our newsletter.',
				FR: 'Cette adresse e-mail est déjà inscrite à notre newsletter.',
			},
			Success: {
				DE: 'Vielen Dank für Ihr Interesse. Bitte bestätigen Sie Ihre Registrierung, indem Sie auf den Link in der Ihnen zugesendeten E-Mail klicken.',
				EN: 'Thank you for your interest. Please confirm your registration by clicking on the link in the email we sent you.',
				FR: 'Nous vous remercions pour votre intérêt. Veuillez confirmer votre inscription en cliquant sur le lien dans l\'e-mail que nous vous avons envoyé.',
			},
		},
	},
};
