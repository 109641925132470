import React from "react";
import {Route} from "react-router-dom";

const module = (shopApp) => {
	const Revocation = shopApp.dynamic(() => import("./Revocation"));

	const url = "/revocation";

	let config = {
		name: "Revocation",
		routes: [
			<Route key={url} exact path={url} component={Revocation}/>
		]
	};

	shopApp.registerPage(config);

};

export default module;
