import React from "react";
import {compose} from "redux";
import {AppShowLoginForm} from "../actions/App/AppRegistration";
import Row from "react-bootstrap/es/Row";
import Col from "react-bootstrap/es/Col";
import {Tr} from "@esnpm/translate";
import Button from "react-bootstrap/es/Button";
import {connect} from "react-redux";

class BackToLoginForm extends React.Component {

	static propTypes = {};

	render() {
		return (
			<Row>
				<Col xs={12}>
					<Button
						onClick={() => this.props.dispatch(AppShowLoginForm(true))}
						bsSize={"sm"}
						bsStyle={"link"}
					>
						<Tr path={"loginDialog.BackToLogin"}/>
					</Button>
				</Col>
			</Row>
		);
	}
}


export default compose(
	connect()
)(BackToLoginForm);
