import React from "react";
import {Route} from "react-router-dom";

const module = (shopApp) => {
	const PrivacyPolicy = shopApp.dynamic(() => import("./PrivacyPolicy"));

	const url = "/privacy";

	let config = {
		name: "PrivacyPolicy",
		routes: [
			<Route key={url} exact path={url} component={PrivacyPolicy}/>
		]
	};

	shopApp.registerPage(config);

};

export default module;
