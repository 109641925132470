import React from "react";
import {Route} from "react-router-dom";


const module = (shopApp) => {
	const Manufacturers = shopApp.dynamic(() => import("./Manufacturers"));

	const url = "/manufacturers";

	let config = {
		name: "Manufacturers",
		routes: [
			<Route key={url} exact path={url} component={Manufacturers}/>
		]
	};

	shopApp.registerPage(config);

};

export default module;
