import PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";
import {Button, Col, Row} from "react-bootstrap";
import {ESForm} from "@esnpm/forms";
import {Tr} from "@esnpm/translate";
import {apiContactResetPasswordRequest_post} from "../actions/esshop2/apiContactResetPasswordRequest_post";
import {compose} from "redux";
import UserResetPasswordSuccessLabel from "./UserResetPasswordSuccessLabel";
import BackToLoginForm from "./BackToLoginForm";


class ForgotPasswordForm extends React.Component {
	static propTypes = {
		app: PropTypes.any
	};

	constructor(props) {
		super(props);
		this.state = {
			button_disable: true
		};
	}

	handleForgotPasswordAction() {
		const form = this.forgotPasswordForm.getWrappedInstance();
		let data = form.value();
		this.props.dispatch(apiContactResetPasswordRequest_post({
			"EMAIL": data["EMAIL"]
		})).then(() => {
			form.resetFields();
		});
	}

	handleForgotPasswordButton() {
		this.handleForgotPasswordAction();
	}

	handleFormValidChange(valid) {
		this.setState({button_disable: !valid});
	}


	render() {
		const {app} = this.props;
		if (!app.registration.show_forgot_password_form) return null;
		const renderFormAfterSucces = ((this.props.app.registration.reset_pw_request.result.data != null) && (this.props.app.registration.reset_pw_request.result.data.SUCCESSFUL));
		return (
			<React.Fragment>
				<UserResetPasswordSuccessLabel/>
				{((!renderFormAfterSucces) ?
					<ESForm
						onValidChange={this.handleFormValidChange.bind(this)}
						horizontal
						ref={c => {
							this.forgotPasswordForm = c;
						}}
						classValid={"ESFormFieldValid"}
						onEnter={() => this.handleForgotPasswordAction()}
					>
						<ESForm.Email/>
					</ESForm> : null)}
				<BackToLoginForm/>
				{((!renderFormAfterSucces) ?
					<Row>
						<Col xs={12}>
							<Button
								className={"ForgotPasswordButton pull-right"}
								onClick={this.handleForgotPasswordButton.bind(this)}
								disabled={this.state.button_disable}
								bsStyle={"primary"}
							>
								<Tr path={"buttons.PasswordRequest"}/>
							</Button>
						</Col>
					</Row> : null)}
			</React.Fragment>
		);
	}
}

export default compose(
	connect(
		(state) => (
			{
				app: {
					registration: state.app.registration,
				}
			}
		)
	)
)(ForgotPasswordForm);

