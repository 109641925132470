import PropTypes from "prop-types";
import {connect} from "react-redux";
import React from "react";
import {compose} from "redux";
import VatInfoFooter from "../../../../../esshop2_frontend/src/js/components/VatInfoFooter";
import Col from "react-bootstrap/es/Col";
import Row from "react-bootstrap/es/Row";
import {Tr} from "@esnpm/translate";

class FooterMetaInfo extends React.Component {
	static propTypes = {
		appInfo: PropTypes.any
	};

	render() {
		return (
			<Row className={"FooterMetaInfo"}>
				<Col xs={12}>
					<VatInfoFooter/>
					<Tr path={"footer.FooterMetaInfo.Version"}/>: {this.props.appInfo.VERSION}
				</Col>
			</Row>
		);
	}
}

export default compose(
	connect(
		(state) => (
			{
				appInfo: state.appInfo
			}
		)
	)
)(FooterMetaInfo);
