import {API_NEWSLETTER_OPT_OUT_FAILURE, API_NEWSLETTER_OPT_OUT_REQUEST, API_NEWSLETTER_OPT_OUT_SUCCESS} from "../../actions/esshop2/apiNewsletterOptOut";
import update from "immutability-helper";

const initialState = {
	result: {
		loading: false,
		data: null,
		error: null,
	},
};

export const AppNewsletterOptOutReducer = (state = initialState, action) => {
	switch (action.type) {
		case API_NEWSLETTER_OPT_OUT_FAILURE:
		case API_NEWSLETTER_OPT_OUT_SUCCESS:
		case API_NEWSLETTER_OPT_OUT_REQUEST:
			return update(state, {
				result: {
					$set: Object.assign({}, state.result, action.payload),
				},
			});
		default:
			return state;
	}
};
