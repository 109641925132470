export const trmCustomer = {
	pathesToCheck: ["customer"],
	tr: {
		customer: {
			Base: {
				Firstname: {
					DE: "Vorname",
					EN: "Firstname",
					FR: "Prénom",
				},
				Lastname: {
					DE: "Nachname",
					EN: "Lastname",
					FR: "Nom",
				},
				Street: {
					DE: "Straße",
					EN: "Street",
					FR: "Rue",
				},
				City: {
					DE: "Ort",
					EN: "City",
					FR: "Ville",
				},
				Zip: {
					DE: "PLZ",
					EN: "Zip",
					FR: "CP",
				},
				Country: {
					DE: "Land",
					EN: "Country",
					FR: "Pays",
				},
			},
			Company: {
				Base: {
					DE: "Firma",
					EN: "Company",
					FR: "Entreprise",
				},
				Department: {
					DE: "Abteilung",
					EN: "Department",
					FR: "Département",
				},
				VatID: {
					Base: {
						DE: "USt-IdNr.",
						EN: "VAT ID",
						FR: "NIF",
					},
					Alert: {
						Note: {
							DE: "Um Ihre Bestellung als Firmenkunde abzuschließen, geben Sie bitte Ihre gültige Umsatzsteuer-Identifikationsnummer in das dafür vorgesehen Feld ein.",
							EN: "To complete your order as a business customer, please enter your valid VAT identification number in the appropriate field.",
							FR: "Pour compléter votre commande en tant que client commercial, veuillez saisir votre NIF valide dans le champ approprié.",
						},
						Error: {
							DE: "Die von Ihnen eingegebenen Umsatzsteuer-Identifikationsnummer enspricht nicht dem erforderlichem Format.",
							EN: "The provided VAT identification number does not correspond to the required format.",
							FR: "Le NIF fourni ne correspond pas au format requis.",
						}
					}
				}
			},
			Contact: {
				PhoneNumber: {
					DE: "Telefonnummer",
					EN: "Phone number",
					FR: "Téléphone",
				},
				EmailAddress: {
					DE: "E-Mail-Adresse",
					EN: "Email Address",
					FR: "Adresse e-mail",
				},
			},
			Status: {
				LoggedIn: {
					Headline: {},
					Content: {}
				},
				NotLoggedIn: {
					Headline: {
						FR: "Vous n'êtes pas connecté.",
						DE: "Sie sind nicht eingeloggt.",
						EN: "You are not logged in.",
					},
					Content: {
						FR: "Veuillez vous connecter afin d'atteindre la page souhaitée.",
						DE: "Bitten loggen Sie sich ein, um die angeforderte Seite darzustellen.",
						EN: "To view the requested page, you must be logged in.",
					}
				}
			}
		}
	}
};
