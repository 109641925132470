import {compose} from "redux";
import {connect} from "react-redux";
import {connectMerger} from "@esnpm/node-toolbox";

export const withAppRegistration = (WrappedComponent) => {
	return compose(
		connect((state) => ({
			app: {
				registration: state.app.registration
			}

		}), null, connectMerger)
	)(WrappedComponent);
};

