import {APP_SET_SIDEBAR_CATEGORY_REQUEST, APP_SET_SIDEBAR_CATEGORY_SUCCESS, APP_SET_SIDEBAR_HIGHLIGHTED_SUCCESS} from "../../actions/App/AppSidebar";

const initialState = {
	loading: true,
	catPath: null,
	highlight: null,
};

export const AppSidebarReducer = (state = initialState, action) => {
	switch (action.type) {
		case APP_SET_SIDEBAR_CATEGORY_REQUEST:
		case APP_SET_SIDEBAR_CATEGORY_SUCCESS:
		case APP_SET_SIDEBAR_HIGHLIGHTED_SUCCESS:
			return Object.assign({}, state, action.payload);

		default:
			return state;
	}
};
