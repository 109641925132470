import React, { useRef, useState } from 'react';
import {
	MenuComponents,
	MenuComputerAndNetwork,
	MenuEmbedded,
	MenuEnergy,
	MenuLabAndWorkshop,
	MenuLegoEducationSeries,
	MenuLegoSeries,
	MenuRobotics,
} from '../../constants/MenuEntries';
import { TagNavHorizontalPortalPlacer } from './TagNavHorizontalPortal';
import { TagNavHorizontalPortalButton } from './TagNavHorizontalPortalButton';
import TagMenuHorizontal from '../TagMenu/TagMenuHorizontal';

export const TagNavHorizontalContext = React.createContext(null);
TagNavHorizontalContext.displayName = 'TagNavHorizontalContext';

export const TagNavHorizontal = () => {
	const [selectedIconPortal, setSelectedIconPortal] = useState(null);
	const activeIconRef = useRef(null);

	const value = {
		activeIconRef,
		selectedIconPortal,
		setSelectedIconPortal,
	};

	return (
		<TagNavHorizontalContext.Provider value={value}>
			<div className={'HeaderNavigation'}>
				<TagNavHorizontalPortalButton name={'lego_education'}>
					<TagMenuHorizontal data={MenuLegoEducationSeries} />
				</TagNavHorizontalPortalButton>
				<TagNavHorizontalPortalButton name={'components'}>
					<TagMenuHorizontal data={MenuComponents} />
				</TagNavHorizontalPortalButton>
				<TagNavHorizontalPortalButton name={'computer_n_network'}>
					<TagMenuHorizontal data={MenuComputerAndNetwork} />
				</TagNavHorizontalPortalButton>
				<TagNavHorizontalPortalButton name={'energy'}>
					<TagMenuHorizontal data={MenuEnergy} />
				</TagNavHorizontalPortalButton>
				<TagNavHorizontalPortalButton name={'lab_n_workshop'}>
					<TagMenuHorizontal data={MenuLabAndWorkshop} />
				</TagNavHorizontalPortalButton>
				<TagNavHorizontalPortalButton name={'embedded'}>
					<TagMenuHorizontal data={MenuEmbedded} />
				</TagNavHorizontalPortalButton>
				<TagNavHorizontalPortalButton name={'robotics'}>
					<TagMenuHorizontal data={MenuRobotics} />
				</TagNavHorizontalPortalButton>
				<TagNavHorizontalPortalButton name={'lego'}>
					<TagMenuHorizontal data={MenuLegoSeries} />
				</TagNavHorizontalPortalButton>
				<TagNavHorizontalPortalPlacer />
			</div>
		</TagNavHorizontalContext.Provider>
	);
};
