import axios from "axios/index";
import {getAPIUrl} from "@esnpm/node-toolbox";
import Promise from "bluebird";
export const API_SESSION_CART_PRODUCTS_HAS_NO_SALE_REQUEST = "API_SESSION_CART_PRODUCTS_HAS_NO_SALE_REQUEST";
export const API_SESSION_CART_PRODUCTS_HAS_NO_SALE_SUCCESS = "API_SESSION_CART_PRODUCTS_HAS_NO_SALE_SUCCESS";
export const API_SESSION_CART_PRODUCTS_HAS_NO_SALE_FAILURE = "API_SESSION_CART_PRODUCTS_HAS_NO_SALE_FAILURE";

export const api_session_cart_products_has_no_sale_request = (options) => {
	return {
		type: API_SESSION_CART_PRODUCTS_HAS_NO_SALE_REQUEST,
		options: options,
		payload: {
			loading: true
		}
	};
};

export const api_session_cart_products_has_no_sale_success = (data) => {
	return {
		type: API_SESSION_CART_PRODUCTS_HAS_NO_SALE_SUCCESS,
		payload: {
			loading: false,
			data: data,
			error: null
		}
	};
};

export const api_session_cart_products_has_no_sale_failure = (error) => {
	return {
		type: API_SESSION_CART_PRODUCTS_HAS_NO_SALE_FAILURE,
		payload: {
			loading: false,
			error: error
		}
	};
};

/*
	Session.apiSessionCartProductsHasNoSale
*/
export const apiSessionCartProductsHasNoSale = (options) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(api_session_cart_products_has_no_sale_request(options));
			const url=getAPIUrl([
				{"url": "/api/session/cart/products/no_sale", "parameters": []},
			], options);
			axios({
				method: "get",
				url: url,
			}).then(
				(response) => {
					if (response.data.SUCCESSFUL) {
						dispatch(api_session_cart_products_has_no_sale_success(response.data.DATA));
						resolve();
					} else {
						dispatch(api_session_cart_products_has_no_sale_failure(response.data));
						resolve();
					}
				},
				(err) => {
					// eslint-disable-next-line no-console
					console.log(err);
					reject();
				}
			);
		}
	);
