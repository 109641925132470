import Promise from "bluebird";

export const APP_CHECKOUT_SET_PAYMENT_AUTHORIZATION_EXPIRED_SUCCESS = "APP_CHECKOUT_SET_PAYMENT_AUTHORIZATION_EXPIRED_SUCCESS";

export const appCheckoutSetPaymentAuthorizationExpired_success = (expired) => {
	return {
		type: APP_CHECKOUT_SET_PAYMENT_AUTHORIZATION_EXPIRED_SUCCESS,
		payload: {
			paymentAuthorizationExpired: expired
		}
	};
};

export const appCheckoutSetPaymentAuthorizationExpired = (data) =>
	(dispatch) => new Promise(
		(resolve) => {
			dispatch(appCheckoutSetPaymentAuthorizationExpired_success(data));
			resolve();
		}
	);

export const APP_CHECKOUT_SET_REDIRECT_TO_PROVIDER_SUCCESS = "APP_CHECKOUT_SET_REDIRECT_TO_PROVIDER_SUCCESS";
export const AppCheckoutSetRedirectToProvider_success = (options) => {
	return {

		type: APP_CHECKOUT_SET_REDIRECT_TO_PROVIDER_SUCCESS,
		payload: {
			redirectToProvider: options.redirectToProvider,
		},
	};
};
export const AppCheckoutSetRedirectToProvider = (options) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(AppCheckoutSetRedirectToProvider_success(options));
			resolve();
		},
	);

export const APP_CHECKOUT_SET_DONE_SUCCESS = "APP_CHECKOUT_SET_DONE_SUCCESS";
export const AppCheckoutSetDone_success = (options) => {
	return {
		type: APP_CHECKOUT_SET_DONE_SUCCESS,
		payload: {
			done: options.done,
		},
	};
};
export const AppCheckoutSetDone = (options) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(AppCheckoutSetDone_success(options));
			resolve();
		},
	);


export const APP_USER_ADDITIONAL_COMMENT_SET_EDITED_SUCCESS = "APP_USER_ADDITIONAL_COMMENT_SET_EDITED_SUCCESS";
export const AppUserAdditionalCommentSetEdited_success = (options) => {
	return {
		type: APP_USER_ADDITIONAL_COMMENT_SET_EDITED_SUCCESS,
		payload: {
			enabled: options.enabled,
		},
	};
};
export const AppUserAdditionalCommentSetEdited = (options) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(AppUserAdditionalCommentSetEdited_success(options));
			resolve();
		},
	);

export const APP_CUSTOMER_TYPE_SELECTION_SUCCESS = "APP_CUSTOMER_TYPE_SELECTION_SUCCESS";
export const AppCustomerTypeSelectionSetEmailValid = (options) => {
	return {
		type: APP_CUSTOMER_TYPE_SELECTION_SUCCESS,
		payload: {
			emailValid: options.emailValid,
		},
	};
};

export const APP_RIGHT_OF_REVOCATION_SET_ACCEPTED_SUCCESS = "APP_RIGHT_OF_REVOCATION_SET_ACCEPTED_SUCCESS";
export const AppRightOfRevocationSetAccepted = (options) => {
	return {
		type: APP_RIGHT_OF_REVOCATION_SET_ACCEPTED_SUCCESS,
		payload: {
			accepted: options.accepted,
		},
	};
};

export const APP_USER_ADDITIONAL_CUSTOMER_EMAIL_SET_EDITED_SUCCESS = "APP_USER_ADDITIONAL_CUSTOMER_EMAIL_SET_EDITED_SUCCESS";
export const AppUserAdditionalCustomerEmailSetEdited = (options) => {
	return {
		type: APP_USER_ADDITIONAL_CUSTOMER_EMAIL_SET_EDITED_SUCCESS,
		payload: {
			enabled: options.enabled,
		},
	};
};

export const APP_USER_ADDITIONAL_CUSTOMER_ORDER_NUMBER_SET_EDITED_SUCCESS = "APP_USER_ADDITIONAL_CUSTOMER_ORDER_NUMBER_SET_EDITED_SUCCESS";
export const AppUserAdditionalCustomerOrderNumberSetEdited_success = (options) => {
	return {
		type: APP_USER_ADDITIONAL_CUSTOMER_ORDER_NUMBER_SET_EDITED_SUCCESS,
		payload: {
			enabled: options.enabled,
		},
	};
};
export const AppUserAdditionalCustomerOrderNumberSetEdited = (options) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) => new Promise(
		// eslint-disable-next-line no-unused-vars
		(resolve, reject) => {
			dispatch(AppUserAdditionalCustomerOrderNumberSetEdited_success(options));
			resolve();
		},
	);

