import React from "react";
import {Tr} from "@esnpm/translate";
import {compose} from "redux";
import PrivateCustomer from "./PrivateCustomer";
import BusinessCustomer from "./BusinessCustomer";

class VatInfoFooter extends React.Component {
    render() {
        return (
            <div className={"VatInfoFooter"}>
                <PrivateCustomer>
                    <Tr path={"product.Vat.Incl"} html/>
                </PrivateCustomer>
                <BusinessCustomer>
                    <Tr path={"product.Vat.Excl"} html/>
                </BusinessCustomer>
            </div>
        );
    }
}

export default compose(

)(VatInfoFooter);
