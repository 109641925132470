import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {Button, Col, PageHeader, Row, Tab, Tabs} from "react-bootstrap";
import {Tr} from "@esnpm/translate";
import {connect} from "react-redux";
import {AppShowLoginRegisterPage} from "../actions/App/AppRegistration";
import RegistrationDialog from "./RegistrationDialog";
import {ESLoadable} from "@esnpm/react-toolbox";
import dynamic from "next/dynamic";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import {compose} from "redux";
import LoginDialog from "./LoginDialog";
import SimplePageHeader from "./SimplePageHeader";
import {withShopAppContext} from "./ShopAppContext";
import {withAuthCurrentSession} from "../factories/withAuthCurrentSession";
import {withAuthCurrentUser} from "../factories/withAuthCurrentUser";
import {withAppRegistration} from "../factories/withAppRegistration";
import {withRouter} from "react-router-dom";
import _ from "lodash";

// import {RegistrationSuccessful} from "./pages/RegistrationSuccessful";
const RegistrationSuccessful = dynamic(() => import("./pages/RegistrationSuccessful"));

class LoginRegistrationPage extends React.Component {

	static propTypes = {
		app: PropTypes.any,
		currentSession: PropTypes.any,
		currentUser: PropTypes.any,
		history: PropTypes.any,
		location: PropTypes.any,
		shopApp: PropTypes.any
	};

	constructor(props) {
		super(props);
		this.state = {
			selectedTab: 1
		};
	}

	componentDidUpdate(nextProps) {
		if (this.props.location.key !== nextProps.location.key) {
			if (
				(this.props.app.registration.show_login_form === nextProps.app.registration.show_login_form) &&
				(this.props.app.registration.show_forgot_password_form === nextProps.app.registration.show_forgot_password_form)
			) {
				this.props.dispatch(AppShowLoginRegisterPage(false));
			}
		}
	}

	setSelected = (val) => {
		this.setState({selectedTab: val});
	};

	renderLoginTab() {
		if (this.state.selectedTab != 1) return null;
		return (<Fragment><ESLoadable
				active={this.props.currentUser.loading || this.props.currentSession.loading}
			>
				<LoginDialog/>
			</ESLoadable>
				<ESLoadable
					active={this.props.app.registration.reset_pw_request.result.loading}
				>
					<ForgotPasswordDialog/>
				</ESLoadable></Fragment>
		);
	}

	renderRegistrationTab() {
		if (this.state.selectedTab != 2) return null;
		return <RegistrationDialog/>;
	}

	renderContent() {
		if (this.props.app.registration.registration.result.data == null) {
			return (
				<React.Fragment>
					<SimplePageHeader onLogoClick={() => this.props.shopApp.setShowLoginComponent(false)}/>
					<PageHeader>
						<Tr path={"registerTeaser.Headline"}/>
					</PageHeader>
					<p><Tr path={"registerTeaser.Content"}/></p>
					<hr/>
					<Tabs defaultActiveKey={1} className={"Tabs"} id={"registrationTab"}
								onSelect={(key) => this.setSelected(key)}
					>
						<Tab
							eventKey={1}
							title={<Tr path={"loginDialog.Login"}/>}
						>
							{this.renderLoginTab()}
						</Tab>
						<Tab
							eventKey={2}
							title={<Tr path={"registerDialog.Register"}/>}
						>
							{this.renderRegistrationTab()}
						</Tab>
					</Tabs>
					<hr/>
					<Button
						onClick={() => this.props.shopApp.setShowLoginComponent(false)}
					>
						<Tr path={"loginDialog.BackToShop"}/>
					</Button>

				</React.Fragment>
			);
		} else if (_.get(this.props.app.registration, ["registration", "result", "data"], null) != null) {
			return (<RegistrationSuccessful/>);
		}
	}

	render() {

		if (_.get(this.props.currentUser, ["loading"], true)) return null;
		if (_.get(this.props.currentUser, ["data"], null) != null) {
			this.props.shopApp.setShowLoginComponent(false);
			this.props.dispatch(AppShowLoginRegisterPage(false)).then(() => {
				// this.props.history.push("/");
			});
		}

		return (
			<Row className={"RegistrationDialog"}>
				<Col md={8} mdOffset={2} xs={12}>
					{this.renderContent()}
				</Col>
			</Row>
		);
	}
}

export default compose(
	withShopAppContext,
	withAuthCurrentSession,
	withAuthCurrentUser,
	withAppRegistration,
	withRouter,
	connect()
)(LoginRegistrationPage);
