import React, { Component } from 'react';
import Link from 'react-router-dom/es/Link';
import PropTypes from 'prop-types';
import { Tr } from '@esnpm/translate';
import { Col, Jumbotron, Row } from 'react-bootstrap';

const ProductErrorComponent = () => {
	return (
		<Row>
			<Col xs={12}>&nbsp;</Col>
			<Col xs={12} md={10} mdPush={1} className={'product-image-gallery'}>
				<Jumbotron>
					<h2>
						<Tr path={'product.NoSale.Headline'} />
					</h2>
					<p>
						<Tr path={'product.NoSale.Content.General'} />
					</p>
					<p>
						<Link to={'/'} className={'btn btn-primary'}>
							<Tr path={'buttons.BackToStart'} />
						</Link>
					</p>
				</Jumbotron>
			</Col>
		</Row>
	);
};

ProductErrorComponent.propTypes = {
	title: PropTypes.any,
	message: PropTypes.any,
};

export class ProductErrorBoundary extends Component {
	promiseRejectionHandler = (event) => {
		this.setState({
			error: event.reason,
		});
	};

	state = {
		error: null,
	};

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { error: error };
	}

	componentDidCatch(error, errorInfo) {
		console.error('Uncaught error:', error, errorInfo);
	}

	componentDidMount() {
		// Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
		window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
	}

	componentWillUnmount() {
		window.removeEventListener(
			'unhandledrejection',
			this.promiseRejectionHandler
		);
	}

	render() {
		if (this.state.error) {
			return <ProductErrorComponent />;
		} else {
			return this.props.children;
		}
	}
}
