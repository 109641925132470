import PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import ForgotPasswordForm from "./ForgotPasswordForm";


class ForgotPasswordDialog extends React.Component {
	static propTypes = {
		app: PropTypes.any
	};

	render() {
		return (
			<ForgotPasswordForm/>
		);
	}
}

export default compose(
	connect(
		(state) => (
			{
				app: {
					registration: state.app.registration,
				}
			}
		)
	)
)(ForgotPasswordDialog);

