import PropTypes from "prop-types";
import React from "react";
import Link from "react-router-dom/es/Link";
import {compose} from "redux";

class TopNavBarItem extends React.Component {

	static propTypes = {
		children: PropTypes.any,
		className: PropTypes.any,
		noLink: PropTypes.any,
		onClick: PropTypes.any,
		to: PropTypes.any
	};

	render() {

		return (
			<li
				onClick={this.props.onClick}
				className={"TopNavBarItem" + " " + this.props.className}>
				{this.renderLink()}
			</li>
		);
	}

	renderLink() {
		if (this.props.noLink) {
			return (
				<a className={"btn btn-transparent"}>{this.props.children}</a>
			);
		} else {

			return (
				<Link to={this.props.to} className={"btn btn-transparent"}>
					{this.props.children}
				</Link>
			);
		}
	}

}

export default compose(

)(TopNavBarItem);
