import {API_SESSION_CART_ADOPT_POSITIONS_FROM_ORDER_POST_SUCCESS} from "../../actions/esshop2/apiSessionCartAdoptPositionsFromOrder_post";
import update from "immutability-helper";

const initialState = {
	order: {
		fullTrNumber: null,
		adoptedPositionErrors: {
			noSale: null,
			eol: null,
		},
	},
};

export const AppAccountReducer = (state = initialState, action) => {
	let payloadData = null;
	switch (action.type) {
		case API_SESSION_CART_ADOPT_POSITIONS_FROM_ORDER_POST_SUCCESS:
			payloadData = action.payload.data;
			return update(state, {
				order: {
					fullTrNumber: {
						$set: payloadData.FULL_TR_NUMBER,
					},
					adoptedPositionErrors: {
						noSale: {
							$set: payloadData.NO_SALES,
						},
						eol: {
							$set: payloadData.EOLS,
						},
					},
				},
			});
		default:
			return state;
	}
};
