import axios from 'axios/index';
import { getAPIUrl } from '@esnpm/node-toolbox';
import Promise from 'bluebird';
export const API_CATEGORIES_SHOP_MENU_REQUEST =
	'API_CATEGORIES_SHOP_MENU_REQUEST';
export const API_CATEGORIES_SHOP_MENU_SUCCESS =
	'API_CATEGORIES_SHOP_MENU_SUCCESS';
export const API_CATEGORIES_SHOP_MENU_FAILURE =
	'API_CATEGORIES_SHOP_MENU_FAILURE';

export const api_categories_shop_menu_request = (options) => {
	return {
		type: API_CATEGORIES_SHOP_MENU_REQUEST,
		options: options,
		payload: {
			loading: true,
		},
	};
};

export const api_categories_shop_menu_success = (data) => {
	return {
		type: API_CATEGORIES_SHOP_MENU_SUCCESS,
		payload: {
			loading: false,
			data: data,
			error: null,
		},
	};
};

export const api_categories_shop_menu_failure = (error) => {
	return {
		type: API_CATEGORIES_SHOP_MENU_FAILURE,
		payload: {
			loading: false,
			error: error,
		},
	};
};

/*
	Categories.apiCategoriesShopMenu
*/
export const apiCategoriesShopMenu =
	(options) =>
	// eslint-disable-next-line no-unused-vars
	(dispatch, getState) =>
		new Promise(
			// eslint-disable-next-line no-unused-vars
			(resolve, reject) => {
				dispatch(api_categories_shop_menu_request(options));
				const url = getAPIUrl(
					[{ url: '/api/categories/shop/menu', parameters: [] }],
					options
				);
				axios({
					method: 'get',
					url: url,
				}).then(
					(response) => {
						if (response.data.SUCCESSFUL) {
							dispatch(api_categories_shop_menu_success(response.data.DATA));
							resolve();
						} else {
							dispatch(api_categories_shop_menu_failure(response.data));
							resolve();
						}
					},
					(err) => {
						console.log(err);
						reject();
					}
				);
			}
		);
