import Promise from "bluebird";

export const APP_PAGE_SET_PREFERENCES_SUCCESS = "APP_PAGE_SET_PREFERENCES_SUCCESS";

export const appPageSetPreferences_success = (param) => {
	return {
		type: APP_PAGE_SET_PREFERENCES_SUCCESS,
		payload: param
	};
};

export const appPageSetPreferences = (data) =>
	(dispatch) => new Promise(
		(resolve) => {
			dispatch(appPageSetPreferences_success(data));
			resolve();
		}
	);
