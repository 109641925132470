export const trmMetaTexts = {
	pathesToCheck: ["meta"],
	tr: {
		meta: {
			Standard: {
				DE: "Vom individuellen DIY-Projekt bis zum professionellen Meisterstück, in unserem Online-Shop werden sie fündig. Entdecken Sie Die Welt der Elektronik und starten Sie Ihr eigenes eizigartiges Projekt.",
				EN: "From individual DIY project to professional masterpieces, you will find everything you'll need in our store! Discover the world of electronics and start your own fancy project.",
				FR: "Des projets individuels de bricolage aux chefs-d'oeuvre professionnels, vous trouverez ce que vous cherchez dans notre boutique en ligne. Découvrez le monde de l'électronique et lancez votre propre projet."
			},
			Category: {
				DE: "Bei uns finden Sie eine große Auswahl an Produkte aus der Kategorie ",
				EN: "Browse through a large selection of products from the category ",
				FR: "Vous trouverez ici un grand choix de produits des catégories suivantes"
			}
		}
	}
};
