import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { ShopProductContext } from './ShopProductContext';
import { withProductContext } from '@esnpm/esdb';
import _ from 'lodash';
import { withTrContext } from '@esnpm/translate';
import {
	gtmAddToCart,
	gtmDetail,
	gtmImpression,
	gtmRemoveFromCart,
} from '../../lib/gtm/gtm';
import Promise from 'bluebird';
import { connect } from 'react-redux';
import { apiAddPositionToCart_post } from '../../actions/esshop2/apiAddPositionToCart_post';
import { apiRemovePositionFromCart_post } from '../../actions/esshop2/apiRemovePositionFromCart_post';
import { withAuthCurrentSession } from '../../factories/withAuthCurrentSession';

const noPic = {
	ASSET_TYPE: 'PRODUCT_MAIN_PICTURE',
	CAPTION: {
		EN: 'No picture available',
	},
	URL: '/nopicc.jpg',
	URL_TYPE: 'CDS',
};

export const getDiscountRate = (sellingPlatform) => {
	const price = sellingPlatform.SALE.PRICES.filter(
		(price) => price.AMOUNT === 1
	)[0];
	let discount = 0;

	if (price.BRUTTO_SP !== price.ORIGINAL_BRUTTO_SP) {
		discount = Math.round(
			100 - (100 / price.ORIGINAL_BRUTTO_SP) * price.BRUTTO_SP
		);
	}
	return discount;
};

const getAssetContentByLC = (data, lc) => {
	if (typeof data === 'string') {
		return data;
	}
	let ret = _.get(data, lc, null);
	if (ret != null) {
		return ret;
	} else {
		const givenLanguages = ['ALL', 'EN', 'DE', 'FR'];
		const result = givenLanguages.filter((lc) => {
			return _.get(data, lc, null) != null;
		});
		if (result.length != 0) {
			return data[result[0]];
		} else {
			return '';
		}
	}
};

export const productBaseData = (product, trProvider = null) => {
	const productData = {
		NAME_TEXT_PATH: 'products.' + product.SKU + '.NAME_TEXT',
		SHORT_TEXT_PATH: 'products.' + product.SKU + '.SHORT_TEXT',
		LONG_TEXT_PATH: 'products.' + product.SKU + '.LONG_TEXT',
		PRODUCT_PATH: '/product/' + product.SKU,
	};
	// Daten die von der Sellingplatform abhängig sind
	const sellingPlatform = _.isArray(product.SELLING_PLATFORMS)
		? product.SELLING_PLATFORMS.filter(
				(sp) => sp.SELLING_PLATFORM === 'WEBSHOP'
		  )[0]
		: null;
	const sellingPlatformData =
		sellingPlatform == null
			? null
			: {
					SP: sellingPlatform,
					PRICE: sellingPlatform.SALE.PRICES.filter((p) => p.AMOUNT === 1)[0],
					MOQ: sellingPlatform.SALE.MINIMUM_ORDER_QUANTITY,
					DISCOUNT_RATE:
						_.indexOf(product.FLAGS, 'NO_DISCOUNT') != -1
							? 0
							: getDiscountRate(sellingPlatform),
			  };

	//ASSETS
	const assets = _.get(product, 'ASSETS', []);
	const lc = _.get(trProvider, 'activeLanguage', 'EN');

	const links = assets
		.filter((asset) => asset.ASSET_TYPE === 'EXTERNAL_LINK')
		.map((asset) => {
			return {
				CAPTION: getAssetContentByLC(asset.CAPTION, lc),
				URL: getAssetContentByLC(asset.URL, lc),
			};
		});

	const copyRightTextObject = _.get(
		assets.filter((asset) => asset.ASSET_TYPE === 'COPYRIGHT_TEXT'),
		'[0]',
		null
	);
	const copyRightText = _.isNull(copyRightTextObject)
		? null
		: getAssetContentByLC(copyRightTextObject.CAPTION, lc);

	const assetData =
		assets.length == 0
			? {
					ASSETS: [noPic],
					MAINPICTURE: noPic,
					MANUFACTURER_LOGO: null,
			  }
			: {
					ASSETS: assets,
					LINKS: links,
					COPYRIGHT_TEXT: copyRightText,
					MAINPICTURE: (() => {
						const mainpics = product.ASSETS.filter(
							(mp) => mp.ASSET_TYPE === 'PRODUCT_MAIN_PICTURE'
						);
						return mainpics.length == 0 ? noPic : mainpics[0];
					})(),
					MANUFACTURER_LOGO: (() => {
						const manufacturerLogos = product.ASSETS.filter(
							(mp) => mp.ASSET_TYPE === 'MANUFACTURER_LOGO'
						);
						return manufacturerLogos.length == 0 ? null : manufacturerLogos[0];
					})(),
			  };

	//CROSSSelling
	const CROSS_SELLING = _.isArray(product.CROSS_SELLING)
		? product.CROSS_SELLING
		: [];
	const crossSellingData = {
		CROSS_SELLING_ACCESSORIES: CROSS_SELLING.filter(
			(item) => item.CROSS_SELLING_TYPE === 'ACCESSORIES'
		),
		CROSS_SELLING_CONSUMABLES: CROSS_SELLING.filter(
			(item) => item.CROSS_SELLING_TYPE === 'CONSUMABLES'
		),
		CROSS_SELLING_MASTERS: CROSS_SELLING.filter(
			(item) => item.CROSS_SELLING_TYPE === 'MASTERS'
		),
	};

	//Manufacturer
	const manufacturerData = !_.has(product, 'MANUFACTURER')
		? null
		: {
				MANUFACTURER_SKU: product.MANUFACTURER.MANUFACTURER_SKU,
				MANUFACTURER_NAME: product.MANUFACTURER.MANUFACTURER_NAME,
		  };

	return Object.assign(
		{},
		product,
		productData,
		sellingPlatformData,
		crossSellingData,
		assetData,
		manufacturerData,
		trProvider != null
			? {
					NAME_TEXT_EN: trProvider.tr(productData.NAME_TEXT_PATH, {
						activeLanguage: 'EN',
					}),
			  }
			: null
	);
};

class ShopProductContainer extends Component {
	static defaultProps = {
		gtmDetail: false,
	};

	static propTypes = {
		children: PropTypes.any,
		currentSession: PropTypes.object,
		dispatch: PropTypes.any,
		gtmDetail: PropTypes.bool,
		product: PropTypes.object.isRequired,
		trProvider: PropTypes.object.isRequired,
		gtmList: PropTypes.string,
		gtmImpression: PropTypes.bool,
	};

	constructor(props, context) {
		super(props, context);
		this.contextValues = null;
		this.updateContextData(props.product, props.currentSession);
	}

	// eslint-disable-next-line no-unused-vars
	shouldComponentUpdate(nextProps, nextState) {
		this.updateContextData(nextProps.product, nextProps.currentSession);
		if (!_.isEqual(this.props.product, nextProps.product)) {
			return true;
		}
		if (!_.isEqual(this.props.currentSession, nextProps.currentSession)) {
			return true;
		}
		return false;
	}

	updateContextData(product, session) {
		const manufacturerID = _.get(
			this.props.product,
			'MANUFACTURER.MANUFACTURER_ID',
			null
		);
		const manufacturerName = _.get(
			this.props.product,
			'MANUFACTURER.MANUFACTURER_NAME',
			null
		);

		let product_flags = _.cloneDeep(_.get(this.props.product, 'FLAGS', []));

		// If product is a LEGO Education product
		if (manufacturerID === 105 || manufacturerName === 'LEGO® EDUCATION') {
			product_flags = _.union(
				_.concat(product_flags, ['AMOUNT_IN_CART_LIMIT:5'])
			);
		}
		// Todo - Remove, fix via DB / erp - Flags unknown to erp, are removed on save
		if (_.includes([193004, 193013], product.SKU)) {
			product_flags = _.union(
				_.concat(product_flags, ['AMOUNT_IN_CART_LIMIT:2'])
			);
		}

		const AMOUNT_IN_CART_LIMIT_FLAG = product_flags.filter((flag) =>
			flag.startsWith('AMOUNT_IN_CART_LIMIT')
		);

		const AMOUNT_IN_CART_LIMIT = !_.isEmpty(AMOUNT_IN_CART_LIMIT_FLAG)
			? AMOUNT_IN_CART_LIMIT_FLAG[0].split(':')[1]
			: null;

		const productBase = productBaseData(product, this.props.trProvider);

		const timesInCart = session.data.CART.POSITIONS.filter(
			(pos) => pos.SKU === this.props.product.SKU
		);

		// take limit from product tag in form of "AMOUNT_IN_CART_LIMIT:5"
		const cartAmount = {
			AMOUNT_IN_CART: timesInCart.length !== 0 ? timesInCart[0]['AMOUNT'] : 0,
			AMOUNT_IN_CART_LIMIT: AMOUNT_IN_CART_LIMIT,
		};

		const methods = {
			addToCart: this.addToCart,
			adjustCart: this.adjustCart,
			removeFromCart: this.removeFromCart,
			removeAllFromCart: this.removeAllFromCart,
		};

		const gtmData = {
			gtmList: this.props.gtmList,
			gtmImpression: this.props.gtmImpression,
		};

		this.contextValues = Object.assign(
			{},
			productBase,
			cartAmount,
			methods,
			gtmData
		);
	}

	addToCart = (amount) =>
		new Promise(
			// eslint-disable-next-line no-unused-vars
			(resolve, reject) => {
				const product = this.contextValues;
				const AMOUNT = product.MOQ * amount;

				const realAmount =
					product.AMOUNT_IN_CART_LIMIT === null
						? AMOUNT
						: product.AMOUNT_IN_CART + AMOUNT < product.AMOUNT_IN_CART_LIMIT
						? AMOUNT
						: product.AMOUNT_IN_CART_LIMIT - product.AMOUNT_IN_CART;
				// console.log("realAmount", realAmount);
				if (realAmount === 0) {
					resolve();
				} else {
					this.props
						.dispatch(
							apiAddPositionToCart_post({
								SKU: product.SKU,
								AMOUNT: realAmount,
							})
						)
						.then(() => {
							gtmAddToCart(product, AMOUNT);
							resolve();
						});
				}
			}
		);

	removeFromCart = (amount = null) =>
		new Promise(
			// eslint-disable-next-line no-unused-vars
			(resolve, reject) => {
				const product = this.contextValues;
				const AMOUNT =
					amount == null ? product.AMOUNT_IN_CART : product.MOQ * amount;
				this.props
					.dispatch(
						apiRemovePositionFromCart_post({
							SKU: product.SKU,
							AMOUNT: AMOUNT,
						})
					)
					.then(() => {
						gtmRemoveFromCart(product, AMOUNT);
						resolve();
					});
			}
		);

	adjustCart = (amount, session) =>
		new Promise(
			// eslint-disable-next-line no-unused-vars
			(resolve, reject) => {
				const product = this.contextValues;
				const AMOUNT = product.MOQ * amount;
				const diff = AMOUNT - product.AMOUNT_IN_CART;
				if (diff > 0) {
					return this.addToCart(diff, session).then(() => {
						resolve();
					});
				} else if (diff < 0) {
					return this.removeFromCart(diff * -1).then(() => {
						resolve();
					});
				} else {
					resolve();
				}
			}
		);

	componentDidMount() {
		if (this.props.gtmImpression) {
			gtmImpression(this.contextValues, this.props.gtmList);
		}
		if (this.props.gtmDetail) {
			gtmDetail(this.contextValues);
		}
	}

	render() {
		// console.log("ren")
		// gtmImpression(this.contextValues);  // Muss nochmal evaluiert werden.

		// console.log("LINKS", _.get(this.contextValues, ["LINKS"]));
		// console.log("COPYRIGHT_TEXT", _.get(this.contextValues, ["COPYRIGHT_TEXT"]));
		return (
			<ShopProductContext.Provider value={this.contextValues}>
				{this.props.children}
			</ShopProductContext.Provider>
		);
	}
}

export default compose(
	connect(),
	withTrContext,
	withProductContext,
	withAuthCurrentSession
)(ShopProductContainer);
